import React from 'react'
import { AiOutlineMinus } from 'react-icons/ai'
import { MenuLink, IconsDivisor, IconsDivisorResponsive } from './style'

const Divider: React.FC = () => {
  return (
    <MenuLink>
      <IconsDivisor>
        <AiOutlineMinus />
      </IconsDivisor>
      <IconsDivisorResponsive>|</IconsDivisorResponsive>
    </MenuLink>
  )
}

export default Divider
