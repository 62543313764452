import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { BsArrowsAngleExpand } from 'react-icons/bs'
import { getDashboardApi } from '@services/api'
import { Dashboard, Widget, WidgetPosition } from '@services/openapi'
import { useToasts } from 'react-toast-notifications'
import usePrevious from '@hooks/usePrevious'
import { ButtonBoxWhiteMain } from '../../../assets/styles/stylesButton'
import GridWidgets from './grid'
import { Div, FullScreen } from './styles'

export default function Index({
  reDrawing = false,
  refresh,
  edit,
  addWidget,
  dashboard,
  onUpdateDashboard,
  fullScreen,
  setFullScreen,
  setReDrawing = element => {
    return element
  },
}: {
  addWidget?: Widget
  dashboard: Dashboard
  refresh: number
  edit: boolean
  reDrawing: boolean
  fullScreen: boolean
  onUpdateDashboard: (d: Dashboard) => void
  setReDrawing: (element: any) => any
  setFullScreen: (enable: boolean) => void
}) {
  const [currentId, setCurrentId] = useState<number>()
  const [widgets, setWidgets] = useState<Widget[]>([])
  const [stageLayout, setStageLayout] = useState<WidgetPosition[]>([])
  const { addToast } = useToasts()
  const prevEdit = usePrevious<boolean>(edit)

  const apiDashboard = getDashboardApi()

  async function loadWidgets() {
    if (dashboard) {
      await apiDashboard
        .dashboardControllerGetAllWidgets(dashboard.id!)
        .then(response => {
          setWidgets(response.data.success ? response.data.data : [])
        })
        .catch(error => {
          debug('api')(error)
        })
    }
  }

  async function flushLayoutChanges() {
    if (stageLayout.length === 0) return

    await apiDashboard
      .dashboardControllerUpdateLayout(dashboard.id!, stageLayout)
      .then(response => {
        if (response.data.success) {
          onUpdateDashboard({ ...dashboard, position: stageLayout })
          setStageLayout([])
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
        addToast(error, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
    setFullScreen(false)
  }

  useEffect(() => {
    loadWidgets()
  }, [])

  useEffect(() => {
    if (addWidget) {
      setWidgets([...widgets, addWidget])
    }
  }, [addWidget])

  useEffect(() => {
    if (dashboard.id !== currentId) {
      setWidgets([])
      loadWidgets()
    }
    setCurrentId(dashboard.id)
  }, [dashboard])

  useEffect(() => {
    setReDrawing(true)
    if (stageLayout === dashboard.position) {
      return
    }
    let flushInterval
    if (edit) {
      flushInterval = setInterval(() => flushLayoutChanges(), 10000)
    }
    if (!edit && prevEdit === true) {
      flushLayoutChanges()
    }
    return () => {
      clearInterval(flushInterval)
      flushInterval = undefined
    }
  }, [edit, stageLayout])

  return (
    <FullScreen id="fullScreen">
      <Div id="fullScreen">
        {fullScreen && (
          <ButtonBoxWhiteMain onClick={() => exitFullScreen()}>
            <BsArrowsAngleExpand />
          </ButtonBoxWhiteMain>
        )}
        <GridWidgets
          reDrawing={reDrawing}
          setReDrawing={setReDrawing}
          widgets={widgets}
          dashboard={dashboard}
          refresh={refresh}
          edit={edit}
          setWidgets={setWidgets}
          onUpdateDashboard={onUpdateDashboard}
          onLayoutChange={(newLayout: WidgetPosition[]) => {
            setStageLayout(newLayout)
          }}
        />
      </Div>
    </FullScreen>
  )
}
