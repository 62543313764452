import React, { memo, useEffect, useRef, useState } from 'react'
import * as Bs from 'react-icons/bs'

import { convertDateInString } from '@utils/date-graphic'
import { valueGraphic } from '@utils/value-graphic'
import { useWindowSize } from '@utils/size'
import {
  Log,
  Widget,
  WidgetTypeEnum,
  WidgetUnit,
  WidgetValueMap,
} from '@services/openapi'
import Dropdown from '@components/dropdown'
import useLastLog from '@hooks/useLastLog'
import parseConditionalsCustomization from '@utils/parseConditionalsCustomization'
import {
  Card,
  Title,
  Center,
  CenterNotValue,
  Units,
  Value,
  DivTitle,
  IconDiv,
} from './styles'

function Index({
  icon,
  title,
  units,
  map,
  size,
  widget,
  time,
  type,
  refresh,
  edit,
  onClone,
  setModalVisibleDelete,
  setModalVisibleUpdate,
  setWidget,
}: {
  icon?: string
  title: string
  units?: WidgetUnit
  map?: WidgetValueMap[]
  size?: number
  type: WidgetTypeEnum
  widget: Widget
  time: number
  refresh?: number
  edit: boolean
  onClone?: (widget: Widget) => void
  setWidget?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
}) {
  const [keyIcon, setKeyIcon] = useState(0)
  const [date, setDate] = useState('')
  const [value, setValue] = useState()
  const tamanho = useWindowSize()
  const [, setWidthInPixie] = useState(205)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [log, setLog] = useState<Log>()
  const [lastModified, setLastModified] = useState<string>()

  const { data, lastModifiedTimestamp, error, refreshNow } = useLastLog(
    widget.variables,
    log,
    lastModified,
    time * 1000,
  )

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (dropDownVisible && ref.current && !ref.current.contains(e.target)) {
        setDropDownVisible(false)
      }
    }

    if (dropDownVisible) {
      document.addEventListener('mousedown', checkIfClickedOutside)

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [dropDownVisible])

  const ListIcons = [
    { name: 'BsThermometerHalf', icon: <Bs.BsThermometerHalf /> },
    { name: 'BsThermometerHigh', icon: <Bs.BsThermometerHigh /> },
    { name: 'BsSpeedometer2', icon: <Bs.BsSpeedometer2 /> },
    { name: 'BsLightningChargeFill', icon: <Bs.BsLightningChargeFill /> },
    { name: 'BsLightningCharge', icon: <Bs.BsLightningCharge /> },
    { name: 'BsLightningFill', icon: <Bs.BsLightningFill /> },
    { name: 'BsLightning', icon: <Bs.BsLightning /> },
    { name: 'BsWater', icon: <Bs.BsWater /> },
  ]

  useEffect(() => {
    let result = 0

    if (size && tamanho.width) {
      const sizeString = +`${size}`
      const newTamanho = tamanho.width - 350
      if (size == 100) {
        result = tamanho.width
      } else {
        result = (newTamanho * sizeString) / 100
      }
    }
    if (result == 0) result = 205
    setWidthInPixie(result)
  }, [tamanho, size])

  useEffect(() => {
    setDate(convertDateInString(log?.date))
    setValue(valueGraphic(log?.value || 0, units?.decimalPlaces, type))

    const key = ListIcons.findIndex(todo => todo.name === icon)
    if (icon === '' || icon == null) setKeyIcon(0)
    else setKeyIcon(key)
  }, [icon, title, units, widget, log, lastModified])

  useEffect(() => {
    if (data) {
      setLog(data as unknown as Log)
    }
    setLastModified(lastModifiedTimestamp || lastModified)
  }, [data, error])

  useEffect(() => refreshNow(), [refresh])

  function renderValueWithExpression() {
    const { color, rotulo, unidade } = parseConditionalsCustomization(
      map,
      widget.variableList,
      value,
    )

    const unit = unidade || units?.valueUnits

    return (
      <Center>
        {icon ? (
          <IconDiv color={color ?? ''}>{ListIcons[keyIcon].icon}</IconDiv>
        ) : null}
        <Units>
          <Value color={color}>
            {type == 'TEXT'
              ? rotulo ?? value
              : rotulo ?? parseFloat(`${value}`).toFixed(units?.decimalPlaces)}
          </Value>
          {unit ? <span>{unit}</span> : null}
        </Units>
      </Center>
    )
  }

  return (
    <>
      <Card id="container">
        <DivTitle>
          <Title>{title}</Title>
          {edit && (
            <Dropdown
              items={[
                [
                  'Editar',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleUpdate) setModalVisibleUpdate(true)
                  },
                ],
                ['Duplicar', () => onClone && onClone(widget)],
                [
                  'Excluir',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleDelete) setModalVisibleDelete(true)
                  },
                ],
              ]}
            />
          )}
        </DivTitle>
        {value !== undefined && value !== null && renderValueWithExpression()}
        <CenterNotValue>
          <small>{date}</small>
        </CenterNotValue>
      </Card>
    </>
  )
}

export default memo(Index)
