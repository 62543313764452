/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react'

import { Widget, WidgetCustomData, WidgetTypeEnum } from '@services/openapi'
import Base from './base'
import ConditionalsSection from './conditionals'

export default function Index({
  onSave: onSubmit,
  ...props
}: {
  type: WidgetTypeEnum
  onSave: (data: any, customData: WidgetCustomData) => void
  closeModal
  idDash: number
  widget?: Widget
}) {
  const [tabManager, setTabManager] = useState<any>()
  const [device, setDevice] = useState<number>()

  const [map, setMap] = useState<any>(
    (props.widget && props.widget.customData
      ? props.widget.customData.map
      : [{ value: '', label: '', action: '', expression: '' }]) || [
      { value: '', label: '', action: '', expression: '' },
    ],
  )

  const onChangeConditionals = (m: any) => {
    setMap(m)
  }

  useMemo(() => {
    if (!tabManager) return

    tabManager.update('personalization', {
      content: () => (
        <ConditionalsSection
          {...{ device, widget: props.widget, onChange: onChangeConditionals }}
        />
      ),
    })
  }, [tabManager, device, props.widget])

  function onSave(oldData, oldCustomData) {
    const data = oldData
    const customData = oldCustomData
    customData.map = map
    data.customData = customData
    onSubmit(data, customData)
  }

  return (
    <Base
      {...props}
      onChangeDevice={e => setDevice(e)}
      onSave={(oldData, oldCustomData) => onSave(oldData, oldCustomData)}
      tabs={tabs => setTabManager(tabs)}
    />
  )
}
