import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import Input from '@components/Input'
import { getOrganizationApi } from '@services/api'
import { Div } from '@components/modal/styles'
import useOrganization from '@hooks/useOrganization'
import { ButtonGreen } from '../../../../assets/styles/stylesButton'

interface IEditOrganization {
  ofOrganization: number
}

const EditOrganization: React.FC<IEditOrganization> = ({ ofOrganization }) => {
  const { addToast } = useToasts()

  const [name, setName] = useState<string>()

  const { organization, mutate } = useOrganization(ofOrganization)

  function onSubmit(e) {
    e.preventDefault()

    const organizationName = e.target.name.value

    getOrganizationApi()
      .organizationControllerUpdate(ofOrganization, { name: organizationName })
      .then(response => {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        mutate()
      })
      .catch(error => {
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    setName(organization?.name)
  }, [organization])

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Input
          required
          name="name"
          label="Nome"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Digite um nome..."
        />
      </div>

      <Div>
        <ButtonGreen>Salvar</ButtonGreen>
      </Div>
    </form>
  )
}

export default EditOrganization
