import React, { useState } from 'react'
import Modal from '@modals/device/create'
import { Nav, Menu, Title } from '../styles'
import { ButtonGreen } from '../../../assets/styles/stylesButton'

export default function Index({ onAddDevice }) {
  const [open, setOpen] = useState(false)

  return (
    <Nav>
      <Title>Dispositivos</Title>
      <Menu>
        <ButtonGreen onClick={() => setOpen(true)}>
          + Novo dispositivo
        </ButtonGreen>
      </Menu>
      <Modal open={open} setOpen={setOpen} onAddDevice={onAddDevice} />
    </Nav>
  )
}
