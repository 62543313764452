import { useAuth } from '@components/store/context'
import {
  getOrganizationApi,
  Organization,
  Searchable,
  UserTypeEnum,
} from '@services/api'
import usePagedData from './usePagedData'

function useOrganizations({ limit, page, search }: Searchable) {
  const [token] = useAuth()
  const { response, ...rest } = usePagedData<Organization>(
    {
      list: 'organizationControllerListOrganization',
      fetcher: () =>
        getOrganizationApi().organizationControllerListOrganization(),
      data: {
        search,
      },
      initial: {
        limit: limit || 10,
        page: page || 0,
      },
    },
    {
      canFetch: token.type === UserTypeEnum.Admin,
    },
  )

  return {
    data: response?.data?.data ?? [],
    response,
    ...rest,
  }
}

export default useOrganizations
