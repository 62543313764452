import React, { useState } from 'react'
import { BsChevronBarExpand } from 'react-icons/bs'
import { Div } from './styles'

export interface HeaderMap<T> {
  name: string
  field: string
  sortable?: boolean
  render?: (
    item: T,
    index: number,
    map: HeaderMap<T>,
  ) => React.ReactElement | React.ReactNode
}

interface HeaderProps<T> {
  onSorting: (field: string, order: 'asc' | 'desc') => void
  headers: HeaderMap<T>[]
}

function Header<T>({ onSorting, headers }: HeaderProps<T>) {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')

  const onSortingChange = field => {
    const order =
      field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order)
  }

  return (
    <thead>
      <tr>
        {headers.map(({ name, field, sortable }) => (
          <th
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            <Div sortable={sortable}>
              {name}
              {sortable == true ? <BsChevronBarExpand /> : null}
            </Div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default Header
