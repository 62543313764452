import { debug } from 'debug'
import useSWR, { mutate } from 'swr'
import { getWidgetApi } from '@services/api'
import { LogsPeriod } from '@services/openapi'
import { PeriodInterval } from 'src/types/enums'

export default (
  widgetId: number,
  periodInterval: PeriodInterval | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined,
  logs: LogsPeriod | undefined,
  lastModified: string | undefined,
  currentTimestamp: string | undefined,
  refreshInterval: number,
) => {
  const { data, error } = useSWR(
    ['widgetControllerGetLogsByPeriod', widgetId, periodInterval],
    (url, id, interval) =>
      getWidgetApi()
        .widgetControllerGetLogsByPeriod(
          id,
          interval!,
          lastModified ? +lastModified : undefined,
          currentTimestamp ? +currentTimestamp : undefined,
          startDate && endDate
            ? {
                startDate,
                endDate,
              }
            : undefined,
        )
        .then(response => {
          return {
            logs: response.data.data,
            lastModified: response.headers['x-last-modified'],
            currentTimestamp: response.headers['x-current-timestamp'],
          }
        })
        .catch(e => {
          debug('api')(e)
          return {
            logs,
            lastModified,
            currentTimestamp,
          }
        }),
    {
      refreshInterval,
    },
  )

  return {
    data: data?.logs,
    xLastModified: data?.lastModified,
    xCurrentTimestamp: data?.currentTimestamp || data?.lastModified,
    error,
    refreshNow: () => {
      mutate(['widgetControllerGetLogsByPeriod', widgetId, periodInterval])
    },
  }
}
