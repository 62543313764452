import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { Mandatory } from '@components/modal/widget/styles'
import useDevices from '@hooks/useDevices'
import { Div, Label, Select } from './styles'

export default function index({
  setDevice,
  device,
}: {
  setDevice: React.Dispatch<React.SetStateAction<number>>
  device: number
}) {
  const [value, setValue] = useState<number>()

  const { devices, isError } = useDevices()

  useEffect(() => {
    debug('api')(isError)
  }, [isError])

  async function handleSubmit(d: number) {
    setValue(d)
    setDevice(d)
  }

  useEffect(() => {
    setValue(device)
  }, [devices])

  return (
    <Div>
      <Label htmlFor="select-device">
        Dispositivo
        <Mandatory>*</Mandatory>
      </Label>
      <Select
        required
        id="select-device"
        className={`form-control ${value ? 'not-empty' : 'empty'}`}
        value={value}
        onChange={event => handleSubmit(+event.target.value)}
      >
        <option value="">Escolha uma Dispositivo...</option>
        {devices?.map(d => (
          <option value={d.id} key={d.id}>
            {d.name}
          </option>
        ))}
      </Select>
    </Div>
  )
}
