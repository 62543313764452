/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import Input from '@components/Input'

import { Td } from './styles'

const DataTable = ({ user, addVariables }) => {
  const [eventChecked, setEventCehecked] = useState(false)
  useEffect(() => {
    setEventCehecked(user.checked)
  }, [user.checked])
  return (
    <>
      <tr key={`${user.checked}${user.id}`}>
        <td>
          <span>
            <Input
              onChange={e => {
                addVariables(e.target.checked, user.id)
              }}
              checked={eventChecked}
              label
              type="checkbox"
            />
          </span>
        </td>
        {/* <th scope="row">{user && user.name}</th> */}
        {user &&
          Object.keys(user).map(renderItem => (
            <Td active={user[renderItem] == 'Ativo'}>
              {renderItem == 'disabled' ? (
                <span>{user[renderItem]}</span>
              ) : (
                <div>{user[renderItem]}</div>
              )}
            </Td>
          ))}
      </tr>
    </>
  )
}

export default DataTable
