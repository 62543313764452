import React from 'react'
import * as Bs from 'react-icons/bs'
import { Div, Label, Select } from './styles'

export default function index({ setIconGraphic, iconGraphic }) {
  const ListIcons = [
    { name: 'BsThermometerHalf', icon: <Bs.BsThermometerHalf /> },
    { name: 'BsThermometerHigh', icon: <Bs.BsThermometerHigh /> },
    { name: 'BsSpeedometer2', icon: <Bs.BsSpeedometer2 /> },
    { name: 'BsLightningChargeFill', icon: <Bs.BsLightningChargeFill /> },
    { name: 'BsLightningCharge', icon: <Bs.BsLightningCharge /> },
    { name: 'BsLightningFill', icon: <Bs.BsLightningFill /> },
    { name: 'BsLightning', icon: <Bs.BsLightning /> },
    { name: 'BsWater', icon: <Bs.BsWater /> },
  ]

  async function handleSubmit(devices) {
    setIconGraphic(devices)
  }

  const ListDevices = ListIcons.map(d => {
    return (
      <option value={d.name} key={d.name}>
        {d.name}
      </option>
    )
  })

  return (
    <Div>
      <Label>ícones</Label>
      <Select
        required
        className={`form-control ${iconGraphic ? 'not-empty' : 'empty'}`}
        value={iconGraphic}
        onChange={event => handleSubmit(event.target.value)}
      >
        <option value="">Selecione…</option>
        {ListDevices}
      </Select>
    </Div>
  )
}
