import React from 'react'
import { WidgetTypeEnum } from '@services/openapi'
import Base from './base'

export default function Index(props: { onSave; closeModal; idDash; widget? }) {
  return (
    <Base
      {...props}
      type={WidgetTypeEnum.BarChart}
      tabs={tabs => {
        tabs.update('personalization', {
          visible: false,
        })
      }}
    />
  )
}
