import React from 'react'
import NotFound from '@errors/notFound'
import Main from '@layout/Main'

export default function Index() {
  return (
    <Main>
      <NotFound />
    </Main>
  )
}
