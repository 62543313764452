import React from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'

import { getWidgetApi, WidgetDTO } from '@services/api'
import { Widget, WidgetTypeEnum } from '@services/openapi'
import Bar from '../common/bar'
import Common from '../common/common'
import Line from '../common/line'
import Meter from '../common/speedometer'
import Cylinder from '../common/cylinder'

export default function Index({
  widget,
  setOpen,
  onUpdate,
}: {
  widget: Widget
  setOpen: (enable: boolean) => void
  onUpdate: (data: WidgetDTO) => void
}) {
  const { addToast } = useToasts()

  const apiWidget = getWidgetApi()

  function closeModal() {
    setOpen(false)
  }

  async function onSave(data) {
    await apiWidget
      .widgetControllerUpdate(widget.id!, data)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          onUpdate(data)
          closeModal()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }
  function getProps() {
    return {
      type: widget.type,
      widget,
      onSave: data => onSave(data),
      closeModal: () => closeModal(),
      idDash: widget.dashboard.id!,
    }
  }

  return (
    <>
      {widget.type === WidgetTypeEnum.Text && <Common {...getProps()} />}
      {widget.type === WidgetTypeEnum.Number && <Common {...getProps()} />}
      {widget.type === WidgetTypeEnum.Meter && <Meter {...getProps()} />}
      {widget.type === WidgetTypeEnum.LineChart && <Line {...getProps()} />}
      {widget.type === WidgetTypeEnum.BarChart && <Bar {...getProps()} />}
      {widget.type === WidgetTypeEnum.Cylinder && <Cylinder {...getProps()} />}
    </>
  )
}
