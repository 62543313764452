import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { UserTypeEnum } from '@services/api'
import SideBar from '@sidebar/index'
import { useAuth, useRouteState } from '@components/store/context'
import Navbar from '@components/navbar/organization/config'
import SingleOrganizationMenu from '@components/sidebar/menu/organization/Single'
import NotFound from '@components/errors/notFound'
import Main from '@layout/Main'
import EditOrganization from '@components/pages/organization/config'

function OrganizationConfig() {
  const history = useHistory()
  const { organizationId } = useParams()
  const [, setRouteState] = useRouteState()
  const [user] = useAuth()

  useEffect(() => {
    setRouteState!({
      key: 'organization/single/config',
      menu: (
        <SingleOrganizationMenu
          id={
            organizationId ??
            (typeof user.organization === 'object'
              ? user.organization.id
              : user.organization)
          }
        />
      ),
    })
  }, [])

  return (
    <>
      <SideBar />
      {user.type === UserTypeEnum.Admin ? (
        <>
          <Navbar
            organizationId={organizationId ?? user.organization}
            onDeleteOrganization={() =>
              history.push(`/organization`, {
                refresh: true,
              })
            }
          />
          <Main>
            <EditOrganization
              ofOrganization={organizationId ?? user.organization}
            />
          </Main>
        </>
      ) : (
        <Main>
          <NotFound />
        </Main>
      )}
    </>
  )
}

export default OrganizationConfig
