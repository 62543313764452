import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { FaBars } from 'react-icons/fa'
import { FiRefreshCcw } from 'react-icons/fi'
import 'react-toggle/style.css'

import {
  BsPencil,
  BsEye,
  BsClockHistory,
  BsArrowsAngleExpand,
  BsCheckLg,
} from 'react-icons/bs'
import ModalCreateWidget from '@modals/widget/create'
import ModalTime from '@modals/dashboard/updateTime'
import { getDashboardApi } from '@services/api'
import { Dashboard, NewWidget } from '@services/openapi'
import {
  ButtonGreen,
  ButtonBoxWhite,
  ButtonBoxGreen,
} from '../../../assets/styles/stylesButton'
import {
  Margin,
  Nav,
  DivHeader,
  Menu,
  ButtonsEditAndView,
  Div,
  DivInput,
} from './styles'
import { Title } from '../styles'

export default function Index({
  idActive = null,
  dashboard,
  edit,
  setEdit,
  setActiveSideBar,
  activeSideBar,
  setFullScreen,
  onRefresh,
  onUpdateDashboardName,
  onUpdateDashboardTime,
  onAddWidget,
}: {
  idActive?
  dashboard: Dashboard
  edit
  setEdit
  setActiveSideBar
  activeSideBar
  setFullScreen
  onRefresh: () => void
  onUpdateDashboardName: (name: string) => void
  onUpdateDashboardTime: (time: number) => void
  onAddWidget: (data: NewWidget) => void
}) {
  const apiDashboard = getDashboardApi()
  const { addToast } = useToasts()

  const [modalVisibleWidget, setModalVisibleWidget] = useState(false)
  const [modalVisibleTime, setModalVisibleTime] = useState(false)
  const [nameDash, setNameDash] = useState<string>('')

  useEffect(() => {
    setNameDash(dashboard.name)
  }, [dashboard])

  async function onSubmit(e) {
    e.preventDefault()

    await apiDashboard
      .dashboardControllerUpdate(idActive, {
        name: nameDash,
      })
      .then(response => {
        if (response.data.success) {
          onUpdateDashboardName(nameDash)
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  function fullScreen() {
    const element = document && document.querySelector('#fullScreen')
    if (!element) return

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        setFullScreen(true)
      } else {
        setFullScreen(false)
      }
    })
    if (!document.fullscreenElement) {
      element.requestFullscreen()
    }
  }

  return (
    <Nav active={activeSideBar}>
      <Margin>
        <DivHeader edit={edit}>
          <FaBars
            className="fabars"
            style={{ cursor: 'pointer' }}
            onClick={() => setActiveSideBar(!activeSideBar)}
          />
          {edit == true ? (
            <form onSubmit={onSubmit}>
              <DivInput>
                <input
                  value={nameDash}
                  required
                  type="text"
                  placeholder={nameDash}
                  onChange={e => setNameDash(e.target.value)}
                  disabled={idActive == 0}
                />
                <ButtonBoxGreen disabled={idActive == 0}>
                  <BsCheckLg />
                </ButtonBoxGreen>
              </DivInput>
            </form>
          ) : (
            <Title>{nameDash}</Title>
          )}
        </DivHeader>
        <ButtonsEditAndView>
          <Div onClick={() => setEdit(true)} active={edit == true}>
            <BsPencil />
            <span>Editar</span>
          </Div>
          <Div onClick={() => setEdit(false)} active={edit == false}>
            <BsEye />
            <span>Visualizar</span>
          </Div>
        </ButtonsEditAndView>
        <Menu>
          <ButtonBoxWhite onClick={() => onRefresh()}>
            <FiRefreshCcw />
          </ButtonBoxWhite>
          {edit && (
            <ButtonBoxWhite onClick={() => setModalVisibleTime(true)}>
              <BsClockHistory />
            </ButtonBoxWhite>
          )}
          <ButtonBoxWhite onClick={() => fullScreen()}>
            <BsArrowsAngleExpand />
          </ButtonBoxWhite>
          {edit && (
            <ButtonGreen
              disabled={idActive == 0}
              onClick={() => setModalVisibleWidget(true)}
            >
              + Novo widget
            </ButtonGreen>
          )}
        </Menu>
        <ModalCreateWidget
          open={modalVisibleWidget}
          setOpen={setModalVisibleWidget}
          idDash={idActive}
          onCreate={onAddWidget}
        />
        <ModalTime
          dashboard={dashboard}
          open={modalVisibleTime}
          setOpen={setModalVisibleTime}
          onUpdate={onUpdateDashboardTime}
        />
      </Margin>
    </Nav>
  )
}
