import React, { createContext, useState, useContext, useEffect } from 'react'
import parseJwt from '@helpers/parseJwt'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import useWindowDimensions from '@utils/useWindowDimensions'
import { darkTheme, whiteTheme } from '../../themes'

const TokenContext = createContext<{
  token?
  setToken?
}>({})

export function TokenProvider({ children }) {
  const [token, setToken] = useState(
    parseJwt(localStorage.getItem('@token')) || null,
  )

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      {children}
    </TokenContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(TokenContext)
  if (!context) throw new Error('useAuth must be used within a TokenProvider')
  const { token, setToken } = context
  return [token, setToken]
}

interface RouteStateProps {
  key: string
  menu?: React.ReactElement | React.ReactNode
}

const RouteStateContext = createContext<{
  routeState?: RouteStateProps
  setRouteState?: React.Dispatch<React.SetStateAction<RouteStateProps>>
}>({})

export function RouteStateProvider({ children }) {
  const [routeState, setRouteState] = useState<RouteStateProps>({
    key: '',
  })

  return (
    <RouteStateContext.Provider
      value={{
        routeState,
        setRouteState,
      }}
    >
      {children}
    </RouteStateContext.Provider>
  )
}

export function useRouteState(): [
  RouteStateProps,
  React.Dispatch<React.SetStateAction<RouteStateProps>>,
] {
  const context = useContext(RouteStateContext)
  if (!context)
    throw new Error('useRouteState must be used within a RouteStateContext')
  const { routeState, setRouteState } = context
  return [routeState!, setRouteState!]
}

const ThemeContext = createContext<{
  theme?: any
  setTheme?: React.Dispatch<React.SetStateAction<any>>
}>({})

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(
    localStorage.getItem('mode-theme') || 'whiteTheme',
  )

  const window = useWindowDimensions()

  useEffect(() => {
    localStorage.setItem('mode-theme', theme)
  }, [theme])

  function getThemeCustom() {
    if (theme === 'whiteTheme') {
      return whiteTheme
    }

    return darkTheme
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <StyledThemeProvider
        theme={() => ({
          ...getThemeCustom(),
          isDesktop: window.width > 1024,
          isTablet: window.width >= 767 && window.width <= 1024,
          isMobile: window.width <= 767,
        })}
      >
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useTheme(): [any, React.Dispatch<React.SetStateAction<any>>] {
  const context = useContext(ThemeContext)
  if (!context) throw new Error('useTheme must be used within a ThemeContext')
  const { theme, setTheme } = context
  return [theme, setTheme!]
}
