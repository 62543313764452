import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { IconType } from 'react-icons'
import { useRouteState } from '@components/store/context'
import { MenuLink, Icons } from './style'

function SidebarItem({
  id,
  to,
  icon: Icon,
  title,
  onClick,
}: {
  icon: IconType
  title: string
  to?: any
  id?: string
  onClick?: () => void
}) {
  const [routeState] = useRouteState()

  const SidebarItemContent: React.FC = () => (
    <MenuLink>
      <Tooltip title={title}>
        <Icons active={routeState?.key === id || false}>
          <Icon />
        </Icons>
      </Tooltip>
    </MenuLink>
  )

  const onChangeRoute = () => {
    if (onClick) onClick()
  }

  return !to ? (
    <div style={{ cursor: 'pointer' }} onClick={() => onChangeRoute()}>
      <SidebarItemContent />
    </div>
  ) : (
    <Link to={to} onClick={() => onChangeRoute()}>
      <SidebarItemContent />
    </Link>
  )
}

export default SidebarItem
