import styled from 'styled-components'

export const SideBar = styled.div<{
  active?: boolean
}>`
  display: ${({ active }) => (active ? '2px solid #00a65f' : 'none')};
  position: fixed;
  width: 240px;
  height: 100%;
  background-color: ${props => props.theme.backgroundPrimaryContrast};
  margin-left: 56px;
  box-shadow: 0px 0px 1px ${props => props.theme.title};

  @media (max-width: 768px) {
    display: none;
  }
`

export const Menu = styled.div<{
  isVisible?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 2%;
  }

  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isVisible }) => (isVisible ? '365px' : '0')};
    transition: max-height 0.3s easy-in;
  }
`

export const Header = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: space-around;
  align-items: end;
  flex: wrap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.title};
`

export const Divmessage = styled.div`
  margin: 5%;
`

export const ListDash = styled.div`
  margin-top: 3%;
  height: 90vh;
  overflow: auto;
  @media (max-width: 768px) {
    display: none;
  }
`

export const ListDashNative = styled.div`
  height: 90vh;
  overflow: auto;
  @media (max-width: 768px) {
    display: block;
  }
`

export const Item = styled.div<{
  active?: boolean
}>`
  font-size: 14px;
  padding: 1px 22px 1px 22px;
  border-left: ${({ active }) =>
    active ? '2px solid #00a65f' : '2px solid transparent'};
  background-color: ${props =>
    props.active ? props.theme.backgroundHover : 'none'};

  > div {
    padding: 13px 0px;
  }
  p,
  > div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.title};
    svg {
      color: #adb5bd;
    }
  }

  &:hover {
    border-left: 2px solid #00a65f;
    background-color: ${props => props.theme.backgroundHover};
  }
`

export const MenuLink = styled.div`
  display: flex;
  padding: 0.5rem;
  text-decoration: none;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: start;
  }
`

export const MenuLinkAvatar = styled(MenuLink)`
  position: fixed;
  bottom: 10px;
  @media (max-width: 768px) {
    top: 5px;
    right: 15px;
    height: 20px;
  }
`

export const Icons = styled.div<{
  active?: boolean
}>`
  padding: 0.4rem;
  border-radius: 5px;
  color: ${({ active, theme }) =>
    active ? theme.backgroundPrimary : '#adb5bd'};
  background-color: ${({ active }) => (active ? '#00A65F' : 'none')};

  &:hover {
    background-color: #00a65f;
    color: ${props => props.theme.backgroundPrimary};
  }
`

export const IconsDivisor = styled.div`
  color: #adb5bd;
  padding: 0.4rem;
  border-radius: 5px;
  margin-bottom: 10%;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 0%;
  }
`

export const IconsDivisorResponsive = styled(IconsDivisor)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

export const IconsInvert = styled(Icons)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const Avatar = styled.img`
  border-radius: 50%;
  height: 20px;
  margin-left: 2px;
`

export const Hamburger = styled(MenuLink)`
  display: none;
  cursor: pointer;
  color: #adb5bd;
  font-size: 25px;

  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    flex-direction: row;
    width: 25px;
    height: 25px;
    justify-content: center;
    margin-left: 0;
    background-color: #75757508;
    top: 55px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24);
    right: 0;
  }
`

export const Navbar = styled.div`
  display: none;

  @media (max-width: 768px) {
    padding-top: 66px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.backgroundTerciary};
  }
`
