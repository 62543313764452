import styled from 'styled-components'

export const Nav = styled.div`
  padding: 0.8rem 24px 0.8rem 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${props => props.theme.backgroundPrimaryContrast};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24);

  @media (min-width: 768px) {
    z-index: 99;
  }
  @media (max-width: 768px) {
    padding-top: 70px;
  }
  @media (max-width: 420px) {
    justify-content: center;
  }
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
`

export const Title = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.title};
  text-decoration: none;
`
