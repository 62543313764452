import styled from 'styled-components'

export const Card = styled.div`
  background: ${props => props.theme.backgroundPrimaryContrast};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 4px;
  position: relative;

  .react-resizable-handle,
  .react-resizable-handle {
    &:after {
      border-right: 2px solid rgb(255 255 255 / 40%);
      border-bottom: 2px solid rgb(255 255 255 / 40%);
    }
  }
`

interface IContainer {
  isDrag?: boolean
}
export const Container = styled.div<IContainer>`
  .reactgridlayout {
    width: 100%;
    ${({ isDrag }) => (isDrag ? 'padding-bottom: 350px;' : '')}
    transition: .5s all;
  }
`

export const NoPermission = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.backgroundPrimaryContrast};
  z-index: 10000;
  border-radius: 3px;

  h2 {
    z-index: 10000;

    color: ${({ theme }) => theme.title};
    font-size: 1.1rem;
  }
`
