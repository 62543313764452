import styled from 'styled-components'

export const DivInput = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    color: ${props => props.theme.title};
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  input {
    margin-top: 3%;
    background: ${props => props.theme.backgroundPrimary};
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 6px;
    padding: 12px;
    border: none;
  }
`

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: end;
  button {
    margin-left: 5%;
  }
`

export const DivFilter = styled.div`
  display: inline-flex;
  flex-wrap: no-wrap;
`

export const DivVariable = styled.div`
  margin-top: 5%;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  width: 100%;
`

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const Menu = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.backgroundSecondary};
`

export const Span = styled.span<{
  active?: boolean
}>`
  width: 70px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;

  color: ${({ active, theme }) => (active ? theme.greenHighlight : '#adb5bd')};
  border-bottom: ${({ active, theme }) =>
    active ? `solid 2px ${theme.greenHighlight}` : 'none'};

  &:hover {
    color: ${props => props.theme.greenHighlight};
    border-bottom: solid 2px ${props => props.theme.greenHighlight};
  }
`

export const InputDiv = styled.div`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: ${props => props.theme.backgroundPrimary};
  border-radius: 6px;
  padding: 10px;
  color: #adb5bd;
  display: flex;
  align-items: center;
  width: 300px;
  input {
    margin-left: 5px;
    background-color: ${props => props.theme.backgroundPrimary};
    border: none;
    outline: none;
    width: 100%;
  }
`
export const Subtitle = styled.h2`
  font-size: 1.4rem;
`
export const DropDown = styled.div`
  width: 200px;
  height: 30px;
  background: red;
  border-radius: 7px;
  position: relative;

  &:hover {
    filter: brightness(0.7);
  }
`
export const ContentColaborate = styled.section`
  height: 100%;
  color: ${props => props.theme.title};
  display: flex;
  flex-direction: column;

  .dropDownColoborate {
    background-color: ${props => {
      return props.theme.backgroundSecondary || 'white'
    }};
    z-index: 13;
    width: 10.5em;
    font-family: Inter;
    font-style: normal;
    font-size: 15px;
    color: ${props => props.theme.title};
    right: 10px;
    top: 30px;
    border: 1px solid
      ${props => {
        return props.theme.backgroundSecondary || 'white'
      }};

    border-radius: 3px;
    min-width: 160px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    padding: 7px 16px;
    cursor: pointer;

    span {
      font-family: Inter;
      padding: 7px 7px;
      cursor: pointer;
      &:hover {
        border-left: 2px solid ${props => props.theme.greenHighlight};
        background: ${props => props.theme.backgroundHover};
      }
    }
  }
`
