import React, { useState, useEffect, useRef } from 'react'
import { DropDownContainer, Div, ThreeDots } from './styles'

interface DropdownProps {
  open?: boolean
  items?: [string, Function][]
  style?: any
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  open,
  items,
  style,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (visible && ref.current && !ref.current.contains(e.target)) {
        setVisible(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [visible])

  useEffect(() => {
    setVisible(open || false)
  }, [open])

  return (
    <Div>
      <ThreeDots active={visible} onClick={() => setVisible(true)} />
      <DropDownContainer ref={ref} active={visible} style={style}>
        {children ||
          (items ? (
            items.map(item => (
              <span
                onClick={() => {
                  setVisible(false)
                  if (item[1]) {
                    item[1]()
                  }
                }}
              >
                {item[0]}
              </span>
            ))
          ) : (
            <></>
          ))}
      </DropDownContainer>
    </Div>
  )
}

export default Dropdown
