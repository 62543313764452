import React, { useEffect, useState, memo } from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from 'styled-components'
import { ApexOptions } from 'apexcharts'

import ModalCustom from '@modals/custom'
import SelectDate from '@selects/select-dates'
import { LogsPeriod, Widget } from '@services/openapi'
import { PeriodInterval } from 'types/enums'
import useLogInterval from '@hooks/useLogInterval'
import Dropdown from '@components/dropdown'
import { Card, DivTitle, Title, DivContent, DivCenter } from './styles'

function Index({
  title,
  widget,
  edit,
  refresh,
  time,
  onClone,
  setModalVisibleDelete,
  setModalVisibleUpdate,
  setWidget,
}: {
  title
  widget: Widget
  edit: boolean
  refresh?: number
  time: number
  onClone?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
  setWidget?: (widget: Widget) => void
}) {
  const [value, setValue] = useState(1)
  const [modalVisibleInput, setModalVisibleInput] = useState(false)
  const [periodInterval, setPeriodInterval] = useState<PeriodInterval>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [chartSeries, setChartSeries] = useState<any>([
    [
      {
        name: '',
        data: [],
      },
    ],
  ])
  const [labels, setLabels] = useState<any[]>([])
  const [logsPeriod, setLogsPeriod] = useState<LogsPeriod>()
  const [lastModified, setLastModified] = useState<string>()
  const [currentTimestamp, setCurrentTimestamp] = useState<string>()
  const theme: any = useTheme()
  const chartRef = React.useRef(null)

  const options: ApexOptions = {
    chart: {
      type: 'line',
      group: `widget_${widget.id}`,
      width: 100,
      animations: {
        enabled: false,
        easing: 'linear',
        dynamicAnimation: {
          enabled: false,
          speed: 1000,
        },
      },
    },
    colors: [
      theme.chart1,
      theme.chart2,
      theme.chart3,
      theme.chart4,
      theme.chart5,
    ],
    legend: {
      labels: {
        colors: theme.title,
      },
    },
    noData: {
      text: 'Carregando...',
      style: {
        color: theme.title,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      labels: {
        style: {
          colors: theme.title,
        },
      },
    },
    yaxis: [
      {
        labels: {
          formatter(val) {
            return val?.toFixed(2)
          },
          style: {
            colors: theme.title,
          },
        },
      },
    ],
  }

  const { data, xLastModified, xCurrentTimestamp, error, refreshNow } =
    useLogInterval(
      widget.id!,
      periodInterval,
      startDate,
      endDate,
      logsPeriod,
      lastModified,
      currentTimestamp,
      time * 1000,
    )

  async function changeDate(v) {
    if (widget.id) {
      let interval = PeriodInterval.HOUR

      if (v == 2) {
        interval = PeriodInterval.QUARTER
      } else if (v == 3) {
        interval = PeriodInterval.TODAY
      } else if (v == 4) {
        interval = PeriodInterval.YESTERDAY
      } else if (v == 5) {
        interval = PeriodInterval.THREE_DAYS
      } else if (v == 6) {
        interval = PeriodInterval.WEEK
      } else if (v == 7) {
        interval = PeriodInterval.MONTH
      } else if (v == 8) {
        setModalVisibleInput(true)
      }

      setPeriodInterval(interval)
    }
  }

  async function setDatas(array) {
    await array.map((e, index) => {
      console.log(index, chartSeries[index], e)
      if (!chartSeries[index]) {
        chartSeries[index] = [e]
      } else {
        chartSeries[index] = [
          {
            name: e.name,
            data: e.data,
          },
        ]
      }
      return e
    })

    console.log(chartSeries)
    await setChartSeries([...chartSeries])
  }

  async function update() {
    const { logs, variables, labels: label } = logsPeriod!

    const logKeys = Object.keys(logs)

    const datas = variables.map((variableName, index) => {
      return {
        name: variableName,
        data: logs[logKeys[index]] ?? [0],
      }
    })

    console.log(logKeys, label, datas)

    await setDatas(datas)
    await setLabels(label)
  }

  useEffect(() => {
    refreshNow()
  }, [startDate, endDate, periodInterval])

  useEffect(() => {
    changeDate(value)
  }, [value])

  useEffect(() => {
    if (logsPeriod) {
      update()
    }
  }, [logsPeriod])

  useEffect(() => {
    if (data && chartRef.current !== null) {
      setLogsPeriod(data)
    }
    setLastModified(xLastModified || lastModified)
    setCurrentTimestamp(xCurrentTimestamp || currentTimestamp)
  }, [data, error])

  useEffect(() => refreshNow(), [refresh])

  function validateSeries(seriesParam: any[]) {
    let validate = false

    seriesParam.forEach(item => {
      if (item) {
        validate = true
      }
    })
    if (validate) return seriesParam

    return [
      {
        name: 'Series A',
        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
      },
    ]
  }

  return (
    <>
      <Card id="container">
        <DivTitle>
          <Title>{title}</Title>
          {edit ? (
            <Dropdown
              items={[
                [
                  'Editar',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleUpdate) setModalVisibleUpdate(true)
                  },
                ],
                ['Duplicar', () => onClone && onClone(widget)],
                [
                  'Excluir',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleDelete) setModalVisibleDelete(true)
                  },
                ],
              ]}
            />
          ) : (
            <SelectDate setValue={setValue} value={value} />
          )}
        </DivTitle>
        <DivCenter>
          <DivContent>
            {chartSeries?.map((e, i) => (
              <>
                <Chart
                  ref={chartRef}
                  key={Math.random() * 100000000}
                  options={{
                    ...options,
                    chart: {
                      ...options.chart,
                      id: `chart${i}`,
                    },
                    xaxis: {
                      categories:
                        labels.length > 0
                          ? labels
                          : [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                      ...options.xaxis,
                    },
                    colors: [theme[`chart${i + 1}`] ?? '#00A65F'],
                  }}
                  series={validateSeries(e)}
                  width="100%"
                  height="150px"
                />
              </>
            ))}
          </DivContent>
        </DivCenter>
      </Card>
      <ModalCustom
        open={modalVisibleInput}
        setOpen={setModalVisibleInput}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </>
  )
}

export default memo(Index)
