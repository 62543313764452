import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import debug from 'debug'
import Modal from 'react-modal'
import { AiOutlineClose } from 'react-icons/ai'
import { useToasts } from 'react-toast-notifications'
import { useTheme } from 'styled-components'
import { useAuth, useRouteState } from '@store/context'
import parseJwt from '@helpers/parseJwt'
import { getDeviceApi, getOrganizationApi } from '@services/api'
import Input from '@components/Input'
import { DivInput, Header, Div } from './styles'
import {
  ButtonGreen,
  ButtonWhite,
} from '../../../../assets/styles/stylesButton'

export default function Index({ open, setOpen, onAddDevice }) {
  const theme: any = useTheme()
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      backgroundColor: theme.backgroundPrimary,
      borderColor: theme.backgroundPrimaryContrast,

      boxShadow: `2px 2px 9px ${
        theme.themeName != 'whiteThem' ? '#000' : theme.title
      }`,
    },
    overlay: {
      backgroundColor: theme.modalOverlay,
    },
  }
  const { addToast } = useToasts()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [token] = useAuth()
  const localStorageToken = localStorage.getItem('@token')
  const [userId, setUser] = useState()
  const [routeState] = useRouteState()
  const { organizationId } = useParams()

  const apiDevice = getDeviceApi()
  const apiOrganization = getOrganizationApi()

  async function getToken() {
    let tokenJson
    if (localStorageToken) tokenJson = await parseJwt(localStorageToken)
    else tokenJson = await parseJwt(token)
    setUser(tokenJson.userId)
  }

  useEffect(() => {
    getToken()
  }, [localStorageToken, token])

  function closeModal() {
    setOpen(false)
  }

  async function onSubmit(e) {
    e.preventDefault()
    const data = {
      name,
      description,
      idUser: userId,
    }

    function reqThen(response) {
      if (response.data.success) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        closeModal()
        onAddDevice(response.data.data)
      } else {
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }

    function reqCatch(error) {
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      debug('api')(error)
    }

    if (routeState.key === 'organization/single/devices') {
      // is an admin editing organization
      await apiOrganization
        .organizationControllerCreateDevice(organizationId, data)
        .then(reqThen)
        .catch(reqCatch)
    } else {
      // normal user
      await apiDevice.deviceControllerCreate(data).then(reqThen).catch(reqCatch)
    }
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <Header>
        Novo dispositivo
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </Header>
      <form onSubmit={onSubmit}>
        <Input
          required
          name="name"
          label="Nome"
          onChange={e => setName(e.target.value)}
          value={name}
          placeholder="Digite um nome..."
        />
        <DivInput>
          <label htmlFor="fname">Descrição</label>
          <input
            value={description}
            required
            type="text"
            placeholder="Digite uma descrição..."
            onChange={e => setDescription(e.target.value)}
          />
        </DivInput>
        <Div>
          <ButtonWhite onClick={() => closeModal()}>Cancelar</ButtonWhite>
          <ButtonGreen>Adicionar</ButtonGreen>
        </Div>
      </form>
    </Modal>
  )
}
