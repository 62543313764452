import styled from 'styled-components'

export const ButtonGreen = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  color: ${props => props.theme.backgroundPrimary};

  padding: 2%;
  border: none;
  width: 150px;
  height: 36px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:disabled {
    background-color: #03844d;
    opacity: 0.3;
    cursor: disabled;
  }

  &:not(:disabled) {
    background-color: ${props => props.theme.greenHighlight};
    cursor: pointer;

    &:hover {
      background: #03844d;
    }
  }
`

export const ButtonRed = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  background: #a60000;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  color: white;
  padding: 2%;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:hover {
    background: #840303;
  }
`

export const ButtonWhite = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  background: ${props => props.theme.backgroundPrimary};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  color: ${props => props.theme.title};
  padding: 2%;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:hover {
    background: #e6e6e6;
  }
`

export const ButtonBoxWhite = styled.button`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  color: ${props => props.theme.title};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  background: ${props => props.theme.backgroundPrimary};
  background-color: ${({ disabled, theme }) =>
    disabled ? 'rgba(200, 200, 200, 0.24)' : theme.backgroundPrimary};
  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};

  border-radius: 6px;
  border: none;
  width: 37px;
  &:hover {
    background: rgba(200, 200, 200, 0.24);
  }
`

export const ButtonBoxWhiteMain = styled(ButtonBoxWhite)`
  margin: 0%;
  margin: 10px;
`

export const ButtonBoxGreen = styled.button`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  color: ${props => props.theme.backgroundPrimary};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: ${({ disabled, theme }) =>
    disabled ? '#03844d' : theme.greenHighlight};
  cursor: ${({ disabled }) => (disabled ? 'disabled' : 'pointer')};
  border-radius: 6px;
  border: none;
  width: 37px;
  &:hover {
    background-color: #03844d;
  }
  svg {
    fill: ${props => props.theme.backgroundPrimary};
  }
`

export const ButtonBoxRed = styled.button`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  color: ${props => props.theme.backgroundPrimary};
  cursor: pointer;
  margin: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  background-color: #ea3f3f;
  border-radius: 6px;
  border: none;
  width: 37px;
  &:hover {
    background-color: #a40000;
  }
`

export const CheckBoxWrapper = styled.div`
  position: relative;
`

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: ${props => props.theme.backgroundPrimary};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + label {
    background: #4fbe79;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`

export const ButtonRoundedGreen = styled.div`
  padding: 0.4rem;
  border-radius: 25px;
  color: ${props => props.theme.backgroundPrimary};
  background-color: ${props => props.theme.greenHighlight};

  width: 15px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.greenHighlight};
  }
`
