import React from 'react'
import { Div, Label, Select } from './styles'

export default function index({
  time,
  setTime,
}: {
  time: number
  setTime: React.Dispatch<React.SetStateAction<number>>
}) {
  async function handleChange(unit: number) {
    setTime(unit)
  }

  return (
    <Div>
      <Label>Tempo</Label>
      <Select
        className="form-control"
        value={time}
        onChange={event => handleChange(+event.target.value)}
        required
      >
        <option value="">Selecione...</option>
        <option value="30">30 seg</option>
        <option value="60">1 min</option>
        <option value="120">2 min</option>
        <option value="300">5 min</option>
      </Select>
    </Div>
  )
}
