import React, { useMemo, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BsSpeedometer2, BsSpellcheck, BsBarChart } from 'react-icons/bs'
import { VscGraphLine } from 'react-icons/vsc'
import { TiSortNumerically } from 'react-icons/ti'
import Cylinder from '@assets/icones/svg/cilindroIcone.svg'
import { WidgetTypeEnum } from '@services/openapi'
import { Header, Container, Card, Search } from '../styles'

export default function Index({ setOpen, setType }) {
  const [search, setSearch] = useState('')

  function closeModal() {
    setOpen(false)
  }

  const widgets = useMemo(
    () => [
      {
        icon: <BsSpellcheck />,
        title: 'Texto',
        description: 'Cartão utilizado para variáveis do tipo texto.',
        type: WidgetTypeEnum.Text,
      },
      {
        icon: <TiSortNumerically />,
        title: 'Numérico',
        description: 'Cartão utilizado para variáveis do tipo numéricas.',
        type: WidgetTypeEnum.Number,
      },
      {
        icon: <BsSpeedometer2 />,
        title: 'Medidor',
        description:
          'Medidor personaliza utilizado para quaisquer tipos de dados.',
        type: WidgetTypeEnum.Meter,
      },
      {
        icon: <BsBarChart />,
        title: 'Gráficos de Barras',
        description:
          'Gráfico de barras utilizados para quaisquer tipos de dados.',
        type: WidgetTypeEnum.BarChart,
      },
      {
        icon: <VscGraphLine />,
        title: 'Gráficos de Linhas',
        description:
          'Gráfico de linhas utilizados para quaisquer tipos de dados.',
        type: WidgetTypeEnum.LineChart,
      },
      {
        icon: <img src={Cylinder} alt="cilindro" />,
        title: 'Cilindro',
        description:
          'Facilita a visualização para leitura de nível de tanques.',
        type: 'CYLINDER',
      },
    ],
    [],
  )

  const Data = useMemo(() => {
    let computedComments = widgets

    if (search) {
      computedComments = computedComments.filter(comment =>
        comment.title.toLowerCase().includes(search.toLowerCase()),
      )
    }

    return computedComments
  }, [widgets, search])

  return (
    <>
      <Header>
        Adicionar widget
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </Header>
      <Search placeholder="Pesquisar por nome…" onSearch={s => setSearch(s)} />
      <Container>
        {Data.map(widget => (
          <Card key={widget.title} onClick={() => setType(widget)}>
            {widget.icon}
            <p>{widget.title}</p>
            <span>{widget.description}</span>
          </Card>
        ))}
      </Container>
    </>
  )
}
