import styled from 'styled-components'
import { Td as BaseTd } from '../styles'

export const Td = styled(BaseTd)<{ type?: boolean }>`
  padding: 15px 0px;

  article {
    width: fit-content;
    background-color: ${({ type }) => (type ? ' #acacac' : '#eff78d')};
    color: ${({ type }) => (type ? ' #ebfff7' : '#b8a420')};
    padding: 10px;
    border-radius: 6px;
  }
`
