/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import debug from 'debug'

import { BsPlusLg, BsDashLg } from 'react-icons/bs'
import { GroupingFormulas } from '@components/dynamic/groupingformulas'
import { DivButtons, Select } from '@selects/styles'
import { Widget } from '@services/openapi'
import useVariables from '@hooks/useVariables'
import ConditionalInstructionsModal from '@components/modal/manual/conditionals'

import {
  DivMultiBaseline,
  LabelNoMargin,
  Label,
  HeaderTitle,
  InputColor,
  DivInput,
} from '../styles'
import { ButtonRed, ButtonGreen } from '../../../../assets/styles/stylesButton'

function ConditionalsSection({
  device,
  onChange,
  ...props
}: {
  widget?: Widget
  device?: number
  onChange: (map: any) => void
}) {
  const actions = [
    {
      value: '',
      label: 'Fazer ação…',
    },
    {
      value: 'color',
      label: 'Trocar Cor',
    },
    {
      value: 'rotulo',
      label: 'Trocar Rótulo',
    },
    {
      value: 'unidade',
      label: 'Trocar Unidade',
    },
  ]
  const [openConditionalInstructions, setOpenConditionalInstructions] =
    useState<boolean>(false)

  const [map, setMap] = useState<any>(
    (props.widget && props.widget.customData
      ? props.widget.customData.map
      : [{ value: '', label: '', action: '', expression: '' }]) || [
      { value: '', label: '', action: '', expression: '' },
    ],
  )

  const { variables: allVariables, isError } = useVariables(device)

  useEffect(() => {
    debug('api')(isError)
  }, [isError])

  const handleAddFields = event => {
    event.preventDefault()
    event.stopPropagation()
    map.push({ value: '', label: '', action: '', expression: '' })
    setMap([...map])
    onChange([...map])
  }

  const handleRemoveFields = (event, index) => {
    event.preventDefault()
    event.stopPropagation()
    map.splice(index, 1)
    setMap([...map])
    onChange([...map])
  }

  const handleInputChange = (index, event, isExpression = false) => {
    const values: any = [...map]
    if (isExpression) {
      values[index].expression = event
    } else if (event.target.name === 'value') {
      values[index].value = event.target.value
    } else if (event.target.name === 'action') {
      if (event.target.value === 'color') values[index].value = '#000000'
      else values[index].value = ''
      values[index].action = event.target.value
    }

    setMap(values)
    onChange(values)
  }

  return (
    <div>
      <HeaderTitle>
        <Label>Mapear valores</Label>
        <a
          className="float-right"
          onClick={() => setOpenConditionalInstructions(true)}
        >
          Como escrever expressões?
        </a>
        <ConditionalInstructionsModal
          open={openConditionalInstructions}
          setOpen={setOpenConditionalInstructions}
        />
      </HeaderTitle>

      {map &&
        map.map((item, index) => (
          <div style={{ margin: '20px 0px' }}>
            <DivMultiBaseline>
              <LabelNoMargin>Se…</LabelNoMargin>
              <GroupingFormulas
                data={allVariables.map(variable => variable.tag)}
                onChange={e => handleInputChange(index, e, true)}
                defaultValue={item.expression}
              />
            </DivMultiBaseline>
            <DivMultiBaseline>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <LabelNoMargin>então </LabelNoMargin>
                  <Select
                    name="action"
                    className={`form-control ${
                      map[index].action ? 'not-empty' : 'empty'
                    }`}
                    defaultValue={map[index].action}
                    onChange={itemChange => {
                      handleInputChange(index, itemChange)
                    }}
                  >
                    {actions.map(itemSelect => (
                      <option
                        key={`${itemSelect.value}`}
                        value={itemSelect.value}
                      >
                        {itemSelect.label}
                      </option>
                    ))}
                  </Select>
                </div>
                {map[index].action && (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <LabelNoMargin>para </LabelNoMargin>
                    {map[index].action == 'color' ? (
                      <InputColor>
                        <input
                          name="value"
                          type="color"
                          defaultValue={map[index].value || '#000000'}
                          onChange={itemChange => {
                            handleInputChange(index, itemChange)
                          }}
                        />
                      </InputColor>
                    ) : (
                      <DivInput style={{ marginTop: 0 }}>
                        <input
                          style={{ marginTop: 0 }}
                          name="value"
                          type="text"
                          defaultValue={map[index].value}
                          placeholder="Novo valor"
                          onChange={itemChange => {
                            handleInputChange(index, itemChange)
                          }}
                        />
                      </DivInput>
                    )}
                  </div>
                )}
              </div>
              <DivButtons>
                {map.length > 1 && (
                  <ButtonRed
                    onClick={event => handleRemoveFields(event, index)}
                  >
                    <BsDashLg />
                  </ButtonRed>
                )}
                {map.length <= 5 && (
                  <ButtonGreen onClick={event => handleAddFields(event)}>
                    <BsPlusLg />
                  </ButtonGreen>
                )}
              </DivButtons>
            </DivMultiBaseline>
          </div>
        ))}
    </div>
  )
}

export default ConditionalsSection
