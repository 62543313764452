import styled from 'styled-components'

export const Content = styled.div<{
  color?: string
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .liquid {
    fill: ${props => props.color ?? props.theme.greenHighlight};
  }
  .liquidTop {
    fill: ${props => props.color ?? props.theme.topLiquid};
    stroke: ${props => (props.color ? '#fff' : props.theme.topLiquid)};
  }
  .disable-cylinder {
    display: none;
  }
`
