import React, { useEffect, useState, useMemo } from 'react'
import { User, UserTypeEnum } from '@services/openapi'
import Input from '@components/Input'
import { TableHeader, Pagination } from '../dataTable'
import Item from './item'
import { Div, Table } from './styles'

interface IPermission {
  user_id: number | string
  variables: any[]
}
const TableCommon = ({
  search,
  headers,
  data,
  permissions,
  setPermissions,
  dropdownSelected,
  tags,
}: {
  search
  headers
  data: User[]
  permissions: IPermission[]
  setPermissions
  dropdownSelected
  tags: any
}) => {
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })
  const [eventChanges, setEventChanges] = useState(0)
  const [selectAllNot, setSelectAllNot] = useState(false)
  const ITEMS_PER_PAGE = 7

  useEffect(() => {
    setCurrentPage(1)
  }, [search])

  function filterUserTable(userParams) {
    const { name } = userParams
    let checked = false
    permissions.forEach(itemPerm => {
      if (itemPerm.user_id == userParams.id) {
        if (dropdownSelected == 0) {
          if (tags.length == itemPerm.variables.length) {
            checked = true
          }
        } else {
          const result = itemPerm.variables.filter(
            varItem => varItem == tags[dropdownSelected - 1],
          )
          if (result) checked = true
        }
      }
    })

    return { name, checked }
  }
  const itemData = useMemo(() => {
    let computedComments = data.map(item => {
      return {
        ...item,
        showSettings: item.type != UserTypeEnum.OrganizationAdmin,
      }
    })

    if (search) {
      computedComments = computedComments.filter(device =>
        device.name.toLowerCase().includes(search.toLowerCase()),
      )
    }

    setTotalItems(computedComments ? computedComments.length : 0)

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]),
      )
    }

    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
    )
  }, [
    data,
    currentPage,
    search,
    sorting,
    dropdownSelected,
    permissions,
    selectAllNot,
    eventChanges,
  ])

  function addVariables(isChecked, userId) {
    let variables: any = []

    if (isChecked) {
      if (dropdownSelected == 0) {
        variables = [...tags]
      } else {
        let find = false
        const variablesAux: any = []
        permissions.forEach(itemPerm => {
          if (itemPerm.user_id == userId) {
            itemPerm.variables.forEach(itemVar => {
              if (itemVar == tags[dropdownSelected - 1]) {
                find = true
              }
              variablesAux.push(itemVar)
            })
          }
        })

        if (!find) variablesAux.push(tags[dropdownSelected - 1])
        variables = variablesAux
      }
    }
    if (!isChecked) {
      if (dropdownSelected != 0) {
        const tagsHave: any = []
        permissions.forEach(itemPerm => {
          if (itemPerm.user_id == userId)
            itemPerm.variables.forEach(itemVar => tagsHave.push(itemVar))
        })
        variables = tagsHave.filter(i => i != tags[dropdownSelected - 1])
      }
    }
    const userDataIndex = permissions.findIndex(i => i.user_id == userId)
    const dataPermission = {
      variables,
      user_id: userId,
    }
    const newPermission = [...permissions]

    if (userDataIndex >= 0) {
      newPermission[userDataIndex].variables = variables
    } else {
      newPermission.push(dataPermission)
    }

    setPermissions(newPermission)
    setEventChanges(eventChanges + 1)
  }

  function selectAllOrNot(eventSelectAll) {
    let findSomeThere = false

    if (dropdownSelected == 0) {
      // verify all variables has variables register some an user
      permissions.forEach(itemValidate => {
        if (itemValidate.variables.length > 0) {
          findSomeThere = true
          return
        }
      })
      if (findSomeThere) {
        // always remove all variables because its found one selected variable
        setPermissions(
          permissions.map(item => {
            return {
              ...item,
              variables: [],
            }
          }),
        )
        setSelectAllNot(false)
      } else {
        setPermissions(
          permissions.map(item => {
            return {
              ...item,
              variables: tags.filter(() => eventSelectAll),
            }
          }),
        )
        setSelectAllNot(true)
      }
    } else {
      permissions.forEach(itemState => {
        itemState.variables.forEach(variablePermission => {
          if (variablePermission == tags[dropdownSelected - 1]) {
            findSomeThere = true
            return
          }
        })
      })
      if (findSomeThere) {
        // always remove because its found one selected variable
        setPermissions(
          permissions.map(item => {
            return {
              ...item,
              variables: item.variables.filter(
                itemVariable => itemVariable != tags[dropdownSelected],
              ),
            }
          }),
        )
        setSelectAllNot(false)
      } else {
        setPermissions(
          permissions.map(item => {
            return {
              ...item,
              variables: [...item.variables, tags[dropdownSelected]],
            }
          }),
        )
        setSelectAllNot(true)
      }
    }
  }
  return (
    <>
      <Div>
        <Table>
          <TableHeader
            headers={[
              {
                name: (
                  <Input
                    onChange={e => selectAllOrNot(e.target.checked)}
                    label
                    type="checkbox"
                    checked={selectAllNot}
                  />
                ),
              },
              ...headers,
            ]}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {itemData.map(userItem => (
              <Item
                addVariables={e => addVariables(e, userItem.id)}
                key={`${userItem.id}${dropdownSelected}${eventChanges}`}
                user={filterUserTable(userItem)}
              />
            ))}
          </tbody>
        </Table>
        {totalItems != 0 && (
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={page => setCurrentPage(page)}
          />
        )}
      </Div>
    </>
  )
}

export default TableCommon
