import styled from 'styled-components'

export const Div = styled.div`
  margin-top: 2%;
  width: 100%;
`

export const Table = styled.table`
  width: 100%;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  font-family: Inter, sans-serif;
  background-color: ${props => props.theme.backgroundPrimary};

  .bigText {
    word-wrap: break-word;
    max-width: 200px;
  }

  td {
    padding-left: 25px;
    text-align: start;
    position: relative;
  }

  th {
    padding: 25px;
    text-align: start;
  }

  thead {
    color: ${props => props.theme.title};
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  tbody {
    font-family: Inter, sans-serif;
    font-size: 12px;
    color: ${props => props.theme.tbody};
    background-color: ${props => props.theme.backgroundPrimaryContrast};
  }
`

export const Td = styled.td<{
  active?: boolean
}>`
  span {
    background-color: ${({ active }) => (active ? '#DEFFEE' : '#FFE6E4')};
    color: ${({ active }) => (active ? '#4c9a78' : '#F16063')};
    padding: 10px;
    border-radius: 6px;
  }
`

export const TdMore = styled.td`
  cursor: pointer;
`
