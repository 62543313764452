import React, { useEffect, useState } from 'react'

import { convertDateInString } from '@utils/date-graphic'
import { Log, Widget, WidgetRange, WidgetUnit } from '@services/openapi'
import useLastLog from '@hooks/useLastLog'
import Dropdown from '@components/dropdown'
import parseConditionalsCustomization from '@utils/parseConditionalsCustomization'
import {
  CardMetter,
  DivTitle,
  Title,
  UnitsSpeed,
  UnitSpeedSpan,
  Value,
  CenterGauge,
} from './styles'

export default function Index({
  time,
  title,
  units,
  range,
  widget,
  refresh,
  edit,
  onClone,
  setModalVisibleDelete,
  setModalVisibleUpdate,
  setWidget,
}: {
  time: number
  title: string
  units?: WidgetUnit
  range?: WidgetRange
  widget: Widget
  refresh?: number
  edit: boolean
  onClone?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
  setWidget?: (widget: Widget) => void
}) {
  const [date, setDate] = useState('')
  const [value, setValue] = useState(0)
  const [log, setLog] = useState<Log>()
  const [lastModified, setLastModified] = useState<string>()

  const { data, lastModifiedTimestamp, error, refreshNow } = useLastLog(
    widget.variables,
    log,
    lastModified,
    time * 1000,
  )

  useEffect(() => {
    if (data) {
      setLog(data as Log)
    }
    setLastModified(lastModifiedTimestamp || lastModified)
  }, [data, error])

  useEffect(() => refreshNow(), [refresh])

  useEffect(() => {
    setDate(convertDateInString(log?.date))
    setValue(+(log?.value || '0'))
  }, [log])

  function renderValueWithExpression() {
    const { color, rotulo, unidade } = parseConditionalsCustomization(
      widget.customData.map,
      widget.variableList,
      value,
    )

    const unit = unidade || units?.valueUnits

    return (
      <CenterGauge
        value={value}
        min={range?.minimumValue || 0}
        max={range?.maximumValue || 100}
        color={color}
      >
        <div className="gauge">
          <div className="gauge__body">
            <div className="gauge__fill" />
            <div className="gauge__cover" />
            <UnitsSpeed>
              <div>
                <Value color={color}>
                  {rotulo ?? value.toFixed(units?.decimalPlaces)}
                </Value>
                {unit ? <span>{unit}</span> : null}
              </div>
            </UnitsSpeed>
          </div>
        </div>
      </CenterGauge>
    )
  }

  return (
    <>
      <CardMetter id="container">
        <DivTitle>
          <Title>{title}</Title>
          {edit && (
            <Dropdown
              items={[
                [
                  'Editar',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleUpdate) setModalVisibleUpdate(true)
                  },
                ],
                ['Duplicar', () => onClone && onClone(widget)],
                [
                  'Excluir',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleDelete) setModalVisibleDelete(true)
                  },
                ],
              ]}
            />
          )}
        </DivTitle>
        {value !== undefined && value !== null && renderValueWithExpression()}
        <UnitSpeedSpan>
          <small>{date}</small>
        </UnitSpeedSpan>
      </CardMetter>
    </>
  )
}
