import { debug } from 'debug'
import useSWR, { mutate } from 'swr'
import { getLogApi } from '@services/api'
import { Log } from '@services/openapi'

export default (
  variables: number[],
  lastLog: Log | Log[] | undefined,
  lastModified: string | undefined,
  interval: number,
) => {
  const { data, error } = useSWR(
    variables.length > 0 && variables.filter(v => v > 0).length > 0
      ? ['logsControllerGetLogs', variables]
      : null,
    (url, v) =>
      getLogApi()
        .logsControllerGetLogs(
          v.join('-'),
          lastModified ? +lastModified : undefined,
        )
        .then(response => {
          return {
            log: response.data.data,
            lastModifiedTimestamp: response.headers['x-last-modified'],
          }
        })
        .catch(e => {
          debug('api')(e)
          return {
            log: lastLog,
            lastModifiedTimestamp: lastModified,
          }
        }),
    {
      refreshInterval: interval,
    },
  )

  return {
    data: data?.log,
    lastModifiedTimestamp: data?.lastModifiedTimestamp,
    error,
    refreshNow: () => {
      mutate(['logsControllerGetLogs', variables])
    },
  }
}
