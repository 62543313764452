import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { User, UserTypeEnum } from '@services/api'
import SideBar from '@sidebar/index'
import { useAuth, useRouteState } from '@components/store/context'
import Navbar from '@components/navbar/organization/members'
import SingleOrganizationMenu from '@components/sidebar/menu/organization/Single'
import NotFound from '@components/errors/notFound'
import MembersTable from '@components/pages/members'
import Main from '@layout/Main'

export default function OrganizationMembers() {
  const [user] = useAuth()
  const { organizationId } = useParams()
  const [, setRouteState] = useRouteState()

  const [newMember, addMember] = useState<User>()

  useEffect(() => {
    setRouteState!({
      key: 'organization/single/members',
      menu: (
        <SingleOrganizationMenu
          id={
            organizationId ??
            (typeof user.organization === 'object'
              ? user.organization.id
              : user.organization)
          }
        />
      ),
    })
  }, [])

  return (
    <>
      <SideBar />
      {user.type === UserTypeEnum.Admin || user.organization ? (
        <>
          <Navbar
            organizationId={organizationId ?? user.organization}
            onAddMember={addMember}
          />
          <Main>
            <MembersTable
              ofOrganization={organizationId ?? user.organization.id}
              addMember={newMember}
            />
          </Main>
        </>
      ) : (
        <Main>
          <NotFound />
        </Main>
      )}
    </>
  )
}
