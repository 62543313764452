/* tslint:disable */
/* eslint-disable */
/**
 * interagi.io API
 * API do interagi.io
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: dev@trialent.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiPagination
 */
export interface ApiPagination {
    /**
     * 
     * @type {number}
     * @memberof ApiPagination
     */
    'foundRows': number;
    /**
     * 
     * @type {number}
     * @memberof ApiPagination
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ApiPagination
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ApiPagination
     */
    'pages'?: number;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiResponse
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponse
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseBoolean
 */
export interface ApiResponseBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBoolean
     */
    'data': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBoolean
     */
    'error': boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBoolean
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBoolean
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseBoolean
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseBoolean
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseCloneWidget
 */
export interface ApiResponseCloneWidget {
    /**
     * 
     * @type {CloneWidget}
     * @memberof ApiResponseCloneWidget
     */
    'data': CloneWidget;
    /**
     * 
     * @type {CloneWidget}
     * @memberof ApiResponseCloneWidget
     */
    'error': CloneWidget;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseCloneWidget
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseCloneWidget
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseCloneWidget
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseCloneWidget
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseDashboard
 */
export interface ApiResponseDashboard {
    /**
     * 
     * @type {Dashboard}
     * @memberof ApiResponseDashboard
     */
    'data': Dashboard;
    /**
     * 
     * @type {Dashboard}
     * @memberof ApiResponseDashboard
     */
    'error': Dashboard;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseDashboard
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseDashboard
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseDashboard
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseDashboard
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseDashboardList
 */
export interface ApiResponseDashboardList {
    /**
     * 
     * @type {Array<Dashboard>}
     * @memberof ApiResponseDashboardList
     */
    'data': Array<Dashboard>;
    /**
     * 
     * @type {Array<Dashboard>}
     * @memberof ApiResponseDashboardList
     */
    'error': Array<Dashboard>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseDashboardList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseDashboardList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseDashboardList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseDashboardList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseDashboardPeriod
 */
export interface ApiResponseDashboardPeriod {
    /**
     * 
     * @type {DashboardPeriod}
     * @memberof ApiResponseDashboardPeriod
     */
    'data': DashboardPeriod;
    /**
     * 
     * @type {DashboardPeriod}
     * @memberof ApiResponseDashboardPeriod
     */
    'error': DashboardPeriod;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseDashboardPeriod
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseDashboardPeriod
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseDashboardPeriod
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseDashboardPeriod
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseDevice
 */
export interface ApiResponseDevice {
    /**
     * 
     * @type {Device}
     * @memberof ApiResponseDevice
     */
    'data': Device;
    /**
     * 
     * @type {Device}
     * @memberof ApiResponseDevice
     */
    'error': Device;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseDevice
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseDevice
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseDevice
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseDevice
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseDeviceList
 */
export interface ApiResponseDeviceList {
    /**
     * 
     * @type {Array<Device>}
     * @memberof ApiResponseDeviceList
     */
    'data': Array<Device>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof ApiResponseDeviceList
     */
    'error': Array<Device>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseDeviceList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseDeviceList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseDeviceList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseDeviceList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseLog
 */
export interface ApiResponseLog {
    /**
     * 
     * @type {Log}
     * @memberof ApiResponseLog
     */
    'data': Log;
    /**
     * 
     * @type {Log}
     * @memberof ApiResponseLog
     */
    'error': Log;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseLog
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseLog
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseLog
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseLog
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseLogOrLogs
 */
export interface ApiResponseLogOrLogs {
    /**
     * 
     * @type {Log | Array<Log>}
     * @memberof ApiResponseLogOrLogs
     */
    'data'?: Log | Array<Log>;
    /**
     * 
     * @type {Log | Array<Log>}
     * @memberof ApiResponseLogOrLogs
     */
    'error'?: Log | Array<Log>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseLogOrLogs
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseLogOrLogs
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseLogOrLogs
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseLogOrLogs
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseLogsPeriod
 */
export interface ApiResponseLogsPeriod {
    /**
     * 
     * @type {LogsPeriod}
     * @memberof ApiResponseLogsPeriod
     */
    'data': LogsPeriod;
    /**
     * 
     * @type {LogsPeriod}
     * @memberof ApiResponseLogsPeriod
     */
    'error': LogsPeriod;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseLogsPeriod
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseLogsPeriod
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseLogsPeriod
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseLogsPeriod
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseNewWidget
 */
export interface ApiResponseNewWidget {
    /**
     * 
     * @type {NewWidget}
     * @memberof ApiResponseNewWidget
     */
    'data': NewWidget;
    /**
     * 
     * @type {NewWidget}
     * @memberof ApiResponseNewWidget
     */
    'error': NewWidget;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseNewWidget
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseNewWidget
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseNewWidget
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseNewWidget
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseOrganization
 */
export interface ApiResponseOrganization {
    /**
     * 
     * @type {Organization}
     * @memberof ApiResponseOrganization
     */
    'data': Organization;
    /**
     * 
     * @type {Organization}
     * @memberof ApiResponseOrganization
     */
    'error': Organization;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseOrganization
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseOrganization
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseOrganization
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseOrganization
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseOrganizationList
 */
export interface ApiResponseOrganizationList {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof ApiResponseOrganizationList
     */
    'data': Array<Organization>;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof ApiResponseOrganizationList
     */
    'error': Array<Organization>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseOrganizationList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseOrganizationList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseOrganizationList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseOrganizationList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseString
 */
export interface ApiResponseString {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseString
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseString
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseString
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseSystemLog
 */
export interface ApiResponseSystemLog {
    /**
     * 
     * @type {SystemLog}
     * @memberof ApiResponseSystemLog
     */
    'data': SystemLog;
    /**
     * 
     * @type {SystemLog}
     * @memberof ApiResponseSystemLog
     */
    'error': SystemLog;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseSystemLog
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseSystemLog
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseSystemLog
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseSystemLog
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseSystemLogList
 */
export interface ApiResponseSystemLogList {
    /**
     * 
     * @type {Array<SystemLog>}
     * @memberof ApiResponseSystemLogList
     */
    'data': Array<SystemLog>;
    /**
     * 
     * @type {Array<SystemLog>}
     * @memberof ApiResponseSystemLogList
     */
    'error': Array<SystemLog>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseSystemLogList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseSystemLogList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseSystemLogList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseSystemLogList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseUserList
 */
export interface ApiResponseUserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof ApiResponseUserList
     */
    'data': Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof ApiResponseUserList
     */
    'error': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseUserList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseUserList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseUserList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseUserList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseVariable
 */
export interface ApiResponseVariable {
    /**
     * 
     * @type {Variable}
     * @memberof ApiResponseVariable
     */
    'data': Variable;
    /**
     * 
     * @type {Variable}
     * @memberof ApiResponseVariable
     */
    'error': Variable;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseVariable
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseVariable
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseVariable
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseVariable
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseVariableList
 */
export interface ApiResponseVariableList {
    /**
     * 
     * @type {Array<Variable>}
     * @memberof ApiResponseVariableList
     */
    'data': Array<Variable>;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof ApiResponseVariableList
     */
    'error': Array<Variable>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseVariableList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseVariableList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseVariableList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseVariableList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseWidget
 */
export interface ApiResponseWidget {
    /**
     * 
     * @type {Widget}
     * @memberof ApiResponseWidget
     */
    'data': Widget;
    /**
     * 
     * @type {Widget}
     * @memberof ApiResponseWidget
     */
    'error': Widget;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseWidget
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseWidget
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseWidget
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseWidget
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseWidgetList
 */
export interface ApiResponseWidgetList {
    /**
     * 
     * @type {Array<Widget>}
     * @memberof ApiResponseWidgetList
     */
    'data': Array<Widget>;
    /**
     * 
     * @type {Array<Widget>}
     * @memberof ApiResponseWidgetList
     */
    'error': Array<Widget>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseWidgetList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseWidgetList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseWidgetList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseWidgetList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface ApiResponseWidgetPositionList
 */
export interface ApiResponseWidgetPositionList {
    /**
     * 
     * @type {Array<WidgetPosition>}
     * @memberof ApiResponseWidgetPositionList
     */
    'data': Array<WidgetPosition>;
    /**
     * 
     * @type {Array<WidgetPosition>}
     * @memberof ApiResponseWidgetPositionList
     */
    'error': Array<WidgetPosition>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseWidgetPositionList
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseWidgetPositionList
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseWidgetPositionList
     */
    'message': string;
    /**
     * 
     * @type {ApiPagination}
     * @memberof ApiResponseWidgetPositionList
     */
    'pagination'?: ApiPagination;
}
/**
 * 
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * 
     * @type {number}
     * @memberof Auth
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
    /**
     * 
     * @type {number}
     * @memberof BaseEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseEntity
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEntity
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface BaseFilter
 */
export interface BaseFilter {
    /**
     * Items per page
     * @type {number}
     * @memberof BaseFilter
     */
    'limit'?: number;
    /**
     * Page
     * @type {number}
     * @memberof BaseFilter
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface ChangePasswordBody
 */
export interface ChangePasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBody
     */
    'confirmPassword': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ChangeSetType {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
    DELETE_EARLY = 'delete_early'
}

/**
 * 
 * @export
 * @interface CloneWidget
 */
export interface CloneWidget {
    /**
     * 
     * @type {number}
     * @memberof CloneWidget
     */
    'newId': number;
    /**
     * 
     * @type {Array<WidgetPosition>}
     * @memberof CloneWidget
     */
    'newPosition': Array<WidgetPosition>;
}
/**
 * 
 * @export
 * @interface CreateOrUpdatePermissionBody
 */
export interface CreateOrUpdatePermissionBody {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdatePermissionBody
     */
    'userId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateOrUpdatePermissionBody
     */
    'variableIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdatePermissionBody
     */
    'deviceId': number;
}
/**
 * 
 * @export
 * @interface CreateUserBody
 */
export interface CreateUserBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserBody
     */
    'iAgree': boolean;
}
/**
 * 
 * @export
 * @interface CreateUserOrganizationBody
 */
export interface CreateUserOrganizationBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserOrganizationBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOrganizationBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOrganizationBody
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserOrganizationBody
     */
    'iAgree': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOrganizationBody
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserOrganizationBody
     */
    'organizationId': number;
}
/**
 * 
 * @export
 * @interface CreateWidgetBody
 */
export interface CreateWidgetBody {
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetBody
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetBody
     */
    'type'?: CreateWidgetBodyTypeEnum;
    /**
     * 
     * @type {WidgetCustomData}
     * @memberof CreateWidgetBody
     */
    'customData'?: WidgetCustomData;
    /**
     * 
     * @type {number}
     * @memberof CreateWidgetBody
     */
    'dashboardId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateWidgetBody
     */
    'deviceId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateWidgetBody
     */
    'variables'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateWidgetBody
     */
    'size'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateWidgetBodyTypeEnum {
    Text = 'TEXT',
    Number = 'NUMBER',
    Meter = 'METER',
    BarChart = 'BAR_CHART',
    LineChart = 'LINE_CHART',
    Cylinder = 'CYLINDER'
}

/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'time': number;
    /**
     * 
     * @type {Array<WidgetPosition>}
     * @memberof Dashboard
     */
    'position': Array<WidgetPosition>;
    /**
     * 
     * @type {boolean}
     * @memberof Dashboard
     */
    'disabled': boolean;
    /**
     * 
     * @type {User}
     * @memberof Dashboard
     */
    'user': User;
    /**
     * 
     * @type {Array<Widget>}
     * @memberof Dashboard
     */
    'widgets': Array<Widget>;
    /**
     * 
     * @type {number}
     * @memberof Dashboard
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface DashboardPeriod
 */
export interface DashboardPeriod {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof DashboardPeriod
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {object}
     * @memberof DashboardPeriod
     */
    'graphic': object;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'connected': boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'connectedDevice': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'token': string;
    /**
     * 
     * @type {any}
     * @memberof Device
     */
    'organization': any;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'disabled': boolean;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof Device
     */
    'variables': Array<Variable>;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'variableCount': number;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof Device
     */
    'permission': Array<Permission>;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface DeviceBody
 */
export interface DeviceBody {
    /**
     * 
     * @type {string}
     * @memberof DeviceBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceBody
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface EditUserBody
 */
export interface EditUserBody {
    /**
     * 
     * @type {string}
     * @memberof EditUserBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserBody
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof EditUserBody
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * 
     * @type {number}
     * @memberof Log
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'date': string;
    /**
     * 
     * @type {any}
     * @memberof Log
     */
    'variable': any;
}
/**
 * 
 * @export
 * @interface LogDataBody
 */
export interface LogDataBody {
    /**
     * 
     * @type {string}
     * @memberof LogDataBody
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof LogDataBody
     */
    'tag': string;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof Login
     */
    'rememberMe': boolean;
}
/**
 * 
 * @export
 * @interface LogsPeriod
 */
export interface LogsPeriod {
    /**
     * 
     * @type {object}
     * @memberof LogsPeriod
     */
    'logs': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof LogsPeriod
     */
    'labels': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LogsPeriod
     */
    'variables': Array<string>;
}
/**
 * 
 * @export
 * @interface NewWidget
 */
export interface NewWidget {
    /**
     * 
     * @type {Widget}
     * @memberof NewWidget
     */
    'newWidget': Widget;
    /**
     * 
     * @type {Array<WidgetPosition>}
     * @memberof NewWidget
     */
    'newPosition': Array<WidgetPosition>;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Organization
     */
    'organizationAdmins': Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Organization
     */
    'staff': Array<User>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof Organization
     */
    'devices': Array<Device>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'logo': string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'disabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'plan': OrganizationPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'logsCount': number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'staffsCount': number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'deviceCount': number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'updatedAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OrganizationPlanEnum {
    Basic = 'BASIC',
    Premium = 'PREMIUM'
}

/**
 * 
 * @export
 * @interface OrganizationBody
 */
export interface OrganizationBody {
    /**
     * 
     * @type {string}
     * @memberof OrganizationBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {any}
     * @memberof Permission
     */
    'device': any;
    /**
     * 
     * @type {Array<any>}
     * @memberof Permission
     */
    'variables': Array<any>;
    /**
     * 
     * @type {any}
     * @memberof Permission
     */
    'user': any;
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordBody
 */
export interface ResetPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordBody
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordBody
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordBody
     */
    'confirmPassword': string;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordBody
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface Searchable
 */
export interface Searchable {
    /**
     * 
     * @type {string}
     * @memberof Searchable
     */
    'search'?: string;
    /**
     * Items per page
     * @type {number}
     * @memberof Searchable
     */
    'limit'?: number;
    /**
     * Page
     * @type {number}
     * @memberof Searchable
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface SendResetPassword
 */
export interface SendResetPassword {
    /**
     * 
     * @type {string}
     * @memberof SendResetPassword
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SystemLog
 */
export interface SystemLog {
    /**
     * 
     * @type {ChangeSetType}
     * @memberof SystemLog
     */
    'operation': ChangeSetType;
    /**
     * 
     * @type {string}
     * @memberof SystemLog
     */
    'entity': string;
    /**
     * 
     * @type {number}
     * @memberof SystemLog
     */
    'rowId': number;
    /**
     * 
     * @type {User}
     * @memberof SystemLog
     */
    'user': User;
    /**
     * 
     * @type {SystemLogBrief}
     * @memberof SystemLog
     */
    'brief': SystemLogBrief;
    /**
     * 
     * @type {object}
     * @memberof SystemLog
     */
    'previousValues': object;
    /**
     * 
     * @type {object}
     * @memberof SystemLog
     */
    'nextValues': object;
    /**
     * 
     * @type {number}
     * @memberof SystemLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLog
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLog
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SystemLogBrief
 */
export interface SystemLogBrief {
    /**
     * 
     * @type {number}
     * @memberof SystemLogBrief
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemLogBrief
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SystemLogBrief
     */
    'email': string;
    /**
     * 
     * @type {UserRole}
     * @memberof SystemLogBrief
     */
    'as': UserRole;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag': string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'checked': boolean;
}
/**
 * 
 * @export
 * @interface UpdateBody
 */
export interface UpdateBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateBody
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBody
     */
    'time'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'remember'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'banned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'agreeTerms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'type': UserTypeEnum;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof User
     */
    'permission': Array<Permission>;
    /**
     * 
     * @type {Organization}
     * @memberof User
     */
    'organization': Organization;
    /**
     * 
     * @type {Array<Dashboard>}
     * @memberof User
     */
    'dashboards': Array<Dashboard>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserTypeEnum {
    Admin = 'ADMIN',
    Staff = 'STAFF',
    OrganizationAdmin = 'ORGANIZATION_ADMIN'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserRole {
    ADMIN = 'ADMIN',
    STAFF = 'STAFF',
    ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN'
}

/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    'tag': string;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    'storeData': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    'disabled': boolean;
    /**
     * 
     * @type {any}
     * @memberof Variable
     */
    'device': any;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    'logsCount': number;
    /**
     * 
     * @type {Array<Log>}
     * @memberof Variable
     */
    'logs': Array<Log>;
    /**
     * 
     * @type {number}
     * @memberof Variable
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface Widget
 */
export interface Widget {
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    'type': WidgetTypeEnum;
    /**
     * 
     * @type {WidgetCustomData}
     * @memberof Widget
     */
    'customData': WidgetCustomData;
    /**
     * 
     * @type {any}
     * @memberof Widget
     */
    'dashboard': any;
    /**
     * 
     * @type {Array<number>}
     * @memberof Widget
     */
    'variables': Array<number>;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof Widget
     */
    'variableList': Array<Variable>;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof Widget
     */
    'variableListWithTag': Array<Variable>;
    /**
     * 
     * @type {boolean}
     * @memberof Widget
     */
    'isAllowed': boolean;
    /**
     * 
     * @type {Device}
     * @memberof Widget
     */
    'device': Device;
    /**
     * 
     * @type {boolean}
     * @memberof Widget
     */
    'disabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    'updatedAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WidgetTypeEnum {
    Text = 'TEXT',
    Number = 'NUMBER',
    Meter = 'METER',
    BarChart = 'BAR_CHART',
    LineChart = 'LINE_CHART',
    Cylinder = 'CYLINDER'
}

/**
 * 
 * @export
 * @interface WidgetCustomData
 */
export interface WidgetCustomData {
    /**
     * 
     * @type {WidgetIcon}
     * @memberof WidgetCustomData
     */
    'icon'?: WidgetIcon;
    /**
     * 
     * @type {WidgetUnit}
     * @memberof WidgetCustomData
     */
    'units'?: WidgetUnit;
    /**
     * 
     * @type {number}
     * @memberof WidgetCustomData
     */
    'size'?: number;
    /**
     * 
     * @type {Array<WidgetValueMap>}
     * @memberof WidgetCustomData
     */
    'map'?: Array<WidgetValueMap>;
    /**
     * 
     * @type {WidgetRange}
     * @memberof WidgetCustomData
     */
    'range'?: WidgetRange;
    /**
     * 
     * @type {Array<WidgetValueNotification>}
     * @memberof WidgetCustomData
     */
    'notification'?: Array<WidgetValueNotification>;
}
/**
 * 
 * @export
 * @interface WidgetIcon
 */
export interface WidgetIcon {
    /**
     * 
     * @type {boolean}
     * @memberof WidgetIcon
     */
    'showIcon': boolean;
    /**
     * 
     * @type {string}
     * @memberof WidgetIcon
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface WidgetPosition
 */
export interface WidgetPosition {
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'h': number;
    /**
     * 
     * @type {string}
     * @memberof WidgetPosition
     */
    'i': string;
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'w': number;
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'minW': number;
    /**
     * 
     * @type {number}
     * @memberof WidgetPosition
     */
    'minH': number;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetPosition
     */
    'moved': boolean;
}
/**
 * 
 * @export
 * @interface WidgetRange
 */
export interface WidgetRange {
    /**
     * 
     * @type {number}
     * @memberof WidgetRange
     */
    'minimumValue': number;
    /**
     * 
     * @type {number}
     * @memberof WidgetRange
     */
    'maximumValue': number;
}
/**
 * 
 * @export
 * @interface WidgetUnit
 */
export interface WidgetUnit {
    /**
     * 
     * @type {boolean}
     * @memberof WidgetUnit
     */
    'showUnits': boolean;
    /**
     * 
     * @type {number}
     * @memberof WidgetUnit
     */
    'decimalPlaces'?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetUnit
     */
    'valueUnits'?: string;
}
/**
 * 
 * @export
 * @interface WidgetValueMap
 */
export interface WidgetValueMap {
    /**
     * 
     * @type {string}
     * @memberof WidgetValueMap
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof WidgetValueMap
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof WidgetValueMap
     */
    'expression': string;
}
/**
 * 
 * @export
 * @interface WidgetValueNotification
 */
export interface WidgetValueNotification {
    /**
     * 
     * @type {string}
     * @memberof WidgetValueNotification
     */
    'expression': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetValueNotification
     */
    'notify': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WidgetValueNotification
     */
    'message': string;
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dashboard by id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGet: async (dashboardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('dashboardControllerGet', 'dashboardId', dashboardId)
            const localVarPath = `/dashboard/{dashboardId}`
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user dashboards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetAllByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all widgets by dashboard id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetAllWidgets: async (dashboardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('dashboardControllerGetAllWidgets', 'dashboardId', dashboardId)
            const localVarPath = `/dashboard/{dashboardId}/widgets`
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logs by period
         * @param {any} start 
         * @param {any} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerIndex: async (start: any, end: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('dashboardControllerIndex', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('dashboardControllerIndex', 'end', end)
            const localVarPath = `/dashboard/{start}/{end}`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove dashboard
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerRemove: async (dashboardId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('dashboardControllerRemove', 'dashboardId', dashboardId)
            const localVarPath = `/dashboard/{dashboardId}`
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dashboard
         * @param {number} dashboardId 
         * @param {UpdateBody} [updateBody] UpdateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerUpdate: async (dashboardId: number, updateBody?: UpdateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('dashboardControllerUpdate', 'dashboardId', dashboardId)
            const localVarPath = `/dashboard/{dashboardId}/update`
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dashboard layout
         * @param {number} dashboardId 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerUpdateLayout: async (dashboardId: number, requestBody?: Array<object>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('dashboardControllerUpdateLayout', 'dashboardId', dashboardId)
            const localVarPath = `/dashboard/{dashboardId}/layout`
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dashboard by id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGet(dashboardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGet(dashboardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user dashboards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetAllByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDashboardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetAllByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all widgets by dashboard id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerGetAllWidgets(dashboardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseWidgetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerGetAllWidgets(dashboardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get logs by period
         * @param {any} start 
         * @param {any} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerIndex(start: any, end: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDashboardPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerIndex(start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove dashboard
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerRemove(dashboardId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerRemove(dashboardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update dashboard
         * @param {number} dashboardId 
         * @param {UpdateBody} [updateBody] UpdateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerUpdate(dashboardId: number, updateBody?: UpdateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerUpdate(dashboardId, updateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update dashboard layout
         * @param {number} dashboardId 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardControllerUpdateLayout(dashboardId: number, requestBody?: Array<object>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardControllerUpdateLayout(dashboardId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerCreate(options?: any): AxiosPromise<ApiResponseDashboard> {
            return localVarFp.dashboardControllerCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dashboard by id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGet(dashboardId: number, options?: any): AxiosPromise<ApiResponseDashboard> {
            return localVarFp.dashboardControllerGet(dashboardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user dashboards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetAllByUser(options?: any): AxiosPromise<ApiResponseDashboardList> {
            return localVarFp.dashboardControllerGetAllByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all widgets by dashboard id
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerGetAllWidgets(dashboardId: number, options?: any): AxiosPromise<ApiResponseWidgetList> {
            return localVarFp.dashboardControllerGetAllWidgets(dashboardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get logs by period
         * @param {any} start 
         * @param {any} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerIndex(start: any, end: any, options?: any): AxiosPromise<ApiResponseDashboardPeriod> {
            return localVarFp.dashboardControllerIndex(start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove dashboard
         * @param {number} dashboardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerRemove(dashboardId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.dashboardControllerRemove(dashboardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dashboard
         * @param {number} dashboardId 
         * @param {UpdateBody} [updateBody] UpdateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerUpdate(dashboardId: number, updateBody?: UpdateBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.dashboardControllerUpdate(dashboardId, updateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dashboard layout
         * @param {number} dashboardId 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardControllerUpdateLayout(dashboardId: number, requestBody?: Array<object>, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.dashboardControllerUpdateLayout(dashboardId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Create new dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerCreate(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dashboard by id
     * @param {number} dashboardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGet(dashboardId: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGet(dashboardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user dashboards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetAllByUser(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetAllByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all widgets by dashboard id
     * @param {number} dashboardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerGetAllWidgets(dashboardId: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerGetAllWidgets(dashboardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get logs by period
     * @param {any} start 
     * @param {any} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerIndex(start: any, end: any, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerIndex(start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove dashboard
     * @param {number} dashboardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerRemove(dashboardId: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerRemove(dashboardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dashboard
     * @param {number} dashboardId 
     * @param {UpdateBody} [updateBody] UpdateBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerUpdate(dashboardId: number, updateBody?: UpdateBody, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerUpdate(dashboardId, updateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dashboard layout
     * @param {number} dashboardId 
     * @param {Array<object>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardControllerUpdateLayout(dashboardId: number, requestBody?: Array<object>, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardControllerUpdateLayout(dashboardId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create device
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCreate: async (deviceBody?: DeviceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerDisabled: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceControllerDisabled', 'deviceId', deviceId)
            const localVarPath = `/device/{deviceId}/disabled`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGet: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceControllerGet', 'deviceId', deviceId)
            const localVarPath = `/device/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAllByOrganizationAdmin: async (organizationId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deviceControllerGetAllByOrganizationAdmin', 'organizationId', organizationId)
            const localVarPath = `/device/all/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAllByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all device variables
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetVariables: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceControllerGetVariables', 'deviceId', deviceId)
            const localVarPath = `/device/{deviceId}/variables`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log value
         * @param {string} deviceToken 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerLog: async (deviceToken: string, requestBody?: Array<object>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('deviceControllerLog', 'deviceToken', deviceToken)
            const localVarPath = `/device/{deviceToken}/log`
                .replace(`{${"deviceToken"}}`, encodeURIComponent(String(deviceToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerRemove: async (deviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceControllerRemove', 'deviceId', deviceId)
            const localVarPath = `/device/{deviceId}/remove`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search devices by name
         * @param {string} deviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerSearch: async (deviceName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceName' is not null or undefined
            assertParamExists('deviceControllerSearch', 'deviceName', deviceName)
            const localVarPath = `/device/{deviceName}/search`
                .replace(`{${"deviceName"}}`, encodeURIComponent(String(deviceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update device
         * @param {number} deviceId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerUpdate: async (deviceId: number, deviceBody?: DeviceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deviceControllerUpdate', 'deviceId', deviceId)
            const localVarPath = `/device/{deviceId}/update`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create device
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerCreate(deviceBody?: DeviceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerCreate(deviceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerDisabled(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerDisabled(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerGet(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerGet(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerGetAllByOrganizationAdmin(organizationId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerGetAllByOrganizationAdmin(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerGetAllByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeviceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerGetAllByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all device variables
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerGetVariables(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVariableList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerGetVariables(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log value
         * @param {string} deviceToken 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerLog(deviceToken: string, requestBody?: Array<object>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerLog(deviceToken, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerRemove(deviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerRemove(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search devices by name
         * @param {string} deviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerSearch(deviceName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerSearch(deviceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update device
         * @param {number} deviceId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceControllerUpdate(deviceId: number, deviceBody?: DeviceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceControllerUpdate(deviceId, deviceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create device
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerCreate(deviceBody?: DeviceBody, options?: any): AxiosPromise<ApiResponseDevice> {
            return localVarFp.deviceControllerCreate(deviceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerDisabled(deviceId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.deviceControllerDisabled(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGet(deviceId: number, options?: any): AxiosPromise<ApiResponseDevice> {
            return localVarFp.deviceControllerGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAll(options?: any): AxiosPromise<ApiResponseDeviceList> {
            return localVarFp.deviceControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAllByOrganizationAdmin(organizationId: any, options?: any): AxiosPromise<ApiResponseDeviceList> {
            return localVarFp.deviceControllerGetAllByOrganizationAdmin(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetAllByUser(options?: any): AxiosPromise<ApiResponseDeviceList> {
            return localVarFp.deviceControllerGetAllByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all device variables
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerGetVariables(deviceId: number, options?: any): AxiosPromise<ApiResponseVariableList> {
            return localVarFp.deviceControllerGetVariables(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log value
         * @param {string} deviceToken 
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerLog(deviceToken: string, requestBody?: Array<object>, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.deviceControllerLog(deviceToken, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove device
         * @param {number} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerRemove(deviceId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.deviceControllerRemove(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search devices by name
         * @param {string} deviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerSearch(deviceName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deviceControllerSearch(deviceName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update device
         * @param {number} deviceId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceControllerUpdate(deviceId: number, deviceBody?: DeviceBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.deviceControllerUpdate(deviceId, deviceBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @summary Create device
     * @param {DeviceBody} [deviceBody] DeviceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerCreate(deviceBody?: DeviceBody, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerCreate(deviceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable device
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerDisabled(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerDisabled(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get device
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerGet(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerGetAll(options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all organization devices just admin
     * @param {any} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerGetAllByOrganizationAdmin(organizationId: any, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerGetAllByOrganizationAdmin(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerGetAllByUser(options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerGetAllByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all device variables
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerGetVariables(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerGetVariables(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log value
     * @param {string} deviceToken 
     * @param {Array<object>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerLog(deviceToken: string, requestBody?: Array<object>, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerLog(deviceToken, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove device
     * @param {number} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerRemove(deviceId: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerRemove(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search devices by name
     * @param {string} deviceName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerSearch(deviceName: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerSearch(deviceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update device
     * @param {number} deviceId 
     * @param {DeviceBody} [deviceBody] DeviceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceControllerUpdate(deviceId: number, deviceBody?: DeviceBody, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceControllerUpdate(deviceId, deviceBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticate user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerLogin: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with organization user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerLoginAuthenticated: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordBody} [resetPasswordBody] ResetPasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerResetPassword: async (resetPasswordBody?: ResetPasswordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request to reset password
         * @param {SendResetPassword} [sendResetPassword] SendResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerSendEmailToReset: async (sendResetPassword?: SendResetPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/send-reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendResetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticate user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerLogin(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerLogin(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with organization user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerLoginAuthenticated(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerLoginAuthenticated(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordBody} [resetPasswordBody] ResetPasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerResetPassword(resetPasswordBody?: ResetPasswordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerResetPassword(resetPasswordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request to reset password
         * @param {SendResetPassword} [sendResetPassword] SendResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerSendEmailToReset(sendResetPassword?: SendResetPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerSendEmailToReset(sendResetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticate user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerLogin(login?: Login, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.loginControllerLogin(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with organization user
         * @param {Login} [login] Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerLoginAuthenticated(login?: Login, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.loginControllerLoginAuthenticated(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordBody} [resetPasswordBody] ResetPasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerResetPassword(resetPasswordBody?: ResetPasswordBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.loginControllerResetPassword(resetPasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request to reset password
         * @param {SendResetPassword} [sendResetPassword] SendResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerSendEmailToReset(sendResetPassword?: SendResetPassword, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.loginControllerSendEmailToReset(sendResetPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary Authenticate user
     * @param {Login} [login] Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginControllerLogin(login?: Login, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginControllerLogin(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with organization user
     * @param {Login} [login] Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginControllerLoginAuthenticated(login?: Login, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginControllerLoginAuthenticated(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password
     * @param {ResetPasswordBody} [resetPasswordBody] ResetPasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginControllerResetPassword(resetPasswordBody?: ResetPasswordBody, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginControllerResetPassword(resetPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request to reset password
     * @param {SendResetPassword} [sendResetPassword] SendResetPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginControllerSendEmailToReset(sendResetPassword?: SendResetPassword, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginControllerSendEmailToReset(sendResetPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all variables logs
         * @param {string} variables 
         * @param {number} [xLastModified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsControllerGetLogs: async (variables: string, xLastModified?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('logsControllerGetLogs', 'variables', variables)
            const localVarPath = `/logs/{variables}`
                .replace(`{${"variables"}}`, encodeURIComponent(String(variables)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xLastModified !== undefined && xLastModified !== null) {
                localVarHeaderParameter['X-Last-Modified'] = String(JSON.stringify(xLastModified));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all variables logs
         * @param {string} variables 
         * @param {number} [xLastModified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsControllerGetLogs(variables: string, xLastModified?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLogOrLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logsControllerGetLogs(variables, xLastModified, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all variables logs
         * @param {string} variables 
         * @param {number} [xLastModified] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsControllerGetLogs(variables: string, xLastModified?: number, options?: any): AxiosPromise<ApiResponseLogOrLogs> {
            return localVarFp.logsControllerGetLogs(variables, xLastModified, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * 
     * @summary Get all variables logs
     * @param {string} variables 
     * @param {number} [xLastModified] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public logsControllerGetLogs(variables: string, xLastModified?: number, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).logsControllerGetLogs(variables, xLastModified, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create organization
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate: async (organizationBody?: OrganizationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create device for organization
         * @param {number} organizationId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateDevice: async (organizationId: number, deviceBody?: DeviceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationControllerCreateDevice', 'organizationId', organizationId)
            const localVarPath = `/organization/{organizationId}/device/create`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary accept staff - device and variables
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateOrUpdatePermission: async (requestBody?: Array<object>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/staff/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create staff for organization
         * @param {CreateUserOrganizationBody} [createUserOrganizationBody] CreateUserOrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateStaff: async (createUserOrganizationBody?: CreateUserOrganizationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/create-staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserOrganizationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDelete: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationControllerDelete', 'organizationId', organizationId)
            const localVarPath = `/organization/delete/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization by id
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGet: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationControllerGet', 'organizationId', organizationId)
            const localVarPath = `/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerListOrganization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all staff of organization
         * @param {number} organizationId 
         * @param {boolean} withPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerListStaff: async (organizationId: number, withPermission: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationControllerListStaff', 'organizationId', organizationId)
            // verify required parameter 'withPermission' is not null or undefined
            assertParamExists('organizationControllerListStaff', 'withPermission', withPermission)
            const localVarPath = `/organization/list-staff/{organizationId}/{withPermission}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"withPermission"}}`, encodeURIComponent(String(withPermission)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization
         * @param {number} organizationId 
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdate: async (organizationId: number, organizationBody?: OrganizationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationControllerUpdate', 'organizationId', organizationId)
            const localVarPath = `/organization/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create organization
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreate(organizationBody?: OrganizationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreate(organizationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create device for organization
         * @param {number} organizationId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreateDevice(organizationId: number, deviceBody?: DeviceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDevice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreateDevice(organizationId, deviceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary accept staff - device and variables
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreateOrUpdatePermission(requestBody?: Array<object>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreateOrUpdatePermission(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create staff for organization
         * @param {CreateUserOrganizationBody} [createUserOrganizationBody] CreateUserOrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerCreateStaff(createUserOrganizationBody?: CreateUserOrganizationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerCreateStaff(createUserOrganizationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerDelete(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerDelete(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization by id
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerGet(organizationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerListOrganization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOrganizationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerListOrganization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list all staff of organization
         * @param {number} organizationId 
         * @param {boolean} withPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerListStaff(organizationId: number, withPermission: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerListStaff(organizationId, withPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization
         * @param {number} organizationId 
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationControllerUpdate(organizationId: number, organizationBody?: OrganizationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationControllerUpdate(organizationId, organizationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create organization
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreate(organizationBody?: OrganizationBody, options?: any): AxiosPromise<ApiResponseOrganization> {
            return localVarFp.organizationControllerCreate(organizationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create device for organization
         * @param {number} organizationId 
         * @param {DeviceBody} [deviceBody] DeviceBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateDevice(organizationId: number, deviceBody?: DeviceBody, options?: any): AxiosPromise<ApiResponseDevice> {
            return localVarFp.organizationControllerCreateDevice(organizationId, deviceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary accept staff - device and variables
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateOrUpdatePermission(requestBody?: Array<object>, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.organizationControllerCreateOrUpdatePermission(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create staff for organization
         * @param {CreateUserOrganizationBody} [createUserOrganizationBody] CreateUserOrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerCreateStaff(createUserOrganizationBody?: CreateUserOrganizationBody, options?: any): AxiosPromise<ApiResponseUserList> {
            return localVarFp.organizationControllerCreateStaff(createUserOrganizationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerDelete(organizationId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.organizationControllerDelete(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization by id
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerGet(organizationId: number, options?: any): AxiosPromise<ApiResponseOrganization> {
            return localVarFp.organizationControllerGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerListOrganization(options?: any): AxiosPromise<ApiResponseOrganizationList> {
            return localVarFp.organizationControllerListOrganization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all staff of organization
         * @param {number} organizationId 
         * @param {boolean} withPermission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerListStaff(organizationId: number, withPermission: boolean, options?: any): AxiosPromise<ApiResponseUserList> {
            return localVarFp.organizationControllerListStaff(organizationId, withPermission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization
         * @param {number} organizationId 
         * @param {OrganizationBody} [organizationBody] OrganizationBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdate(organizationId: number, organizationBody?: OrganizationBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.organizationControllerUpdate(organizationId, organizationBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Create organization
     * @param {OrganizationBody} [organizationBody] OrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerCreate(organizationBody?: OrganizationBody, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerCreate(organizationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create device for organization
     * @param {number} organizationId 
     * @param {DeviceBody} [deviceBody] DeviceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerCreateDevice(organizationId: number, deviceBody?: DeviceBody, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerCreateDevice(organizationId, deviceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary accept staff - device and variables
     * @param {Array<object>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerCreateOrUpdatePermission(requestBody?: Array<object>, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerCreateOrUpdatePermission(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create staff for organization
     * @param {CreateUserOrganizationBody} [createUserOrganizationBody] CreateUserOrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerCreateStaff(createUserOrganizationBody?: CreateUserOrganizationBody, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerCreateStaff(createUserOrganizationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete organization
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerDelete(organizationId: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerDelete(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization by id
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerGet(organizationId: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerListOrganization(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerListOrganization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all staff of organization
     * @param {number} organizationId 
     * @param {boolean} withPermission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerListStaff(organizationId: number, withPermission: boolean, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerListStaff(organizationId, withPermission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization
     * @param {number} organizationId 
     * @param {OrganizationBody} [organizationBody] OrganizationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationControllerUpdate(organizationId: number, organizationBody?: OrganizationBody, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationControllerUpdate(organizationId, organizationBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemLogApi - axios parameter creator
 * @export
 */
export const SystemLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get system logs by id
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLogControllerGet: async (logId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logId' is not null or undefined
            assertParamExists('systemLogControllerGet', 'logId', logId)
            const localVarPath = `/system-log/{logId}`
                .replace(`{${"logId"}}`, encodeURIComponent(String(logId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all system logs
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLogControllerGetAll: async (search?: string, limit?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system-log/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemLogApi - functional programming interface
 * @export
 */
export const SystemLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get system logs by id
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLogControllerGet(logId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSystemLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLogControllerGet(logId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all system logs
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemLogControllerGetAll(search?: string, limit?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSystemLogList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemLogControllerGetAll(search, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemLogApi - factory interface
 * @export
 */
export const SystemLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get system logs by id
         * @param {number} logId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLogControllerGet(logId: number, options?: any): AxiosPromise<ApiResponseSystemLog> {
            return localVarFp.systemLogControllerGet(logId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all system logs
         * @param {string} [search] 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemLogControllerGetAll(search?: string, limit?: number, page?: number, options?: any): AxiosPromise<ApiResponseSystemLogList> {
            return localVarFp.systemLogControllerGetAll(search, limit, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemLogApi - object-oriented interface
 * @export
 * @class SystemLogApi
 * @extends {BaseAPI}
 */
export class SystemLogApi extends BaseAPI {
    /**
     * 
     * @summary Get system logs by id
     * @param {number} logId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemLogApi
     */
    public systemLogControllerGet(logId: number, options?: AxiosRequestConfig) {
        return SystemLogApiFp(this.configuration).systemLogControllerGet(logId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all system logs
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemLogApi
     */
    public systemLogControllerGetAll(search?: string, limit?: number, page?: number, options?: AxiosRequestConfig) {
        return SystemLogApiFp(this.configuration).systemLogControllerGetAll(search, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate user
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerActivate: async (userId: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerActivate', 'userId', userId)
            const localVarPath = `/user/{userId}/activate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerBan: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerBan', 'userId', userId)
            const localVarPath = `/user/{userId}/ban`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register new user
         * @param {CreateUserBody} [createUserBody] CreateUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate: async (createUserBody?: CreateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerDelete', 'userId', userId)
            const localVarPath = `/user/{userId}/delete`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDisable: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerDisable', 'userId', userId)
            const localVarPath = `/user/{userId}/disable`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEnable: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerEnable', 'userId', userId)
            const localVarPath = `/user/{userId}/enable`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change password
         * @param {number} userId 
         * @param {ChangePasswordBody} [changePasswordBody] ChangePasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetPassword: async (userId: number, changePasswordBody?: ChangePasswordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerResetPassword', 'userId', userId)
            const localVarPath = `/user/{userId}/change-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnban: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUnban', 'userId', userId)
            const localVarPath = `/user/{userId}/unban`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {EditUserBody} [editUserBody] EditUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (userId: number, editUserBody?: EditUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUpdate', 'userId', userId)
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate user
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerActivate(userId: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerActivate(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerBan(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerBan(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register new user
         * @param {CreateUserBody} [createUserBody] CreateUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreate(createUserBody?: CreateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreate(createUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDelete(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDisable(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDisable(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerEnable(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerEnable(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change password
         * @param {number} userId 
         * @param {ChangePasswordBody} [changePasswordBody] ChangePasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerResetPassword(userId: number, changePasswordBody?: ChangePasswordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerResetPassword(userId, changePasswordBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUnban(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUnban(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {EditUserBody} [editUserBody] EditUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(userId: number, editUserBody?: EditUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(userId, editUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate user
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerActivate(userId: number, body?: string, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerActivate(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerBan(userId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerBan(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register new user
         * @param {CreateUserBody} [createUserBody] CreateUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate(createUserBody?: CreateUserBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerCreate(createUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDelete(userId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDisable(userId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerDisable(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEnable(userId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerEnable(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll(options?: any): AxiosPromise<ApiResponseUserList> {
            return localVarFp.userControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change password
         * @param {number} userId 
         * @param {ChangePasswordBody} [changePasswordBody] ChangePasswordBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetPassword(userId: number, changePasswordBody?: ChangePasswordBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerResetPassword(userId, changePasswordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unban user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnban(userId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerUnban(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {EditUserBody} [editUserBody] EditUserBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(userId: number, editUserBody?: EditUserBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.userControllerUpdate(userId, editUserBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Activate user
     * @param {number} userId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerActivate(userId: number, body?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerActivate(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ban user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerBan(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerBan(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register new user
     * @param {CreateUserBody} [createUserBody] CreateUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreate(createUserBody?: CreateUserBody, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreate(createUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDelete(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDisable(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerDisable(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerEnable(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerEnable(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetAll(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change password
     * @param {number} userId 
     * @param {ChangePasswordBody} [changePasswordBody] ChangePasswordBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerResetPassword(userId: number, changePasswordBody?: ChangePasswordBody, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerResetPassword(userId, changePasswordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unban user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUnban(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUnban(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user
     * @param {number} userId 
     * @param {EditUserBody} [editUserBody] EditUserBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdate(userId: number, editUserBody?: EditUserBody, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdate(userId, editUserBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VariableApi - axios parameter creator
 * @export
 */
export const VariableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerDisableLog: async (variables: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('variableControllerDisableLog', 'variables', variables)
            const localVarPath = `/variable/disable/{variables}`
                .replace(`{${"variables"}}`, encodeURIComponent(String(variables)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerEnableLog: async (variables: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('variableControllerEnableLog', 'variables', variables)
            const localVarPath = `/variable/enable/{variables}`
                .replace(`{${"variables"}}`, encodeURIComponent(String(variables)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get variable by id
         * @param {number} variableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerGet: async (variableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('variableControllerGet', 'variableId', variableId)
            const localVarPath = `/variable/{variableId}/get`
                .replace(`{${"variableId"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerGetAllByOrganizationAdmin: async (organizationId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('variableControllerGetAllByOrganizationAdmin', 'organizationId', organizationId)
            const localVarPath = `/variable/all/{organizationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerRemove: async (variables: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('variableControllerRemove', 'variables', variables)
            const localVarPath = `/variable/remove/{variables}`
                .replace(`{${"variables"}}`, encodeURIComponent(String(variables)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VariableApi - functional programming interface
 * @export
 */
export const VariableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VariableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Disable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async variableControllerDisableLog(variables: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.variableControllerDisableLog(variables, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async variableControllerEnableLog(variables: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.variableControllerEnableLog(variables, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get variable by id
         * @param {number} variableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async variableControllerGet(variableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVariable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.variableControllerGet(variableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async variableControllerGetAllByOrganizationAdmin(organizationId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVariableList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.variableControllerGetAllByOrganizationAdmin(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async variableControllerRemove(variables: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.variableControllerRemove(variables, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VariableApi - factory interface
 * @export
 */
export const VariableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VariableApiFp(configuration)
    return {
        /**
         * 
         * @summary Disable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerDisableLog(variables: string, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.variableControllerDisableLog(variables, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerEnableLog(variables: string, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.variableControllerEnableLog(variables, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get variable by id
         * @param {number} variableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerGet(variableId: number, options?: any): AxiosPromise<ApiResponseVariable> {
            return localVarFp.variableControllerGet(variableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all organization devices just admin
         * @param {any} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerGetAllByOrganizationAdmin(organizationId: any, options?: any): AxiosPromise<ApiResponseVariableList> {
            return localVarFp.variableControllerGetAllByOrganizationAdmin(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove variables
         * @param {string} variables 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        variableControllerRemove(variables: string, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.variableControllerRemove(variables, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VariableApi - object-oriented interface
 * @export
 * @class VariableApi
 * @extends {BaseAPI}
 */
export class VariableApi extends BaseAPI {
    /**
     * 
     * @summary Disable variables
     * @param {string} variables 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApi
     */
    public variableControllerDisableLog(variables: string, options?: AxiosRequestConfig) {
        return VariableApiFp(this.configuration).variableControllerDisableLog(variables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable variables
     * @param {string} variables 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApi
     */
    public variableControllerEnableLog(variables: string, options?: AxiosRequestConfig) {
        return VariableApiFp(this.configuration).variableControllerEnableLog(variables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get variable by id
     * @param {number} variableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApi
     */
    public variableControllerGet(variableId: number, options?: AxiosRequestConfig) {
        return VariableApiFp(this.configuration).variableControllerGet(variableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all organization devices just admin
     * @param {any} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApi
     */
    public variableControllerGetAllByOrganizationAdmin(organizationId: any, options?: AxiosRequestConfig) {
        return VariableApiFp(this.configuration).variableControllerGetAllByOrganizationAdmin(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove variables
     * @param {string} variables 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariableApi
     */
    public variableControllerRemove(variables: string, options?: AxiosRequestConfig) {
        return VariableApiFp(this.configuration).variableControllerRemove(variables, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WidgetApi - axios parameter creator
 * @export
 */
export const WidgetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerClone: async (widgetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerClone', 'widgetId', widgetId)
            const localVarPath = `/widget/{widgetId}/clone`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new widget
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerCreate: async (createWidgetBody?: CreateWidgetBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widget/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWidgetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get widget by id
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGet: async (widgetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerGet', 'widgetId', widgetId)
            const localVarPath = `/widget/{widgetId}/get`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widget/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all widget logs
         * @param {number} widgetId 
         * @param {string} interval 
         * @param {number} [xLastModified] 
         * @param {number} [xCurrentTimestamp] 
         * @param {any} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGetLogsByPeriod: async (widgetId: number, interval: string, xLastModified?: number, xCurrentTimestamp?: number, range?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerGetLogsByPeriod', 'widgetId', widgetId)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('widgetControllerGetLogsByPeriod', 'interval', interval)
            const localVarPath = `/widget/{widgetId}/{interval}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)))
                .replace(`{${"interval"}}`, encodeURIComponent(String(interval)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (xLastModified !== undefined && xLastModified !== null) {
                localVarHeaderParameter['X-Last-Modified'] = String(JSON.stringify(xLastModified));
            }

            if (xCurrentTimestamp !== undefined && xCurrentTimestamp !== null) {
                localVarHeaderParameter['X-Current-Timestamp'] = String(JSON.stringify(xCurrentTimestamp));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerRemove: async (widgetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerRemove', 'widgetId', widgetId)
            const localVarPath = `/widget/{widgetId}/remove`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update widget
         * @param {number} widgetId 
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerUpdate: async (widgetId: number, createWidgetBody?: CreateWidgetBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerUpdate', 'widgetId', widgetId)
            const localVarPath = `/widget/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWidgetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update widget custom data
         * @param {number} widgetId 
         * @param {WidgetCustomData} [widgetCustomData] WidgetCustomData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerUpdateCustomData: async (widgetId: number, widgetCustomData?: WidgetCustomData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('widgetControllerUpdateCustomData', 'widgetId', widgetId)
            const localVarPath = `/widget/{widgetId}/custom`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication userToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(widgetCustomData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetApi - functional programming interface
 * @export
 */
export const WidgetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerClone(widgetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCloneWidget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerClone(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new widget
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerCreate(createWidgetBody?: CreateWidgetBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseNewWidget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerCreate(createWidgetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get widget by id
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerGet(widgetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseWidget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerGet(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseWidgetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all widget logs
         * @param {number} widgetId 
         * @param {string} interval 
         * @param {number} [xLastModified] 
         * @param {number} [xCurrentTimestamp] 
         * @param {any} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerGetLogsByPeriod(widgetId: number, interval: string, xLastModified?: number, xCurrentTimestamp?: number, range?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLogsPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerGetLogsByPeriod(widgetId, interval, xLastModified, xCurrentTimestamp, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerRemove(widgetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerRemove(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update widget
         * @param {number} widgetId 
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerUpdate(widgetId: number, createWidgetBody?: CreateWidgetBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerUpdate(widgetId, createWidgetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update widget custom data
         * @param {number} widgetId 
         * @param {WidgetCustomData} [widgetCustomData] WidgetCustomData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetControllerUpdateCustomData(widgetId: number, widgetCustomData?: WidgetCustomData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetControllerUpdateCustomData(widgetId, widgetCustomData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetApi - factory interface
 * @export
 */
export const WidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerClone(widgetId: number, options?: any): AxiosPromise<ApiResponseCloneWidget> {
            return localVarFp.widgetControllerClone(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new widget
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerCreate(createWidgetBody?: CreateWidgetBody, options?: any): AxiosPromise<ApiResponseNewWidget> {
            return localVarFp.widgetControllerCreate(createWidgetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get widget by id
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGet(widgetId: number, options?: any): AxiosPromise<ApiResponseWidget> {
            return localVarFp.widgetControllerGet(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGetAll(options?: any): AxiosPromise<ApiResponseWidgetList> {
            return localVarFp.widgetControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all widget logs
         * @param {number} widgetId 
         * @param {string} interval 
         * @param {number} [xLastModified] 
         * @param {number} [xCurrentTimestamp] 
         * @param {any} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerGetLogsByPeriod(widgetId: number, interval: string, xLastModified?: number, xCurrentTimestamp?: number, range?: any, options?: any): AxiosPromise<ApiResponseLogsPeriod> {
            return localVarFp.widgetControllerGetLogsByPeriod(widgetId, interval, xLastModified, xCurrentTimestamp, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove widget
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerRemove(widgetId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.widgetControllerRemove(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update widget
         * @param {number} widgetId 
         * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerUpdate(widgetId: number, createWidgetBody?: CreateWidgetBody, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.widgetControllerUpdate(widgetId, createWidgetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update widget custom data
         * @param {number} widgetId 
         * @param {WidgetCustomData} [widgetCustomData] WidgetCustomData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetControllerUpdateCustomData(widgetId: number, widgetCustomData?: WidgetCustomData, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.widgetControllerUpdateCustomData(widgetId, widgetCustomData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WidgetApi - object-oriented interface
 * @export
 * @class WidgetApi
 * @extends {BaseAPI}
 */
export class WidgetApi extends BaseAPI {
    /**
     * 
     * @summary Clone widget
     * @param {number} widgetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerClone(widgetId: number, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerClone(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new widget
     * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerCreate(createWidgetBody?: CreateWidgetBody, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerCreate(createWidgetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get widget by id
     * @param {number} widgetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerGet(widgetId: number, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerGet(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerGetAll(options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all widget logs
     * @param {number} widgetId 
     * @param {string} interval 
     * @param {number} [xLastModified] 
     * @param {number} [xCurrentTimestamp] 
     * @param {any} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerGetLogsByPeriod(widgetId: number, interval: string, xLastModified?: number, xCurrentTimestamp?: number, range?: any, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerGetLogsByPeriod(widgetId, interval, xLastModified, xCurrentTimestamp, range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove widget
     * @param {number} widgetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerRemove(widgetId: number, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerRemove(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update widget
     * @param {number} widgetId 
     * @param {CreateWidgetBody} [createWidgetBody] CreateWidgetBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerUpdate(widgetId: number, createWidgetBody?: CreateWidgetBody, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerUpdate(widgetId, createWidgetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update widget custom data
     * @param {number} widgetId 
     * @param {WidgetCustomData} [widgetCustomData] WidgetCustomData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetControllerUpdateCustomData(widgetId: number, widgetCustomData?: WidgetCustomData, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetControllerUpdateCustomData(widgetId, widgetCustomData, options).then((request) => request(this.axios, this.basePath));
    }
}


