import parseJwt from '@helpers/parseJwt'

export default async function getUserAuthentication(token) {
  const localStorageToken = localStorage.getItem('@token')

  let tokenJson

  if (localStorageToken) tokenJson = await parseJwt(localStorageToken)
  else tokenJson = await parseJwt(token)
  return tokenJson
}
