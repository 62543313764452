import React, { useEffect, useState } from 'react'
import ContentEditableWithRef from '../ContentEditableWithRef'
import { Container, Section } from './styles'

const ReactAutosuggest = ({
  data,
  onChange,
  defaultValue,
}: {
  data: string[]
  onChange: any
  defaultValue?: any
}) => {
  // const [values, setValues] = useState<any>([])
  const [textFieldInput, setTextFieldInput] = useState<string>()
  const [suggestionSelected, setSuggestionSelected] = useState<any>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [injectedChip, setInjectedChip] = useState<any>('')

  const handleAddChip = chip => {
    setSuggestionSelected([])
    const newTextValue = `${textFieldInput?.slice(
      0,
      -1,
    )}<span class="chip-variavel">${chip}<span>&#x2715;</span></span>&nbsp;`
    console.log('O que armazenou', chip, newTextValue)

    setTextFieldInput(newTextValue)
    setInjectedChip(newTextValue)
  }

  const handleDeleteChip = newTextInput => {
    setTextFieldInput(newTextInput)
    setInjectedChip(newTextInput)
  }

  function renderSuggestion() {
    return (
      <div className="suggestionContainer">
        {suggestionSelected.map(item => (
          <div onClick={() => handleAddChip(item)}>{item}</div>
        ))}
      </div>
    )
  }

  function getSuggestions(value) {
    const texto: any = Array.from(value)
    console.log('PREPER GET SUGGESTION', texto)

    const newValue = texto.pop() || ''

    const inputValue = newValue.trim().toLowerCase()
    console.log(
      'PREPER GET SUGGESTION',
      newValue,
      inputValue,
      inputValue.length,
    )

    console.log('PREPER GET SUGGESTION', data)

    const result =
      inputValue.length === 0
        ? data
        : (data.filter &&
            data.filter(variable =>
              variable.toLowerCase().includes(inputValue),
            )) ||
          data

    setSuggestionSelected(result)
  }
  function regexCustomInput(textParam, withKey = false) {
    const textFormatted = String(textParam)
      .replace(/&nbsp;/gi, '')
      .replace(/<span>([ ]+)<\/span>/gi, '')
      .replace(/<span>(&#x2715;|✕)<\/span>/gi, '')
      .replace(/<span class="chip-variavel">/gi, withKey ? '{{' : '')
      .replace(/<\/span>/gi, withKey ? '}}' : '')
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/gi, '')
      .replace(/ /gi, '')

    const txt = document.createElement('textarea')
    txt.innerHTML = textFormatted
    return txt.textContent
  }

  function handletextFieldInputChange(event) {
    setTextFieldInput(event)
    getSuggestions(regexCustomInput(event))
  }

  useEffect(() => {
    onChange(regexCustomInput(textFieldInput, true))
  }, [textFieldInput])

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0 && !textFieldInput) {
      setTextFieldInput(
        defaultValue
          .replace(/{{/gi, '<span class="chip-variavel">')
          .replace(/}}/gi, '<span>&#x2715;</span></span>'),
      )
    } else if (!textFieldInput) {
      setTextFieldInput('')
    }
  }, [])

  const renderInput = () => {
    return (
      <Section>
        {typeof textFieldInput === 'string' && (
          <ContentEditableWithRef
            key={injectedChip}
            handleDeleteChip={handleDeleteChip}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 300)}
            onChange={e => {
              const event: any = e.target
              console.log('Teve Mudandca', event.innerHTML)
              handletextFieldInputChange(event.innerHTML)
            }}
            value={textFieldInput}
          />
        )}
      </Section>
    )
  }

  return (
    <Container>
      {renderInput()}
      {showSuggestions && suggestionSelected.length > 0 && renderSuggestion()}
    </Container>
  )
}

export const GroupingFormulas = ReactAutosuggest
