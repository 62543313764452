import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { getOrganizationApi, ApiResponseOrganization } from '@services/api'

function useOrganization(
  organizationId?: number,
  config?: SWRConfigurationProps,
) {
  const fetch = config?.canFetch || (organizationId && organizationId > 0)

  const { data, error, mutate } = (
    config?.immutable === false ? useSWR : useSWRImmutable
  )<ApiResponseOrganization>(
    fetch ? ['organizationControllerGet', organizationId] : null,
    () =>
      getOrganizationApi()
        .organizationControllerGet(organizationId!)
        .then(response => response.data),
    config as SWRConfiguration,
  )

  return {
    organization: data?.data,
    response: data,
    isError: error,
    isLoading: fetch ? !error && !data : false,
    mutate,
  }
}

export default useOrganization
