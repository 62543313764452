import React from 'react'
import { useHistory } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import SidebarItem from './item'

const Back: React.FC = () => {
  const history = useHistory()
  return (
    <SidebarItem
      title="Voltar para Organizações"
      icon={BsArrowLeft}
      onClick={() => history.push('/organization')}
    />
  )
}

export default Back
