import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SideBar from '@sidebar/index'
import { useAuth, useRouteState } from '@components/store/context'
import { Organization, UserTypeEnum } from '@services/openapi'
import Navbar from '@components/navbar/organization'
import useOrganizations from '@hooks/useOrganizations'
import Main from '@layout/Main'
import InputSearch from '@components/Input/search'
import { MainContent, Div } from './styles'
import OrganizationItem from './organizationItem'
import OrganizationMembers from './show/members'

export default function OrganizationPage() {
  const [user] = useAuth()
  const history = useHistory()

  const [inputSearch, setInputSearch] = useState<any>('')
  const [listOrganizationFilter, setListOrganizationFilter] = useState<
    Organization[]
  >([])

  const { data: listOrganization, mutate } = useOrganizations({
    limit: 100,
  })

  useEffect(() => {
    if (inputSearch && inputSearch != '') {
      setListOrganizationFilter(
        listOrganization?.filter(item => {
          if (
            String(item.name)
              .toLowerCase()
              .includes(String(inputSearch).toLowerCase())
          )
            return true
          return false
        }) ?? [],
      )
    } else if (listOrganization.length > 0) {
      setListOrganizationFilter(listOrganization)
    }
  }, [inputSearch])

  useEffect(() => {
    if (listOrganization.length > 0) {
      setListOrganizationFilter(listOrganization)
    }
  }, [listOrganization])

  const [, setRouteState] = useRouteState()

  useEffect(() => {
    if (history.location.state?.refresh) {
      mutate()
    }
  }, [history.location.state])

  useEffect(() => {
    setRouteState({
      key: 'organization',
    })
  }, [])

  return (
    <>
      {user && user.type == UserTypeEnum.Admin ? (
        <>
          <SideBar />
          <Navbar onAddOrganization={() => mutate()} />
          <Main>
            <Div>
              <InputSearch
                placeholder="Pesquisar por nome…"
                onSearch={s => setInputSearch(s)}
              />
            </Div>
            <MainContent>
              {listOrganizationFilter.map(item => (
                <OrganizationItem key={item.name} data={item} />
              ))}
            </MainContent>
          </Main>
        </>
      ) : (
        <OrganizationMembers />
      )}
    </>
  )
}
