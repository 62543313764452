import { Variable } from '@services/openapi'

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const convertKeyLikeValue = (
  formula: string,
  variaveis: Variable[],
  value: any,
) => {
  let conversion = ''
  Array.from(variaveis).forEach(variavel => {
    conversion = formula.replace(
      new RegExp(escapeRegExp(`{{${variavel.tag}}}`), 'g'),
      value,
    )
  })

  return conversion
}

export default convertKeyLikeValue
