import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTheme } from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import { ButtonGreen, ButtonWhite } from '../../../assets/styles/stylesButton'
import { DivInput, Div } from './styles'

export default function Index({
  open,
  setOpen,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}: {
  open
  setOpen
  startDate?
  endDate?
  setEndDate
  setStartDate
}) {
  const theme: any = useTheme()
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.backgroundPrimary,
      borderColor: theme.backgroundPrimaryContrast,
      boxShadow: `2px 2px 9px ${
        theme.themeName != 'whiteThem' ? '#000' : theme.title
      }`,
      // zIndex: '200 !important',
    },
    overlay: {
      backgroundColor: theme.modalOverlay,
    },
  }
  const [startDateModal, setStartDateModal] = useState(startDate)
  const [endDateModal, setEndDateModal] = useState(endDate)
  const { addToast } = useToasts()

  function closeModal() {
    setOpen(false)
  }

  async function onSubmit(e) {
    e.preventDefault()
    if (startDateModal > endDateModal) {
      addToast('Data inicial não pode ser maior que a final', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      setStartDate(startDateModal)
      setEndDate(endDateModal)
      closeModal()
    }
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <form onSubmit={e => onSubmit(e)}>
        <DivInput>
          <label htmlFor="fname">Data Inicio: </label>
          <input
            value={startDateModal}
            required
            type="date"
            onChange={e => setStartDateModal(e.target.value)}
          />
        </DivInput>
        <DivInput>
          <label htmlFor="fname">Data Fim: </label>
          <input
            value={endDateModal}
            required
            type="date"
            max={moment().format('YYYY-MM-DD')}
            onChange={e => setEndDateModal(e.target.value)}
          />
        </DivInput>
        <Div>
          <ButtonGreen>Consultar</ButtonGreen>
          <ButtonWhite onClick={() => closeModal()}>Cancelar</ButtonWhite>
        </Div>
      </form>
    </Modal>
  )
}
