import React, { useEffect, useState } from 'react'
import { BsColumnsGap, BsBroadcast, BsGear, BsList } from 'react-icons/bs'

import { AiOutlineMinus } from 'react-icons/ai'
import { useAuth, useRouteState } from '@components/store/context'
import getUserAuthentication from '@utils/auth'
import { Organization, UserTypeEnum } from '@services/openapi'
import parseJwt from '@helpers/parseJwt'
import { Tooltip } from '@material-ui/core'
import AvatarImg from '../../assets/avatar/avatar.png'
import {
  SideBar,
  Menu,
  MenuLink,
  MenuLinkAvatar,
  IconsDivisor,
  Avatar,
  Hamburger,
  Navbar,
} from './style'
import SidebarItem from './item'
import DefaultMenu from './menu/default'

interface IUser {
  userId: string
  userName: string
  email: string
  avatar: string
  remember: string
  organization: Organization
  type: UserTypeEnum
}

export default function Index({
  overrideMenu,
}: {
  overrideMenu?: React.ReactElement
}) {
  const [isVisible, setIsVisible] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [routeState] = useRouteState()
  const localStorageToken = localStorage.getItem('@token')

  const [token] = useAuth()

  const [user, setUser] = useState<IUser>()
  async function getUserAuthenticated() {
    setUser(await getUserAuthentication(token))
  }
  useEffect(() => {
    getUserAuthenticated()
  }, [])

  useEffect(() => {
    if (localStorageToken) {
      const avatarUser = parseJwt(localStorageToken).avatar
      if (avatarUser) setAvatar(avatarUser)
      else setAvatar(null)
    }
  }, [])

  return (
    <>
      <SideBar>
        <Hamburger onClick={() => setIsVisible(!isVisible)}>
          <BsList />
        </Hamburger>
        {overrideMenu || (
          <Menu>
            {routeState?.menu || <DefaultMenu />}
            <div>
              <MenuLinkAvatar>
                <Tooltip title={user?.userName || 'Usuário'}>
                  <Avatar src={avatar ?? AvatarImg} />
                </Tooltip>
              </MenuLinkAvatar>
            </div>
          </Menu>
        )}
      </SideBar>
      {isVisible ? (
        <Navbar>
          <SidebarItem
            to="/dashboard"
            title="Dashboards"
            key="dashboard"
            icon={BsColumnsGap}
          />
          <SidebarItem
            to="/devices"
            title="Dispositivos"
            key="devices"
            icon={BsBroadcast}
          />
          <MenuLink>
            <IconsDivisor>
              <AiOutlineMinus />
            </IconsDivisor>
          </MenuLink>
          <SidebarItem
            to="/configuration"
            title="Configuração"
            key="configuration"
            icon={BsGear}
          />
        </Navbar>
      ) : null}
    </>
  )
}
