/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react'
import Input from '@components/Input'
import SubmitButton from '@components/submitbutton'
import ModalComponent from '@components/modal'
import { User, UserTypeEnum } from '@services/openapi'
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs'
import { FormModal, TypeUser, Select, Title } from './styles'

interface ICreateUserOrganization {
  open: boolean
  setOpen
  onAddMember
  user?: User
}

const CreateUserOrganization: React.FC<ICreateUserOrganization> = ({
  setOpen,
  open,
  onAddMember,
  user,
}) => {
  const [name, setName] = useState<any>((user && user.name) || '')
  const [email, setEmail] = useState<any>((user && user.email) || '')
  const [password, setPassword] = useState<any>('')
  const [showPassword, setshowPassword] = useState<boolean>(false)
  const [typeUser, setTypeUser] = useState<any>((user && user.type) || false)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const [dropError, setDropError] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  function selectTypeUser(typeParam) {
    setDropDownVisible(false)
    setTypeUser(typeParam)
  }

  return (
    <ModalComponent
      open={open}
      setOpen={() => setOpen(false)}
      title={user ? 'Atualizar membro' : 'Novo membro'}
    >
      <FormModal
        onSubmit={e => {
          e.preventDefault()
          const formData: any = e

          console.log('submit form')
          console.log(formData.target.typeUser.value)
          console.log(
            'IS true',
            formData.target.typeUser.value ?? false,
            formData.target.typeUser.value != 'false',
          )
          // if (formData.target.typeUser.value != false) console.log('ENtao foi')

          // setDropError(true)

          // if (true) return

          if (formData.target.typeUser.value != 'false') onAddMember(e)
          else {
            setDropError(true)
          }
        }}
      >
        <div>
          <Input
            required
            name="name"
            label="Nome"
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder="Digite um nome..."
          />
          <Input
            required
            name="email"
            label="E-mail"
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder="Digite um e-mail..."
          />
          <Title>Tipo de usuário</Title>
          <TypeUser
            dropError={dropError}
            onClick={e => {
              e.stopPropagation()
              setDropDownVisible(!dropDownVisible)
              if (dropError) setDropError(false)
            }}
          >
            <section>
              {typeUser
                ? typeUser === UserTypeEnum.OrganizationAdmin
                  ? 'Admin. da Organização'
                  : 'Membro'
                : 'Selecione o tipo de membro'}
              <input type="hidden" name="typeUser" value={typeUser} />
              {!dropDownVisible ? (
                <BsFillCaretDownFill />
              ) : (
                <BsFillCaretUpFill />
              )}
            </section>
            <Select ref={ref} active={dropDownVisible}>
              <span
                onClick={() => selectTypeUser(UserTypeEnum.OrganizationAdmin)}
              >
                Admin. da Organização
              </span>
              <span onClick={() => selectTypeUser(UserTypeEnum.Staff)}>
                Membro
              </span>
            </Select>
          </TypeUser>
          {!user && (
            <>
              <Input
                required
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Senha"
                onChange={e => setPassword(e.target.value)}
                value={password}
                placeholder="Digite um nome..."
              />
              <Input
                type="checkbox"
                label="Exibir senha"
                onChange={e => {
                  setshowPassword(e.target.checked)
                }}
                placeholder="Digite um nome..."
              />
            </>
          )}
        </div>

        <SubmitButton
          label={user ? 'Atualizar' : 'Criar'}
          onClick={() => {
            setOpen(true)
            console.log('Open Modal Create')
          }}
        />
      </FormModal>
    </ModalComponent>
  )
}

export default CreateUserOrganization
