/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef } from 'react'
import { ContentEditable } from './styles'

interface IContentEditableWithRef {
  onChange: any
  onFocus: any
  onBlur: any
  value: any
  handleDeleteChip: any
}
const ContentEditableWithRef: React.FC<IContentEditableWithRef> = ({
  onChange,
  onFocus,
  onBlur,
  value,
  handleDeleteChip,
}) => {
  const valueRef = useRef(value)

  const updateListeners = () => {
    Array.from(
      document.querySelectorAll(
        '.contentEditableWithRef .chip-variavel > span',
      ),
    ).forEach((spanClose: any) => {
      spanClose.onclick = e => {
        e.stopPropagation()
        const tag = e.target.parentNode
        const contentEditable = tag.parentNode
        contentEditable.removeChild(tag)
        handleDeleteChip(contentEditable.innerHTML)
      }
    })
  }

  useEffect(() => {
    updateListeners()
  }, [])

  // function getCaretPosition(node) {
  //   const selections: any = window.getSelection()
  //   if (selections) {
  //     const range = selections.getRangeAt(0)
  //     const tmp = document.createElement('div')
  //     const preCaretRange: any = range.cloneRange()
  //     let caretPosition: any = ''

  //     preCaretRange.selectNodeContents(node)
  //     preCaretRange.setEnd(range.endContainer, range.endOffset)
  //     tmp.appendChild(preCaretRange.cloneContents())
  //     caretPosition = tmp.innerHTML.length
  //     return caretPosition
  //   }
  //   return null
  // }

  // function getHTMLCaretPosition(element) {
  //   const textPosition = getCaretPosition(element)
  //   const htmlContent = element.innerHTML
  //   const htmlBeginChars = ['&', '<']
  //   const htmlEndChars = [';', '>']
  //   let htmlIndex = 0
  //   let textIndex = 0
  //   let insideHtml = false

  //   if (textPosition == 0) {
  //     return 0
  //   }

  //   while (textIndex < textPosition) {
  //     htmlIndex += htmlIndex
  //     while (htmlBeginChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
  //       insideHtml = true
  //       while (insideHtml) {
  //         if (htmlEndChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
  //           if (htmlContent.charAt(htmlIndex) == ';') {
  //             htmlIndex -= htmlIndex // entity is char itself
  //           }
  //           insideHtml = false
  //         }
  //         htmlIndex += htmlIndex
  //       }
  //     }
  //     textIndex += textIndex
  //   }
  //   console.log('htmlIndex', htmlIndex)
  //   console.log('textPosition', textPosition)
  //   // in htmlIndex is caret position inside html
  //   return htmlIndex
  // }
  // function getCaretPosition(editableDiv) {
  //   const caretPos = 0,
  //     sel,
  //     range
  //   if (window.getSelection) {
  //     sel = window.getSelection()
  //     if (sel.rangeCount) {
  //       range = sel.getRangeAt(0)
  //       if (range.commonAncestorContainer.parentNode == editableDiv) {
  //         caretPos = range.endOffset
  //       }
  //     }
  //   } else if (document.selection && document.selection.createRange) {
  //     range = document.selection.createRange()
  //     if (range.parentElement() == editableDiv) {
  //       const tempEl = document.createElement('span')
  //       editableDiv.insertBefore(tempEl, editableDiv.firstChild)
  //       const tempRange = range.duplicate()
  //       tempRange.moveToElementText(tempEl)
  //       tempRange.setEndPoint('EndToEnd', range)
  //       caretPos = tempRange.text.length
  //     }
  //   }
  //   return caretPos
  // }

  // function getCurrentWord(el, position) {
  //   const content = el.textContent
  //   position = content[position] === ' ' ? position - 1 : position

  //   let startPosition = content.lastIndexOf(' ', position)
  //   startPosition = startPosition === content.length ? 0 : startPosition
  //   let endPosition = content.indexOf(' ', position)
  //   endPosition = endPosition === -1 ? content.length : endPosition

  //   return content.substring(startPosition + 1, endPosition)
  // }

  // function getWordAtPosition(position, element) {
  //   var total_text = element.innerHTML
  //   var current_word = ''
  //   var i = 0
  //   var word_found = false
  //   while (i < total_text.length) {
  //     if (total_text[i] != ' ') current_word += total_text[i]
  //     else if (word_found) return current_word
  //     else current_word = ''
  //     if (i == position) word_found = true
  //     i++
  //   }
  //   return current_word
  // }

  // function getCaretCharacterOffsetWithin(element) {
  //   let caretOffset = 0
  //   let doc = element.ownerDocument || element.document
  //   let win = doc.defaultView || doc.parentWindow
  //   let sel
  //   if (typeof win.getSelection != 'undefined') {
  //     sel = win.getSelection()
  //     if (sel.rangeCount > 0) {
  //       var range = win.getSelection().getRangeAt(0)
  //       var preCaretRange = range.cloneRange()
  //       preCaretRange.selectNodeContents(element)
  //       preCaretRange.setEnd(range.endContainer, range.endOffset)
  //       caretOffset = preCaretRange.toString().length
  //     }
  //   } else if ((sel = doc.selection) && sel.type != 'Control') {
  //     var textRange = sel.createRange()
  //     var preCaretTextRange = doc.body.createTextRange()
  //     preCaretTextRange.moveToElementText(element)
  //     preCaretTextRange.setEndPoint('EndToEnd', textRange)
  //     caretOffset = preCaretTextRange.text.length
  //   }
  //   console.log('caretOffset', caretOffset)
  //   const word = getWordAtPosition(caretOffset, element)
  //   console.log('word', word)
  //   return caretOffset
  // }

  // useEffect(() => {
  //   const elm: any = document.querySelector('.contentEditableWithRef')
  //   elm.addEventListener('keydown', () => {
  //     try {
  //       const caretPosition = getHTMLCaretPosition(elm)
  //       // const word = getCaretCharacterOffsetWithin(this)

  //       console.log('Teste cursor and postion', caretPosition, 'word')
  //     } catch (e) {
  //       console.log('EOORrrpr: ', e)
  //     }
  //   })
  // }, [])

  return (
    <ContentEditable
      onInput={e => {
        onChange(e)
        updateListeners()
      }}
      onFocus={e => onFocus && onFocus(e)}
      onBlur={e => onBlur && onBlur(e)}
      placeholder="expressão for verdadeira"
      dangerouslySetInnerHTML={{
        __html: valueRef.current,
      }}
      className="contentEditableWithRef"
    />
  )
}

export default ContentEditableWithRef
