import moment from 'moment'

export function currentDate(dateParams = '') {
  const date = new Date(dateParams)
  const nowUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )

  return new Date(nowUtc).toString()
}

export function convertDateInString(date) {
  if (date) {
    const newDate = moment(currentDate(date))

    const newDateFormatted = newDate.format('DD-MM-YYYY').toString()

    const now = moment().format('DD-MM-YYYY')

    if (now == newDateFormatted) {
      return `Hoje ${moment(date).format('H:mm').toString()}`
    }
    return `${moment(date).format('DD-MM H:mm').toString()}`
  }
  return 'Sem registro'
}
