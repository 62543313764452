import styled from 'styled-components'

export const OrganizationItemStyles = styled.div`
  display: flex;
  max-width: 100%;
  box-shadow: 0px 1px 10px ${({ theme }) => theme.backgroundSecondary};
  background: ${props => props.theme.backgroundPrimary};
  justify-content: space-between;
  margin: 10px 0px;
  border-radius: 4px;
  align-items: center;
  height: 70px;
  padding: 0px 13px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundTerciary};
  }
  > div {
    width: calc((100% - 32px) / 4);
    display: flex;
    align-items: center;
    color: ${props => props.theme.title};
    margin: 0px 8px;

    > div {
      display: flex;
      flex-wrap: wrap;
      color: ${({ theme }) => theme.greenHighlight};
      text-decoration: none;

      svg {
        width: 20px;
        font-size: 1.5rem;
        margin-right: 10px;
      }
    }
    img {
      width: 100px;
      max-height: 50px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 10px;
    }
    &:first-child {
      font-weight: 500;
      width: -webkit-fill-available;
      font-size: 1.2rem;
      margin: 0px;
    }
    /* &:last-child {
      width: 16px;
      margin: 0px;
    } */
  }

  @media (max-width: 768px) {
    > div {
      font-size: 0.8rem;

      img {
        width: 70px;
        max-height: 50px;
        background: red;
      }

      &:first-child {
        font-size: 0.9rem;
      }
    }
  }
`

export const MainContent = styled.div`
  margin-top: 20px;

  .listOrganization {
    width: 100%;
  }
`

export const Div = styled.div`
  padding-top: 1%;
  padding-right: 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
