import React, { useEffect, useState } from 'react'
import SideBar from '@sidebar/index'
import Navbar from '@navbar/device'
import DeviceList from '@components/pages/devices/index'
import { Device } from '@services/openapi'
import { useRouteState } from '@store/context'
import Main from '@layout/Main'

export default function Index() {
  const [newDevice, addDevice] = useState<Device>()

  const [, setRouteState] = useRouteState()

  useEffect(() => {
    setRouteState({
      key: 'devices',
    })
  }, [])

  return (
    <>
      <SideBar />
      <Navbar onAddDevice={addDevice} />
      <Main>
        <DeviceList addDevice={newDevice} />
      </Main>
    </>
  )
}
