import React, { memo } from 'react'
import { Dashboard, Widget, WidgetTypeEnum } from '@services/openapi'
import Common from './common'
import Line from './line'
import Bar from './bar'
import Speedmeter from './speedometer'
import Cylinder from './cylinder'
import { NoPermission } from '../pages/dashboard/grid/styles'

function Index(props: {
  widget: Widget
  dashboard?: Dashboard
  edit: boolean
  refresh?: number
  realtimeDelay?: number
  onClone?: (widget: Widget) => void
  setWidget?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
  style?: any
}) {
  const { widget, dashboard, realtimeDelay } = props

  return (
    <>
      {!widget.isAllowed && (
        <NoPermission>
          <h2>Sem Permissão</h2>
        </NoPermission>
      )}
      {widget.type == WidgetTypeEnum.Text && (
        <Common
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          type={WidgetTypeEnum.Text}
          {...widget.customData}
          icon={widget.customData?.icon?.icon}
          {...props}
        />
      )}
      {widget.type == WidgetTypeEnum.Number && (
        <Common
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          type={WidgetTypeEnum.Number}
          {...widget.customData}
          icon={widget.customData?.icon?.icon}
          {...props}
        />
      )}
      {widget.type == WidgetTypeEnum.Meter && (
        <Speedmeter
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          units={widget.customData?.units}
          range={
            widget.customData?.range || { minimumValue: 0, maximumValue: 100 }
          }
          {...props}
        />
      )}
      {widget.type == WidgetTypeEnum.Cylinder && (
        <Cylinder
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          units={widget.customData?.units}
          range={
            widget.customData?.range || { minimumValue: 0, maximumValue: 100 }
          }
          {...props}
        />
      )}
      {widget.type == WidgetTypeEnum.BarChart && (
        <Bar
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          {...props}
        />
      )}
      {widget.type == WidgetTypeEnum.LineChart && (
        <Line
          time={(dashboard?.time || 0) + (realtimeDelay || 10)}
          title={widget.title}
          {...props}
        />
      )}
    </>
  )
}

export default memo(Index)
