import styled from 'styled-components'

export const DivInput = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  label {
    color: #2e2e3a;
  }
  input {
    background: ${props => props.theme.backgroundPrimary};
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 6px;
    padding: 6px;
    border: none;
    color: #2e2e3a;
  }
`

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  button {
    width: 100px;
  }
`

export const Button = styled.button`
  color: ${props => props.theme.backgroundPrimary} !important;
  text-transform: uppercase;
  text-decoration: none;
  background: ${props => props.theme.greenHighlight};
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  &:hover {
    background: #434343;
  }
`
