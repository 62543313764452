import React from 'react'
import { BsBuilding } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'

import { OrganizationItemStyles } from './styles'

const OrganizationItem = ({ data }) => {
  const history = useHistory()

  return (
    <OrganizationItemStyles
      onClick={() => history.push(`/organization/${data.id}/members`)}
    >
      <div>
        <div>
          {data.logo ? <img src={data.logo} alt="logo" /> : <BsBuilding />}
          {data.name}
        </div>
      </div>
      <div>Funcionários: {data.staffsCount || 0}</div>
      <div>Dispositivos: {data.deviceCount || 0}</div>
      <div>Logs: {data.logsCount || 0}</div>
    </OrganizationItemStyles>
  )
}

export default OrganizationItem
