import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IntroDiv = styled.div`
  padding: 2% 5% 2% 5%;
  background-color: ${props => props.theme.backgroundTerciary};
  width: 40%;

  p {
    font-size: 1.125rem;

    font-weight: 300;
    color: ${props => props.theme.title};
  }
  h1 {
    font-size: 1.6rem;
    color: ${props => props.theme.title};
  }

  @media (max-width: 800px) {
    display: none;
  }
`

export const ImgLogo = styled.img`
  max-width: 180px;
  margin-bottom: 5%;
`

export const ImgGraphic = styled.img`
  margin-top: 10%;
  width: 100%;
`

export const FormCard = styled.div`
  padding: 0 5% 0 5%;
  display: table;
  background-color: ${props => props.theme.backgroundPrimary};
  width: 70%;

  img {
    display: none;
  }

  @media (max-width: 900px) {
    padding: 5%;
  }

  @media (max-width: 800px) {
    width: 100%;
    img {
      width: 100px;
      display: block;
    }
  }
`

export const TableDiv = styled.form`
  display: table-cell;
  vertical-align: middle;
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`

export const FirstText = styled.span`
  font-size: 2.3125rem;
  font-weight: 700;
  color: ${props => props.theme.greenHighlight};
  margin-bottom: 2%;
`

export const Form = styled(Div)`
  margin-top: 5%;
`

export const InputDiv = styled.div`
  ${props => props.theme.modalHover};
  background-color: ${props => props.theme.backgroundSecondary};
  border-radius: 2px;
  padding: 10px;
  color: #adb5bd;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  input {
    margin-left: 5px;
    background-color: ${props => props.theme.backgroundSecondary};
    border: none;
    outline: none;
    color: ${props => props.theme.title};
    width: 100%;
  }
`

export const InputDivPass = styled(InputDiv)`
  margin-bottom: 0;
  img {
    width: 3%;
    display: block;
  }
`

export const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${props => props.theme.title};
  margin-bottom: 2%;
`

export const Span = styled.label`
  font-weight: 300;
  font-size: 0.625rem;
  color: ${props => props.theme.title};
  margin-bottom: 5%;
`

export const Button = styled.button`
  color: ${props => props.theme.backgroundPrimary}!important;
  text-transform: uppercase;
  text-decoration: none;
  background: ${props => props.theme.greenHighlight};
  padding: 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${props => props.theme.backgroundSecondary};
    cursor: pointer;
  }
  span {
    margin-right: 2%;
  }
`

export const ButtonLoanding = styled.button`
  color: ${props => props.theme.backgroundPrimary}; !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #4ca27d;
  padding: 10px;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 2%;
  }
`

export const MessageError = styled.div`
  padding: 10px;
  background-color: #f4655b;
  color: ${props => props.theme.backgroundPrimary};
  border-radius: 10px;
  margin-bottom: 2%;
`

export const MessageSuccess = styled.div`
  padding: 10px;
  background-color: ${props => props.theme.greenHighlight};
  color: ${props => props.theme.backgroundPrimary};
  border-radius: 10px;
  margin-bottom: 2%;
`

export const LinkLogin = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
  a {
    font-size: 0.875rem;
    color: ${props => props.theme.greenHighlight};
    text-decoration: none;
    &:hover {
      color: #01482a;
    }
  }
`
