import React from 'react'
import { useHistory } from 'react-router-dom'
import { BsBoxArrowInLeft, BsGear, BsMoon, BsSun } from 'react-icons/bs'
import { useToasts } from 'react-toast-notifications'
import { useRouteState, useTheme } from '@components/store/context'
import SidebarItem from '../item'

const ConfigMenu: React.FC = () => {
  const [theme, setTheme] = useTheme()
  const history = useHistory()
  const { addToast } = useToasts()

  async function logout() {
    addToast('Usuário deslogado', {
      appearance: 'success',
      autoDismiss: true,
    })
    localStorage.clear()
    history.push('/')
  }

  const [, setRouteState] = useRouteState()

  return (
    <>
      <SidebarItem
        title={theme == 'whiteTheme' ? 'Tema escuro' : 'Tema claro'}
        icon={theme == 'whiteTheme' ? BsMoon : BsSun}
        onClick={() =>
          setTheme(theme == 'whiteTheme' ? 'darkTheme' : 'whiteTheme')
        }
      />
      <SidebarItem
        to="/configuration"
        id="configuration"
        title="Configuração"
        icon={BsGear}
        onClick={() => {
          setRouteState({
            key: 'configuration',
          })
        }}
      />
      <SidebarItem
        onClick={() => logout()}
        title="Sair da Conta"
        icon={BsBoxArrowInLeft}
      />
    </>
  )
}

export default ConfigMenu
