import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  background: ${props => props.theme.backgroundForm};
  border-radius: 5px;
  position: relative;
  width: 100%;

  .MuiChip-label {
    padding: 0px 7px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiChip-root {
    color: ${props => props.theme.title};
    background-color: ${props => props.theme.modalHover};
    border-radius: 4px;
    &:hover {
      background-color: ${props => props.theme.backgroundPrimaryContrast};
    }

    input {
      color: ${props => props.theme.title};
      /* max-width: 50px; */
    }
  }
  svg {
    width: 17px;
    height: 17px;
    color: ${props => props.theme.title};
    /* display: none; */

    &:hover {
      color: ${props => props.theme.modalHover};
    }
  }

  .suggestionContainer {
    position: absolute;
    z-index: 1;
    margin-bottom: 20px;
    background: ${props => props.theme.backgroundPrimary};
    border: 1px solid ${props => props.theme.backgroundSecondary};
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: 0px 0.4px 2.5px rgb(0 0 0 / 6%), 0 13px 20px rgb(0 0 0 / 13%);

    > div {
      padding: 8px 8px;
      border-top: 1px solid ${props => props.theme.backgroundSecondary};
      cursor: pointer;
      &:first-child {
        border: none;
      }
      &:hover {
        background: ${props => props.theme.backgroundSecondary};
      }
    }
  }
`
export const Section = styled.section`
  width: inherit;
`
