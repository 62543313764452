import { useSWRConfig, Key } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { ApiResponse, Searchable } from '@services/api'
import { useState } from 'react'
import { AxiosResponse } from 'axios'

export interface SWRFetcherProps extends Searchable {
  key: string
}

export interface ListResponse<Data = any, Error = any> {
  response?: AxiosResponse<ApiResponse<Data[]>>
  isError?: Error
  isLoading: boolean
  mutate: () => void
  resetCache: () => void
}

function useList<Data extends unknown, Extra = SWRFetcherProps, Error = any>(
  key: string | undefined,
  fetcher: (
    props: SWRFetcherProps & Extra,
  ) => Promise<AxiosResponse<ApiResponse<Data[]>>>,
  { limit, page, search, extra }: Searchable & { extra?: Extra },
  config?: SWRConfigurationProps,
): ListResponse<Data, Error> {
  const { mutate, cache } = useSWRConfig()
  const [allCache, setAllCache] = useState<Key[]>([])

  const { data, error } = useSWRImmutable<
    AxiosResponse<ApiResponse<Data[]>>,
    Error
  >(
    config?.canFetch === false ? null : [key, limit, page, search, extra],
    (k, l, p, s, e) => {
      setAllCache(allCached => [...allCached, [k, l, p, s, e]])
      return fetcher({ k, l, p, s, ...e })
    },
    config,
  )

  const mutateCurrent = () => {
    mutate([key, limit, page, search, extra])
  }

  const resetCache = () => {
    mutateCurrent()
    allCache.forEach(
      (k: any) =>
        k.toString() !== [key, limit, page, search, extra].toString() &&
        mutate(k, undefined, false),
    )
  }

  console.log(cache)

  return {
    response: data,
    isError: error,
    isLoading: !error && !data,
    mutate: mutateCurrent,
    resetCache,
  }
}

export default useList
