import React from 'react'
import { BsColumnsGap, BsPeople, BsBroadcast } from 'react-icons/bs'
import SidebarItem from '@sidebar/item'
import Divider from '@sidebar/divider'
import ConfigMenu from '@sidebar/menu/config'

const OrganizationMenu: React.FC = () => {
  return (
    <>
      <SidebarItem
        to="/dashboard"
        id="dashboard"
        title="Dashboards"
        icon={BsColumnsGap}
      />
      <SidebarItem
        to="/organization"
        id="organization"
        title="Membros"
        icon={BsPeople}
      />
      <SidebarItem
        to="/devices"
        id="devices"
        title="Dispositivos"
        icon={BsBroadcast}
      />
      <Divider />
      <ConfigMenu />
    </>
  )
}

export default OrganizationMenu
