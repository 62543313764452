import React, { useEffect, useState, useMemo } from 'react'
import TableHeader, { HeaderMap } from './Header'
import TableBody from './Body'
import Pagination from './Pagination'
import { Div, Table } from './styles'

export { Pagination, TableBody, TableHeader }

interface DataTableProps<T> {
  search?: string
  headers: HeaderMap<T>[]
  data: T[]
  onFilter: (item: T, search: string) => boolean
  render?: (item: T, index: number) => React.ReactNode | React.ReactElement
}

function DataTable<T>({
  search,
  headers,
  data,
  render,
  onFilter,
}: DataTableProps<T>) {
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ field: '', order: '' })

  const ITEMS_PER_PAGE = 10

  useEffect(() => {
    setCurrentPage(1)
  }, [search])

  const computedData = useMemo(() => {
    let computedComments = data

    if (search) {
      computedComments = computedComments.filter(item => onFilter(item, search))
    }

    setTotalItems(computedComments.length)

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]),
      )
    }

    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
    )
  }, [data, currentPage, search, sorting])

  return (
    <>
      <Div>
        <Table>
          <TableHeader
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <TableBody data={computedData} headers={headers} render={render} />
        </Table>
        {totalItems != 0 && (
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={page => setCurrentPage(page)}
          />
        )}
      </Div>
    </>
  )
}

export default DataTable
