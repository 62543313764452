import { DefaultTheme } from 'styled-components'

export const whiteTheme: DefaultTheme = {
  themeName: 'whiteTheme',
  backgroundPrimary: '#fff',
  backgroundPrimaryContrast: '#fff',
  backgroundSecondary: '#e9ecef',
  backgroundTerciary: '#f8fafb',
  backgroundHover: '#f3f3f3',
  title: '#495057',
  placeholder: '#adb5bd',
  backgroundForm: '#fff',
  tbody: '#6c757d',
  greenHighlight: '#00a65f',
  topLiquid: '#3bd895',
  iconColor: '#2E2E3A',
  modalHover: '#f7f7f7',
  modalOverlay: '#ffffffc4',
  borderColorCustom: '#f7f7f7',
  chart1: '#00A65F',
  chart2: '#EF476F',
  chart3: '#736CED',
  chart4: '#FFD166',
  chart5: '#E29578',
  backgroundPrimary_8: '#e6e6e6cc',
}

export const darkTheme: DefaultTheme = {
  themeName: 'darkTheme',
  backgroundPrimary: '#222',
  backgroundPrimaryContrast: '#2c2c2c',
  backgroundSecondary: '#4b4b4b',
  backgroundTerciary: '#3c3b3b',
  backgroundHover: '#3a3a3a',
  title: '#eaeef1',
  placeholder: '#adb5bd',
  backgroundForm: '#333',
  tbody: '#dcf5df',
  greenHighlight: '#27e18f',
  topLiquid: '#00c370',
  iconColor: '#dcf5df',
  modalHover: '#595959',
  modalOverlay: '#222222c2',
  borderColorCustom: '#3a3a3a',
  chart1: '#00A65F',
  chart2: '#EF476F',
  chart3: '#736CED',
  chart4: '#FFD166',
  chart5: '#E29578',
  backgroundPrimary_8: '#2c2c2ccc',
}
