import styled from 'styled-components'

export const Div = styled.div`
  padding-top: 1%;
  padding-right: 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const DivFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 95px;
`

export const Button = styled.button`
  color: ${props => props.theme.title};
  cursor: pointer;
  margin: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  background: ${props => props.theme.backgroundPrimary};
  border-radius: 6px;
  border: none;
  &:hover {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  }
`
