import env from '../env'
import {
  Configuration,
  DashboardApi,
  DeviceApi,
  LoginApi,
  LogsApi,
  OrganizationApi,
  UserApi,
  VariableApi,
  WidgetApi,
  SystemLogApi,
  ApiPagination,
  WidgetTypeEnum,
  WidgetCustomData,
} from './openapi'

export * from './openapi'

export interface ApiResponse<T, E = T> {
  data?: T
  error?: E
  status: number
  success: boolean
  message: string
  pagination?: ApiPagination
}

export interface WidgetDTO {
  customData: WidgetCustomData
  dashboardId: number
  deviceId: number
  title: string
  type: WidgetTypeEnum
  variables: number[]
}

function getConfiguration(): Configuration {
  return new Configuration({
    accessToken: () => localStorage.getItem('@token') || '',
    basePath: env.API_URL,
  })
}

export function getWidgetApi() {
  return new WidgetApi(getConfiguration())
}

export function getDashboardApi() {
  return new DashboardApi(getConfiguration())
}

export function getDeviceApi() {
  return new DeviceApi(getConfiguration())
}

export function getLoginApi() {
  return new LoginApi(getConfiguration())
}

export function getVariableApi() {
  return new VariableApi(getConfiguration())
}

export function getLogApi() {
  return new LogsApi(getConfiguration())
}

export function getSystemLogApi() {
  return new SystemLogApi(getConfiguration())
}

export function getUserApi() {
  return new UserApi(getConfiguration())
}

export function getOrganizationApi() {
  return new OrganizationApi(getConfiguration())
}
