import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { getLoginApi } from '@services/api'
import { useToasts } from 'react-toast-notifications'
import Logo from '@assets/logo-default.png'
import LogoDark from '@assets/logo-theme-dark.png'
import { useTheme } from 'styled-components'
import {
  Main,
  IntroDiv,
  ImgLogo,
  ImgGraphic,
  FormCard,
  TableDiv,
  Div,
  FirstText,
  SecondText,
  Form,
  LinkLogin,
  InputDiv,
  Label,
  Button,
} from './styles'
import Graphics from '../../../assets/img/graphics.png'
import { ButtonLoanding } from '../styles'

export default function Index() {
  const { addToast } = useToasts()
  const [email, setEmail] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const history = useHistory()
  const theme: any = useTheme()

  const apiLogin = getLoginApi()

  function getLogoImg() {
    const src = {
      src: Logo,
    }
    if (theme.themeName == 'darkTheme') src.src = LogoDark
    return src
  }

  function onSubmit(e) {
    e.preventDefault()
    setButtonDisabled(true)

    apiLogin.loginControllerSendEmailToReset({ email }).then(response => {
      if (response.data.success) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        history.push('/')
      } else {
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    })
    setButtonDisabled(false)
  }

  return (
    <Main>
      <IntroDiv>
        <ImgLogo {...getLogoImg()} alt="Logo interlock" />
        <h1>Lorem ipsum dolor sit</h1>
        <p>
          Lorem ipsum dolor sit amet. Qui tempora enim est sint aperiam sit
          itaque est quas voluptatibus et eveniet necessitatibus At culpa
          repellendus.
        </p>
        <ImgGraphic src={Graphics} alt="pessoas mexendo em um grafico" />
      </IntroDiv>
      <FormCard>
        <TableDiv onSubmit={e => onSubmit(e)}>
          <Div>
            <ImgLogo {...getLogoImg()} alt="Logo interlock" />
            <FirstText>Esqueceu a senha?</FirstText>
            <SecondText>
              Digite o endereço de e-mail que você usou quando se inscreveu e
              enviaremos instruções para redefinir sua senha.
            </SecondText>
            <Form>
              <Label>E-mail</Label>
              <InputDiv>
                <AiOutlineMail size="20px" />
                <input
                  required
                  placeholder="E-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </InputDiv>
            </Form>
            <LinkLogin>
              <Link to="/">Fazer Login</Link>
            </LinkLogin>
            {buttonDisabled ? (
              <ButtonLoanding type="submit">
                <span>
                  <AiOutlineMail size="20px" />
                </span>
                Carregando
              </ButtonLoanding>
            ) : (
              <Button type="submit">
                <span>
                  <AiOutlineMail size="20px" />
                </span>
                Enviar
              </Button>
            )}
          </Div>
        </TableDiv>
      </FormCard>
    </Main>
  )
}
