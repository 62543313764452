import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import Input from '@components/Input'
import ModalComponent from '@components/modal'
import { getOrganizationApi } from '@services/api'
import { Div } from '@components/modal/styles'
import {
  ButtonGreen,
  ButtonWhite,
} from '../../../../assets/styles/stylesButton'

interface ICreateOrganization {
  open: boolean
  setOpen
  onAddOrganization
}

const CreateOrganization: React.FC<ICreateOrganization> = ({
  setOpen,
  open,
  onAddOrganization,
}) => {
  const { addToast } = useToasts()

  const [name, setName] = useState<any>('')

  const apiOrganization = getOrganizationApi()

  function onSubmit(e) {
    e.preventDefault()

    const organizationName = e.target.name.value

    apiOrganization
      .organizationControllerCreate({ name: organizationName })
      .then(response => {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        setOpen(false)
        onAddOrganization(response.data)
      })
      .catch(error => {
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <ModalComponent
      open={open}
      setOpen={() => setOpen(false)}
      title="Nova organização"
    >
      <form onSubmit={onSubmit}>
        <div>
          <Input
            required
            name="name"
            label="Nome"
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder="Digite um nome..."
          />
        </div>

        <Div>
          <ButtonWhite onClick={() => setOpen(false)}>Cancelar</ButtonWhite>
          <ButtonGreen>Criar</ButtonGreen>
        </Div>
      </form>
    </ModalComponent>
  )
}

export default CreateOrganization
