import styled from 'styled-components'
import { BsThreeDotsVertical } from 'react-icons/bs'

export const ThreeDots = styled(BsThreeDotsVertical)<{ active?: boolean }>`
  fill: ${({ active, theme }) => (active ? theme.greenHighlight : theme.title)};

  &:hover {
    fill: ${props => props.theme.greenHighlight};
  }
`

export const Div = styled.div`
  cursor: pointer;
  line-height: 1.5;
`

export const DropDownContainer = styled.div<{
  active?: boolean
}>`
  background-color: ${props => props.theme.backgroundPrimary};
  z-index: 13;
  width: 10.5em;
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-size: 15px;
  color: ${props => props.theme.title};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  text-align: center;
  position: absolute;
  border: 1px solid ${props => props.theme.modalHover};
  border-radius: 8px;
  transform: translateX(-100%);
  overflow: hidden;

  span {
    font-family: Inter;
    padding: 7px 7px;
    cursor: pointer;
    border-left: 2px solid transparent;
    &:hover {
      border-left: 2px solid #00a65f;
      background: ${props => props.theme.modalHover};
    }
  }
`
