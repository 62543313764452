import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../components/store/context'
import parseJwt from '../helpers/parseJwt'

const RoutesPrivates = ({
  component: Component,
  renderCustom: RenderCustom,
  ...rest
}: {
  component?
  renderCustom?
  exact?
  path?
}) => {
  const dateNow = new Date()
  const [token] = useAuth()
  let tokenJson
  let render = false
  const localStorageToken = localStorage.getItem('@token')

  if (localStorageToken) tokenJson = parseJwt(localStorageToken)
  else tokenJson = parseJwt(token)

  if (tokenJson) {
    const dateToken = new Date(tokenJson.exp * 1000)

    if (dateToken <= dateNow) {
      localStorage.clear()
      render = false
    } else {
      render = true
    }
  } else {
    render = false
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (Component) {
          return render == true ? <Component {...rest} /> : <Redirect to="/" />
        }
        return render == true ? <RenderCustom {...rest} /> : <Redirect to="/" />
      }}
    />
  )
}

export default RoutesPrivates
