import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { useTheme } from 'styled-components'
import Modal from 'react-modal'
import { AiOutlineClose } from 'react-icons/ai'
import SelectTime from '@selects/select-time'
import { getDashboardApi } from '@services/api'
import { Dashboard } from '@services/openapi'
import { FooterForm, Header, Form } from './styles'
import { ButtonWhite, ButtonGreen } from '../../../assets/styles/stylesButton'

export default function Index({
  dashboard,
  setOpen,
  open,
  onUpdate,
}: {
  dashboard: Dashboard
  open: boolean
  onUpdate: (time: number) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const theme: any = useTheme()
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      height: '40vh',
      backgroundColor: theme.backgroundPrimary,
      borderColor: theme.backgroundPrimaryContrast,
      boxShadow: `2px 2px 9px ${
        theme.themeName != 'whiteThem' ? '#000' : theme.title
      }`,
      // zIndex: '200 !important',
    },
    overlay: {
      backgroundColor: theme.modalOverlay,
    },
  }

  const { addToast } = useToasts()
  const [time, setTime] = useState<number>(-1)

  const apiDashboard = getDashboardApi()

  useEffect(() => {
    setTime(dashboard.time)
  }, [dashboard])

  function closeModal() {
    setOpen(false)
  }

  async function onSubmit(e) {
    e.preventDefault()

    await apiDashboard
      .dashboardControllerUpdate(dashboard.id!, {
        time,
      })
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          onUpdate(time)
          closeModal()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Modal Update Time"
      ariaHideApp={false}
    >
      <Header>
        Tempo de atualização
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </Header>
      <Form onSubmit={e => onSubmit(e)} style={{ marginTop: '10%' }}>
        <SelectTime time={time} setTime={setTime} />
        <FooterForm>
          <ButtonWhite onClick={() => closeModal()}>Cancelar</ButtonWhite>
          <ButtonGreen>Atualizar</ButtonGreen>
        </FooterForm>
      </Form>
    </Modal>
  )
}
