import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { Link, useHistory } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { BsKey, BsEye, BsEyeSlash } from 'react-icons/bs'
import { BiLogIn } from 'react-icons/bi'
import { useToasts } from 'react-toast-notifications'
import { useTheme } from 'styled-components'

import { useAuth } from '@store/context'
import parseJwt from '@helpers/parseJwt'
import Graphics from '@assets/img/graphics.png'
import Logo from '@assets/logo-default.png'
import LogoDark from '@assets/logo-theme-dark.png'
import { getLoginApi } from '@services/api'
import {
  Main,
  IntroDiv,
  ImgLogo,
  ImgGraphic,
  FormCard,
  TableDiv,
  Div,
  FirstText,
  SecondText,
  Form,
  InputDiv,
  InputDivPass,
  Label,
  Span,
  FooterForm,
  ButtonLoanding,
  Button,
} from './styles'

export default function Index() {
  const [, setToken] = useAuth()
  const { addToast } = useToasts()
  const history = useHistory()
  const localStorageToken = localStorage.getItem('@token')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const theme: any = useTheme()

  function getLogoImg() {
    const src = {
      src: Logo,
    }
    if (theme.themeName == 'darkTheme') src.src = LogoDark
    return src
  }
  const apiLogin = getLoginApi()

  useEffect(() => {
    const dateNow = new Date()
    if (localStorageToken) {
      const tokenJson = parseJwt(localStorageToken)
      if (tokenJson != false) {
        const dateToken = new Date(tokenJson.exp * 1000)
        if (tokenJson.remember) {
          if (dateToken <= dateNow) localStorage.clear()
          else {
            setToken(tokenJson)
            history.push('/dashboard')
          }
        } else localStorage.clear()
      }
    }
  }, [])

  async function onSubmit(e) {
    e.preventDefault()
    setButtonDisabled(true)
    const form = { email, password, rememberMe }

    await apiLogin
      .loginControllerLogin(form)
      .then(response => {
        if (response.data.success) {
          // TODO olhar esses caras
          setToken(parseJwt(response.data.data))
          localStorage.setItem('@token', response.data.data)
          history.push('/dashboard')
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        if (error.response) {
          addToast(
            error.response.data.message === 'ACCOUNT_BANNED'
              ? 'Você foi banido da organização. Para mais informações, contate o administrador.'
              : 'Credenciais inválidas',
            {
              appearance: 'error',
              autoDismiss: true,
            },
          )
        }
        debug('api')(error)
      })
    setButtonDisabled(false)
  }

  return (
    <Main>
      <IntroDiv>
        <ImgLogo {...getLogoImg()} alt="Logo interlock" />
        <h1>A maneira mais fácil de interagir com seu ecossistema</h1>
        <p>
          Conecte seu hardware e/ou serviços de dados digitais à nuvem sem
          códigos com nossas ferramentas de coleta, análise e visualização de
          dados.
        </p>
        <ImgGraphic src={Graphics} alt="pessoas mexendo em um grafico" />
      </IntroDiv>
      <FormCard onSubmit={e => onSubmit(e)}>
        <TableDiv>
          <Div>
            <ImgLogo {...getLogoImg()} alt="Logo interlock" />
            <FirstText>Bem-vindo!</FirstText>
            <SecondText>Por favor, faça o login na sua conta</SecondText>
            <Form>
              <Label>E-mail:</Label>
              <InputDiv>
                <AiOutlineMail size="20px" />
                <input
                  value={email}
                  type="email"
                  placeholder="E-mail"
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </InputDiv>
              <Label>Senha:</Label>
              <InputDivPass>
                <BsKey size="20px" />
                {showPassword ? (
                  <>
                    <input
                      value={password}
                      type="text"
                      onChange={e => setPassword(e.target.value)}
                    />
                    <BsEye
                      size="24px"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </>
                ) : (
                  <>
                    <input
                      value={password}
                      type="password"
                      placeholder="Senha"
                      onChange={e => setPassword(e.target.value)}
                    />
                    <BsEyeSlash
                      size="24px"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </>
                )}
              </InputDivPass>
            </Form>
            <Span>Deve ter no mínimo 8 caracteres</Span>
            <FooterForm>
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="remember"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  Lembre-me
                </label>
              </div>
              <div>
                <Link to="/forgot-password">Esqueceu a senha?</Link>
              </div>
            </FooterForm>
            {/* <FooterForm>
              <div>
                <Link to="/create-account">Criar conta</Link>
              </div>
            </FooterForm> */}
            {buttonDisabled ? (
              <ButtonLoanding type="submit">
                <span>
                  <BiLogIn size="20px" />
                </span>
                Carregando
              </ButtonLoanding>
            ) : (
              <Button type="submit">
                <span>
                  <BiLogIn size="20px" />
                </span>
                Entrar
              </Button>
            )}
          </Div>
        </TableDiv>
      </FormCard>
    </Main>
  )
}
