import { Variable, WidgetValueMap } from '@services/openapi'
import calculateText from './compare-text'
import convertKeyLikeValue from './convertKeyFormulasLikeValue'
import calculateFormula from './formulas'

function parseConditionalsCustomization(
  map: WidgetValueMap[] | undefined,
  variables: Variable[],
  value: any,
) {
  let color
  let rotulo
  let unidade

  map?.forEach(item => {
    if (!item.expression) return

    const prepared = convertKeyLikeValue(item.expression, variables, value)

    const okFormula =
      prepared.match(/[A-Za-z"']+/) !== null
        ? calculateText(prepared)
        : calculateFormula(prepared)

    if (okFormula == true && item.action == 'color') {
      color = item.value
    } else if (okFormula == true && item.action == 'rotulo') {
      rotulo = item.value
    } else if (okFormula == true && item.action == 'unidade') {
      unidade = item.value
    }
  })

  return { color, rotulo, unidade }
}

export default parseConditionalsCustomization
