import React, { useEffect, useState } from 'react'
import { Content } from './styles'

export const CylinderDynamic = ({
  color,
  liquid,
}: {
  color?: any
  liquid: any
}) => {
  const [positionTopLiquid, setPositionTopLiquid] = useState(0)

  const [heightLiquid, setHeightLiquid] = useState(0)
  const [positionYLiquid, setPositionYLiquid] = useState(0)

  const positionLiquidMax = 20
  const positionLiquidMin = 152
  const positionComparative = positionLiquidMin - positionLiquidMax
  const heightLiquidMax = 127

  const sizeTopLiquid = 15.496
  const tolerance = 7

  function calculatePosition() {
    return positionLiquidMin - (positionComparative * liquid) / 100
  }

  function calculateHeight() {
    const result = (heightLiquidMax * liquid) / 100
    const heightCalculate = result + (tolerance * 2 - 3.3) - sizeTopLiquid / 2
    return heightCalculate > 0 ? heightCalculate : 0
  }

  useEffect(() => {
    const postion = calculatePosition()
    const height = calculateHeight()

    setPositionTopLiquid(postion)
    setHeightLiquid(height)
    setPositionYLiquid(postion - tolerance + sizeTopLiquid / 2)
  })
  return (
    <Content color={color}>
      <svg
        width="100%"
        height="80%"
        viewBox="0 0 160.88965 170.07862"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="left-glass"
            d="M 15.106 19.658 C 15.106 63.998 15.106 108.325 15.106 152.658"
            fill="none"
            stroke="hsl(0, 0%, 78%)"
            strokeWidth="1"
          />
          <path
            id="right-glass"
            d="M 148.106 19.658 C 148.106 63.998 148.106 108.325 148.106 152.658"
            fill="none"
            stroke="hsl(0, 0%, 78%)"
            strokeWidth="1"
          />

          <ellipse
            id="bottom-glass"
            rx="66.5"
            ry="16.5"
            cx="81.606"
            cy="152.658"
            fill="none"
            stroke="hsl(0, 0%, 78%)"
            strokeWidth="1"
          />

          <ellipse
            className={`liquid ${
              !liquid || liquid === 0 ? 'disable-cylinder' : ''
            }`}
            id="bottom-liquid"
            rx="66"
            ry="16"
            cx="81.606"
            cy="152.658"
            opacity="1"
            fill="hsl(208, 56%, 46%)"
            stroke="none"
          >
            <title>1.44</title>
          </ellipse>

          <rect
            className={`liquid ${
              !liquid || liquid === 0 ? 'disable-cylinder' : ''
            }`}
            id="liquid"
            width="132"
            height={heightLiquid}
            x="15.606"
            y={positionYLiquid}
            fill="hsl(208, 56%, 46%)"
            opacity="1"
            stroke="none"
          >
            <title>1.44</title>
          </rect>

          <ellipse
            className={`liquidTop ${
              !liquid || liquid === 0 ? 'disable-cylinder' : ''
            }`}
            id="top-liquid"
            rx="65.61"
            ry={sizeTopLiquid}
            cx="81.624"
            cy={positionTopLiquid}
            fill="hsl(208, 56%, 61%)"
            stroke="hsl(208, 56%, 61%)"
            opacity="1"
            strokeWidth="1"
          >
            <title>1.44</title>
          </ellipse>
          <ellipse
            id="top-glass"
            rx="66.515"
            ry="15.515"
            cx="81.606"
            cy="19.658"
            fill="none"
            stroke="hsl(0, 0%, 78%)"
            strokeWidth="1"
          />
        </g>
      </svg>
    </Content>
  )
}
