import React, { ReactChild } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal, { Props as ModalProps } from 'react-modal'
import { useTheme } from 'styled-components'
import { Title } from './styles'

export default function ModalComponent({
  open,
  setOpen,
  children,
  header,
  title,
  style,
  ...props
}: {
  open: any
  setOpen: any
  children?: any
  title?: string
  header?: ReactChild
  style?: any
} & Partial<ModalProps>) {
  const theme: any = useTheme()
  const customStyles = {
    content: {
      overflowY: 'auto',
      overflowX: 'hidden',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      maxHeight: '70vh',
      backgroundColor: theme.backgroundPrimary,
      borderWidth: 0,
      boxShadow: `0 0 30px rgba(0, 0, 0, .2)`,
      ...(style?.content ? style.content : {}),
    },
    overlay: {
      backgroundColor: theme.modalOverlay,
      ...(style?.overlay ? style.overlay : {}),
    },
  }

  function closeModal() {
    setOpen(false)
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeModal()}
      style={customStyles}
      ariaHideApp={false}
      {...props}
    >
      {header ||
        (title ? (
          <Title>
            {title}
            <span>
              <AiOutlineClose onClick={() => closeModal()} />
            </span>
          </Title>
        ) : null)}
      {children}
    </Modal>
  )
}
