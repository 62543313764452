import React, { Fragment, useEffect, useState } from 'react'
import debug from 'debug'
import { BsPlusLg, BsDashLg } from 'react-icons/bs'
import { Mandatory } from '@components/modal/widget/styles'
import useVariables from '@hooks/useVariables'
import { Div, Label, LabelVar, Select, DivMulti, DivButtons } from './styles'
import { ButtonGreen, ButtonRed } from '../../assets/styles/stylesButton'

export default function index({
  idDevice,
  setVariable,
  variable,
  required,
  type,
}) {
  const [inputFields, setInputFields] = useState<number[]>(variable ?? [0])

  const handleAddFields = () => {
    const values = [...inputFields]
    values.push(0)
    setInputFields(values)
  }

  const handleRemoveFields = i => {
    const values = [...inputFields]
    values.splice(i, 1)
    setInputFields(values)
  }

  const handleInputChange = (i, event) => {
    const values = [...inputFields]
    if (event.target.name === 'variable') values[i] = event.target.value
    setInputFields(values)
  }

  const { variables, isError } = useVariables(idDevice)

  useEffect(() => {
    if (variables.length > 0) {
      setInputFields(variable.length > 0 ? variable : [0])
    }
  }, [variables])

  useEffect(() => {
    debug('api')(isError)
  }, [isError])

  useEffect(() => {
    setVariable(inputFields ?? [0])
  }, [inputFields])

  const ListVariables: React.FC<{ v: any[] }> = ({ v }) => {
    return (
      <>
        {v.map(d => (
          <option value={d.id} key={d.id}>
            {d.tag}
          </option>
        ))}
      </>
    )
  }

  return (
    <Div>
      {inputFields.map((inputField, i) => {
        return (
          <Fragment key={`${JSON.stringify(inputField)}`}>
            {type == 'BAR_CHART' || type == 'LINE_CHART' ? (
              <>
                {i === 0 && (
                  <LabelVar htmlFor={`select-variable${i}`}>
                    Variável
                    <Mandatory>*</Mandatory>
                  </LabelVar>
                )}
                <DivMulti>
                  <Select
                    name="variable"
                    id={`select-variable${i}`}
                    required={required}
                    className={`form-control ${
                      inputField ? 'not-empty' : 'empty'
                    }`}
                    value={inputField}
                    onChange={event => handleInputChange(i, event)}
                  >
                    <option value="">Escolha uma Variável...</option>
                    <ListVariables v={variables} />
                  </Select>
                  <DivButtons>
                    {inputFields.length > 1 && (
                      <ButtonRed onClick={() => handleRemoveFields(i)}>
                        <BsDashLg />
                      </ButtonRed>
                    )}
                    {inputFields.length < 5 && (
                      <ButtonGreen onClick={() => handleAddFields()}>
                        <BsPlusLg />
                      </ButtonGreen>
                    )}
                  </DivButtons>
                </DivMulti>
              </>
            ) : (
              <>
                {i === 0 && (
                  <Label htmlFor={`select-variable${i}`}>
                    Variável
                    <Mandatory>*</Mandatory>
                  </Label>
                )}
                <Select
                  name="variable"
                  id={`select-variable${i}`}
                  required={required}
                  className={`form-control ${
                    inputField ? 'not-empty' : 'empty'
                  }`}
                  value={inputField}
                  onChange={event => handleInputChange(i, event)}
                >
                  <option value="">Escolha uma Variável...</option>
                  <ListVariables v={variables} />
                </Select>
              </>
            )}
          </Fragment>
        )
      })}
    </Div>
  )
}
