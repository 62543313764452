import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  width: 100%;
`

export const Label = styled.label`
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.title};
  margin-bottom: 2%;
`

export const LabelVar = styled(Label)`
  margin-bottom: 5px;
`

export const Select = styled.select`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 6px;
  padding: 12px;
  background-color: ${props => props.theme.backgroundPrimaryContrast};
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;

  &.empty {
    color: ${props => props.theme.placeholder};
  }
  &.not-empty {
    color: ${props => props.theme.title};
  }
  &:focus {
    box-shadow: 0px 0.4px 2.5px rgba(0, 0, 0, 0.062),
      0px 3px 20px rgba(0, 0, 0, 0.13);
  }
`

export const DivMulti = styled(Div)`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  justify-content: space-between;
  margin-top: 15px;

  select {
    width: 80%;
  }

  button {
    width: 45px;
    margin-right: 15%;
  }
`

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SelectGraphic = styled.select`
  background: ${props => props.theme.backgroundSecondary};
  font-family: Inter, sans-serif;
  border-radius: 6px;
  padding: 3px 5px;
  border: none;
  color: ${props => props.theme.title};
`

export const DivGraphic = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`
