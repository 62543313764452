import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { useParams, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import SideBar from '@sidebar/index'
import SideBarDash from '@sidebar/dash'
import Navbar from '@navbar/dash'
import Principal from '@components/pages/dashboard'
import { getDashboardApi } from '@services/api'
import { Dashboard, NewWidget } from '@services/openapi'
import { useRouteState } from '@components/store/context'
import { Main } from './style'

export default function Index() {
  const { index } = useParams()
  const history = useHistory()

  const { addToast } = useToasts()
  const [onLoad, setOnLoad] = useState<boolean>(true)
  const [dashboardIndex, setDashboardIndex] = useState<number>(0)
  const [idActive, setIdActive] = useState<number>(-1)
  const [edit, setEdit] = useState<boolean>(false)
  const [activeSideBar, setActiveSideBar] = useState<boolean>(true)
  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const [dashboard, setDashboard] = useState<Dashboard>({} as Dashboard)
  const [dashboards, setDashboards] = useState<Dashboard[]>([])
  const [reDrawing, setReDrawing] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<number>(0)
  const [newWidget, addWidget] = useState<NewWidget>()

  const apiDashboard = getDashboardApi()

  async function getAllDashboards() {
    setOnLoad(true)
    await apiDashboard
      .dashboardControllerGetAllByUser()
      .then(response => {
        if (response.data.success) {
          setDashboards(response.data.data)
          if (response.data.data.length > 0) {
            setIdActive(response.data.data[0].id!)
          }
        }
        setOnLoad(false)
      })
      .catch(error => {
        setOnLoad(false)
        debug('api')(error)
        addToast('Nenhuma dashboard encontrada', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  function updateDashboardList(updatedDashboard: Dashboard) {
    const newDashboadList = [...dashboards]
    newDashboadList[dashboardIndex] = updatedDashboard
    setDashboards(newDashboadList)
  }

  useEffect(() => {
    getAllDashboards()
  }, [])

  useEffect(() => {
    if (idActive === -1 && index && dashboards.length > 0) {
      if (dashboards[index]) setIdActive(dashboards[index].id!)
    } else if (idActive >= 0 && dashboards.length > 0) {
      const i = dashboards.findIndex(d => d.id === idActive)
      setDashboardIndex(i)
      setDashboard({ ...dashboards[i] })
      history.replace(`/dashboard/${i}`)
    } else {
      setDashboardIndex(-1)
      setDashboard({} as Dashboard)
    }
  }, [idActive, dashboards])

  const handleActiveSideBar = event => {
    setActiveSideBar(event)
  }

  const handleActiveDrawing = () => {
    setReDrawing(false)
  }

  const [, setRouteState] = useRouteState()

  useEffect(() => {
    setRouteState({
      key: 'dashboard',
    })
  }, [])

  return (
    <div>
      <SideBar />
      <SideBarDash
        dashboards={dashboards}
        setDashboards={setDashboards}
        idActive={idActive}
        setIdActive={setIdActive}
        activeSideBar={activeSideBar}
        setEdit={setEdit}
      />

      {dashboard.id && (
        <>
          <Navbar
            idActive={idActive}
            dashboard={dashboard}
            edit={edit}
            setEdit={setEdit}
            setActiveSideBar={handleActiveSideBar}
            activeSideBar={activeSideBar}
            setFullScreen={setFullScreen}
            onRefresh={() => setRefresh(refresh + 1)}
            onUpdateDashboardTime={(time: number) => {
              updateDashboardList({ ...dashboard, time })
            }}
            onUpdateDashboardName={(name: string) => {
              updateDashboardList({ ...dashboard, name })
            }}
            onAddWidget={(data: NewWidget) => {
              addWidget(data)
              updateDashboardList({ ...dashboard, position: data.newPosition })
            }}
          />
        </>
      )}

      <Main active={activeSideBar}>
        {dashboard.id ? (
          <>
            {!onLoad && (
              <Principal
                reDrawing={reDrawing}
                setReDrawing={handleActiveDrawing}
                dashboard={dashboard!}
                addWidget={newWidget?.newWidget}
                refresh={refresh}
                edit={edit}
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                onUpdateDashboard={(data: Dashboard) => {
                  updateDashboardList(data)
                }}
              />
            )}
          </>
        ) : (
          <>
            <div>Selecione uma dashboard</div>
          </>
        )}
      </Main>
    </div>
  )
}
