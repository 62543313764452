import React from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'

import { getVariableApi } from '@services/api'
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
} from '../../../assets/styles/stylesButton'

interface VariableItemProps {
  variable
  onToggleCheck: (checked: boolean) => void
}

const VariableItem: React.FC<VariableItemProps> = ({
  variable,
  onToggleCheck,
}) => {
  const apiVariable = getVariableApi()
  const { addToast } = useToasts()

  async function storeData(id, disabled) {
    ;(disabled
      ? apiVariable.variableControllerEnableLog(id)
      : apiVariable.variableControllerDisableLog(id)
    )
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          // onUpdate(response.data.data)
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
        addToast('Error ao atualizar', {
          appearance: 'error',
          autoDismiss: true,
        })
      })

    return true
  }

  return (
    <tr key={variable.id}>
      <th scope="row">
        <input
          type="checkbox"
          value={variable.id}
          onChange={e => onToggleCheck(e.target.checked)}
        />
      </th>
      <td>{variable.tag}</td>
      <td>{variable.logsCount}</td>
      <td>
        <CheckBoxWrapper
          onChange={() => storeData(variable.id, variable.disabled)}
        >
          <CheckBox
            id={`checkbox ${variable.id}`}
            type="checkbox"
            defaultChecked={!variable.disabled}
          />
          <CheckBoxLabel htmlFor={`checkbox ${variable.id}`} />
        </CheckBoxWrapper>
      </td>
    </tr>
  )
}

export default VariableItem
