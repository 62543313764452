import styled from 'styled-components'
import { Menu as BaseMenu } from '../styles'

export const Nav = styled.div<{
  active?: boolean
}>`
  padding: ${({ active }) =>
    active ? '0.8rem 2.5rem 0.8rem 20rem' : '0.8rem 2.5rem 0.8rem 5rem'};
  background-color: ${props => props.theme.backgroundPrimary};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24);
  height: 60px;

  @media (min-width: 768px) {
    z-index: 99;
  }

  @media (max-width: 1100px) {
    height: 150px;
  }

  @media (max-width: 768px) {
    padding: 4rem 0 2rem 0;
  }

  @media (max-width: 317px) {
    height: 300px;
  }
`

export const Margin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 0.4rem;
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const DivHeader = styled.div<{
  edit?: boolean
}>`
  width: 30%;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 20px;

  @media (max-width: 1100px) {
    width: 60%;
    justify-content: space-between;
  }
  svg {
    margin-right: ${({ edit }) => (edit ? '4%' : '5%')};
    color: ${props => props.theme.title};
    width: 20px;
  }

  @media (max-width: 768px) {
    .fabars {
      display: none;
    }
  }
`

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  label {
    color: ${props => props.theme.title};
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  input {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 6px;
    border: none;
    height: 26px;
    width: 70%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: ${props => props.theme.title};
    text-decoration: none;
    border-bottom: 1px solid #03844d;
  }

  @media (max-width: 1100px) {
    width: 100%;
    justify-content: space-between;

    input {
      width: 100%;
    }
  }
`

export const Menu = styled(BaseMenu)`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: end;
  width: 290px;

  .toggle-theme {
    display: unset;
  }

  button {
    margin: 2%;
  }

  @media (max-width: 1100px) {
    justify-content: space-between;
    margin-left: -1%;
    width: 60%;
  }
  @media (max-width: 317px) {
    flex-direction: column;
    align-items: normal;
  }
`

export const ButtonsEditAndView = styled.div`
  background-color: ${props => props.theme.backgroundSecondary};
  display: flex;
  width: 200px;
  padding: 5px;
  border-radius: 6px;
  color: ${props => props.theme.title};
  /* width: 65px;
  font-family: Inter;
  font-style: normal;

  @media (max-width: 1100px) {
    width: 60%;
    justify-content: space-between;
  } */
`

export const Div = styled.div<{
  active?: boolean
}>`
  box-shadow: ${({ active }) =>
    active ? ' 0px 0px 1px rgba(12, 26, 75, 0.24)' : 'none'};
  background-color: ${props => {
    if (props.active) return props.theme.backgroundPrimary
    return props.theme.backgroundSecondary
  }};
  font-size: 14px;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  span {
    margin-left: 5%;
    color: ${props => props.theme.title};
  }
  svg {
    color: ${props => props.theme.title};
  }
`
