import React, { useEffect, useState } from 'react'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { ButtonGreen } from '@assets/styles/stylesButton'
import mdfile from '@assets/md/HOW_TO_EXPRESSIONS.md'
import ModalComponent from '../..'
import { Div, Message } from './styles'

interface IAreYouSure {
  open: boolean
  setOpen
}

const ConditionalInstructionsModal: React.FC<IAreYouSure> = ({
  setOpen,
  open,
}) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    fetch(mdfile)
      .then(response => response.text())
      .then(text => setContent(text))
  }, [])

  return (
    <ModalComponent
      title="Como escrever expressões?"
      open={open}
      setOpen={setOpen}
    >
      <Message>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      </Message>
      <Div>
        <ButtonGreen onClick={() => setOpen(false)}>Fechar</ButtonGreen>
      </Div>
    </ModalComponent>
  )
}

export default ConditionalInstructionsModal
