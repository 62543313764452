import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { getDeviceApi, ApiResponse, Variable } from '@services/api'

function useVariables(deviceId?: number, config?: SWRConfigurationProps) {
  const fetch = config?.canFetch || (deviceId && deviceId > 0)

  const { data, error, mutate } = (
    config?.immutable === false ? useSWR : useSWRImmutable
  )<ApiResponse<Variable[]>>(
    fetch ? ['deviceControllerGetVariables', deviceId] : null,
    () =>
      getDeviceApi()
        .deviceControllerGetVariables(deviceId!)
        .then(response => response.data),
    config as SWRConfiguration,
  )

  return {
    variables: data?.data ?? [],
    response: data,
    isError: error,
    isLoading: fetch ? !error && !data : false,
    mutate,
  }
}

export default useVariables
