import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { ApiResponse } from '@services/api'
import useList, { ListResponse, SWRFetcherProps } from './useList'

interface PagedDataProps<T, Extra> {
  list: string
  canFetch?: boolean
  fetcher: (
    props: SWRFetcherProps & Extra,
  ) => Promise<AxiosResponse<ApiResponse<T[]>>>
  initial?: { limit?: number; page?: number }
  data: { search?: string } & Extra
}

export interface PagedDataResponse<Data = any, Error = any>
  extends ListResponse<Data, Error> {
  foundRows: number
  limit: number
  page: number
  pages: number
  updateLimit: (n: number) => void
  updatePage: (n: number) => void
}

function usePagedData<T, Extra = { search?: string }, Error = any>(
  {
    list,
    fetcher,
    initial,
    data: { search, ...extra },
  }: PagedDataProps<T, Extra>,
  config?: SWRConfigurationProps,
): PagedDataResponse<T, Error> {
  const [limit, updateLimit] = useState<number>(
    initial && initial.limit ? initial.limit : 10,
  )
  const [page, updatePage] = useState<number>(
    initial && initial.page ? initial.page : 0,
  )

  const [foundRows, setFoundRows] = useState<number>(0)
  const [pages, setPages] = useState<number>(0)

  const { response, ...swr } = useList<T, Extra, Error>(
    list,
    fetcher,
    {
      limit,
      page,
      search,
      extra: extra as Extra,
    },
    config,
  )

  useEffect(() => {
    const res = response?.data
    if (res && res.pagination && res.pagination.foundRows !== foundRows) {
      setFoundRows(res.pagination.foundRows)
      setPages(Math.ceil(res.pagination.foundRows / limit))
    }
  }, [response, foundRows])

  return {
    response,
    ...swr,
    foundRows,
    limit,
    page,
    pages,
    updateLimit: (n: number) => updateLimit(n),
    updatePage: (n: number) => updatePage(n),
  }
}

export default usePagedData
