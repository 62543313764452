import React, { useEffect, useState, useRef, memo } from 'react'

import { convertDateInString } from '@utils/date-graphic'
import Dropdown from '@components/dropdown'
import { CylinderDynamic } from '@components/dynamic/cylinder/cylinder-dynamic'
import { Log, Widget, WidgetRange, WidgetUnit } from '@services/openapi'
import useLastLog from '@hooks/useLastLog'
import parseConditionalsCustomization from '@utils/parseConditionalsCustomization'
import {
  CardMetter,
  DivTitle,
  Title,
  UnitSpeedSpan,
  CylinderContent,
} from './styles'

function Index({
  time,
  title,
  units,
  range,
  widget,
  refresh,
  edit,
  onClone,
  setModalVisibleDelete,
  setModalVisibleUpdate,
  setWidget,
}: {
  time: number
  title: string
  units?: WidgetUnit
  range?: WidgetRange
  widget: Widget
  refresh?: number
  edit: boolean
  onClone?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
  setWidget?: (widget: Widget) => void
}) {
  const [date, setDate] = useState('')
  const [value, setValue] = useState(0)
  const refWidget = useRef<HTMLDivElement>(null)
  const [log, setLog] = useState<Log>()
  const [lastModified, setLastModified] = useState<string>()

  const { data, lastModifiedTimestamp, error, refreshNow } = useLastLog(
    widget.variables,
    log,
    lastModified,
    time * 1000,
  )

  useEffect(() => {
    if (data) {
      setLog(data as Log)
    }
    setLastModified(lastModifiedTimestamp || lastModified)
  }, [data, error])

  useEffect(() => refreshNow(), [refresh])

  useEffect(() => {
    setDate(convertDateInString(log?.date))
    setValue(+(log?.value || '0'))
  }, [log])

  function generateLiquid() {
    const min = range?.minimumValue || 0
    const max = range?.maximumValue || 100
    let v = value
    if (v > max) {
      v = max
    }
    if (v < min) {
      v = min
    }
    return (100 * (v - min)) / (max - min)
  }

  function renderValueWithExpression() {
    const { color, rotulo, unidade } = parseConditionalsCustomization(
      widget.customData.map,
      widget.variableList,
      value,
    )

    const unit = unidade || units?.valueUnits

    return (
      <CylinderContent
        heightCard={refWidget.current ? refWidget.current.offsetHeight : false}
      >
        <CylinderDynamic color={color} liquid={generateLiquid()} />
        <section>
          <p style={{ color }}>
            {rotulo ?? value.toFixed(units?.decimalPlaces)}
          </p>
          {unit ? <span>{unit}</span> : null}
        </section>
      </CylinderContent>
    )
  }

  return (
    <>
      <CardMetter id="container" ref={refWidget}>
        <DivTitle>
          <Title>{title}</Title>
          {edit && (
            <Dropdown
              items={[
                [
                  'Editar',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleUpdate) setModalVisibleUpdate(true)
                  },
                ],
                ['Duplicar', () => onClone && onClone(widget)],
                [
                  'Excluir',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleDelete) setModalVisibleDelete(true)
                  },
                ],
              ]}
            />
          )}
        </DivTitle>
        {value !== undefined && value !== null && renderValueWithExpression()}
        <UnitSpeedSpan>
          <small>{date}</small>
        </UnitSpeedSpan>
      </CardMetter>
    </>
  )
}
export default memo(Index)
