import React from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { getWidgetApi, Widget } from '@services/api'
import AreYouSure from '../areyousure'

export default function Index({
  open,
  setOpen,
  widget,
  onDelete,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  widget: Widget
  onDelete: () => void
}) {
  const { addToast } = useToasts()

  async function remove() {
    await getWidgetApi()
      .widgetControllerRemove(widget.id!)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          setOpen(false)
          onDelete()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  return (
    <AreYouSure
      open={open}
      setOpen={setOpen}
      title={`Excluir widget ${widget.title}`}
      description="Deseja excluir esse widget?"
      confirmText="Excluir"
      onConfirm={() => remove()}
    />
  )
}
