import React, { useState } from 'react'
import debug from 'debug'
import { BsArrowBarLeft } from 'react-icons/bs'
import { useToasts } from 'react-toast-notifications'
import { getDashboardApi } from '@services/api'
import { Dashboard } from '@services/openapi'
import { ButtonRoundedGreen } from '../../../assets/styles/stylesButton'
import {
  SideBar,
  Menu,
  MenuLink,
  Hamburger,
  Header,
  ListDash,
  ListDashNative,
  Navbar,
} from './style'
import Item from './item'

export default function Index({
  dashboards,
  setDashboards,
  idActive,
  setIdActive,
  activeSideBar,
  setEdit,
}: {
  dashboards: Dashboard[]
  setDashboards
  idActive
  setIdActive
  activeSideBar
  setEdit
}) {
  const [isVisible, setIsVisible] = useState(false)
  const { addToast } = useToasts()

  const apiDashboard = getDashboardApi()

  async function createDash() {
    await apiDashboard
      .dashboardControllerCreate()
      .then(response => {
        if (response.data.success) {
          setDashboards([...dashboards, response.data.data])
          setIdActive(response.data.data.id!)
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
        debug('api')(error)
      })
  }

  function deleteDashboard() {
    dashboards.splice(
      dashboards.findIndex(dashboard => dashboard.id === idActive),
      1,
    )
    setIdActive(-1)
    setDashboards([...dashboards])
  }

  return (
    <>
      {!isVisible && (
        <Hamburger onClick={() => setIsVisible(!isVisible)}>
          <BsArrowBarLeft />
        </Hamburger>
      )}
      <SideBar active={activeSideBar}>
        <Menu>
          <Header>
            <MenuLink>Minhas Dashboards</MenuLink>
            <ButtonRoundedGreen onClick={() => createDash()}>
              <span style={{ marginTop: '-1px' }}>+</span>
            </ButtonRoundedGreen>
          </Header>
        </Menu>
        <ListDash>
          {dashboards.map(dash => (
            <Item
              key={dash.id}
              dash={dash}
              idActive={idActive}
              onClick={() => setIdActive(dash.id)}
              onEdit={() => setEdit(true)}
              onDelete={() => deleteDashboard()}
            />
          ))}
        </ListDash>
      </SideBar>
      {isVisible && (
        <Navbar>
          <ListDashNative>
            {dashboards.map(dash => (
              <Item
                key={dash.id}
                dash={dash}
                idActive={idActive}
                onClick={() => setIdActive(dash.id)}
                onEdit={() => setEdit(true)}
                onDelete={() => deleteDashboard()}
              />
            ))}
          </ListDashNative>
        </Navbar>
      )}
    </>
  )
}
