import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { getOrganizationApi } from '@services/api'
import AreYouSure from '@components/modal/areyousure'
import useOrganization from '@hooks/useOrganization'
import { Nav, Menu, Title } from '../../styles'
import { ButtonRed } from '../../../../assets/styles/stylesButton'

function NavbarOrganizationConfig({
  organizationId,
  onDeleteOrganization,
}: {
  organizationId: number
  onDeleteOrganization?: () => void
}) {
  const [open, setOpen] = useState(false)
  const { addToast } = useToasts()

  const { organization } = useOrganization(organizationId)

  function onDelete() {
    getOrganizationApi()
      .organizationControllerDelete(organizationId)
      .then(() => {
        addToast(`Organização removida permanentemente`, {
          appearance: 'success',
          autoDismiss: true,
        })
        setOpen(false)
        if (onDeleteOrganization) onDeleteOrganization()
      })
      .catch(e => {
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <Nav>
      <Title>
        Configurações{organization ? ` de ${organization.name}` : ''}
      </Title>
      <Menu>
        {onDeleteOrganization && (
          <ButtonRed onClick={() => setOpen(true)}>
            Excluir organização
          </ButtonRed>
        )}
      </Menu>
      <AreYouSure
        title="Excluir organização"
        open={open}
        description="Deseja realmente excluir essa organização? Todos os dados vinculados serão excluídos. Isso é irreversível!"
        onConfirm={() => onDelete()}
        setOpen={setOpen}
      />
    </Nav>
  )
}

export default NavbarOrganizationConfig
