import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { getDeviceApi } from '@services/api'
import { Device } from '@services/openapi'
import { HeaderMap } from '@components/table/dataTable/Header'
import DeviceItem from '@components/table/device/item'
import InputSearch from '@components/Input/search'
import { Div } from './styles'
import DataTable from '../../table/dataTable'

export default function Index({
  addDevice,
  ofOrganization,
}: {
  addDevice: any
  ofOrganization?: number
}) {
  const apiDevice = getDeviceApi()

  const [devices, setDevices] = useState<Device[]>([])
  const [search, setSearch] = useState<string>('')

  const headers: HeaderMap<Device>[] = [
    {
      name: 'NOME',
      field: 'name',
      sortable: false,
    },
    {
      name: 'SITUAÇÃO',
      field: 'connectedDevice',
      sortable: true,
    },
    {
      name: 'VARIÁVEIS',
      field: 'variableCount',
      sortable: false,
    },
    {
      name: 'ÚLTIMA ATUALIZAÇÃO',
      field: 'updateAt',
      sortable: true,
    },
    {
      name: 'CRIADO EM',
      field: 'createdAt',
      sortable: true,
    },
  ]

  async function loadDevices() {
    await (ofOrganization
      ? apiDevice.deviceControllerGetAllByOrganizationAdmin(ofOrganization)
      : apiDevice.deviceControllerGetAllByUser()
    )
      .then(response => {
        setDevices(response.data.data)
      })
      .catch(error => {
        debug('api')(error)
        setDevices([])
      })
  }

  function deleteDevice(index: number) {
    devices.splice(index, 1)
    setDevices([...devices])
  }

  function updateDevice(index: number, data: Device) {
    const newDevices = [...devices]
    newDevices[index] = data
    setDevices(newDevices)
  }

  useEffect(() => {
    loadDevices()
  }, [])

  useEffect(() => {
    if (addDevice) {
      setDevices([...devices, addDevice])
    }
  }, [addDevice])

  return (
    <>
      <Div>
        <InputSearch
          placeholder="Pesquisar por nome…"
          onSearch={s => setSearch(s)}
        />
      </Div>
      {devices.length > 0 ? (
        <DataTable
          search={search}
          headers={headers}
          data={devices}
          onFilter={item =>
            item.name.toLowerCase().includes(search.toLowerCase())
          }
          render={item => (
            <DeviceItem
              device={item}
              onDelete={index => deleteDevice(index)}
              onUpdate={(index, data) => updateDevice(index, data)}
              onRefresh={() => loadDevices()}
            />
          )}
        />
      ) : (
        <>
          <div>Nenhum dispositivo na organização</div>
        </>
      )}
    </>
  )
}
