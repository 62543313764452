import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { getLoginApi, User } from '@services/api'
import useOrganizationStaffList from '@hooks/useOrganizationStaffList'
import { useAuth } from '@components/store/context'
import { HeaderMap } from '@components/table/dataTable/Header'
import MemberItem from '@components/table/members/item'
import InputSearch from '@components/Input/search'
import parseJwt from '@helpers/parseJwt'
import DataTable from '../../table/dataTable'
import { Div } from './styles'

function MembersTable({
  addMember,
  ofOrganization,
}: {
  addMember: any
  ofOrganization: number
}) {
  const apiLogin = getLoginApi()
  const { addToast } = useToasts()
  const history = useHistory()
  const [, setToken] = useAuth()

  const [search, setSearch] = useState('')

  const {
    staff: users,
    isError,
    mutate,
  } = useOrganizationStaffList(ofOrganization, false)

  useEffect(() => {
    debug('api')(isError)
  }, [isError])

  const headers: HeaderMap<User>[] = [
    {
      name: 'NOME',
      field: 'name',
      sortable: false,
    },
    {
      name: 'SITUAÇÃO',
      field: 'connectedDevice',
      sortable: true,
    },
    {
      name: 'EMAIL',
      field: 'variableCount',
      sortable: false,
    },
    {
      name: 'TIPO',
      field: 'updateAt',
      sortable: true,
    },
    {
      name: 'ÚLTIMO LOGIN',
      field: 'createdAt',
      sortable: true,
    },
    { name: 'CRIADO EM', field: 'createdAt', sortable: true },
  ]

  async function logingThisUser(email) {
    await apiLogin
      .loginControllerLoginAuthenticated({
        email,
        password: '999999999999',
        rememberMe: false,
      })
      .then(response => {
        if (response.data.success) {
          // TODO olhar esses caras
          setToken(parseJwt(response.data.data))
          localStorage.setItem('@token', response.data.data)
          history.push('/dashboard')
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        addToast('Credenciais inválidas', {
          appearance: 'error',
          autoDismiss: true,
        })
        debug('api')(error)
      })
  }

  useEffect(() => {
    mutate()
  }, [addMember])

  return (
    <>
      <Div>
        <InputSearch
          placeholder="Pesquisar por nome…"
          onSearch={s => setSearch(s)}
        />
      </Div>
      {users.length > 0 ? (
        <DataTable
          search={search}
          headers={headers}
          data={users}
          onFilter={item =>
            item.name.toLowerCase().includes(search.toLowerCase())
          }
          render={item => (
            <MemberItem
              user={item}
              onUpdateUser={() => mutate()}
              updateTable={() => mutate()}
              logingThisUser={e => logingThisUser(e)}
            />
          )}
        />
      ) : (
        <>
          <div>Nenhum membro na organização</div>
        </>
      )}
    </>
  )
}

export default MembersTable
