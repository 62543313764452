import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { getOrganizationApi, ApiResponse, User } from '@services/api'

function useOrganizationStaffList(
  organizationId?: number,
  withPermission?: boolean,
  config?: SWRConfigurationProps,
) {
  const fetch = config?.canFetch || (organizationId && organizationId > 0)

  const { data, error, mutate } = (
    config?.immutable === false ? useSWR : useSWRImmutable
  )<ApiResponse<User[]>>(
    fetch
      ? ['organizationControllerListStaff', organizationId, withPermission]
      : null,
    () =>
      getOrganizationApi()
        .organizationControllerListStaff(
          organizationId!,
          withPermission || false,
        )
        .then(response => response.data),
    config as SWRConfiguration,
  )

  return {
    staff: data?.data ?? [],
    response: data,
    isError: error,
    isLoading: fetch ? !error && !data : false,
    mutate,
  }
}

export default useOrganizationStaffList
