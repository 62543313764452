import React, { useEffect, useState } from 'react'
import debug from 'debug'
import { Tooltip } from '@material-ui/core'
import { Resizable } from 're-resizable'
import { BsPlusLg, BsDashLg } from 'react-icons/bs'

import SelectDevice from '@selects/select-devices'
import SelectVariables from '@selects/multi-select-variables'
import SelectDecimal from '@selects/select-decimals'
import SelectIcon from '@selects/select-icons'
import { GroupingFormulas } from '@components/dynamic/groupingformulas'
import Input from '@components/Input'
import { DivButtons } from '@selects/styles'
import { Widget, WidgetCustomData, WidgetTypeEnum } from '@services/openapi'
import useVariables from '@hooks/useVariables'
import usePrevious from '@hooks/usePrevious'
import Preview from '@components/widgets/preview'
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  ButtonWhite,
  ButtonGreen,
  ButtonRed,
} from '../../../../assets/styles/stylesButton'
import {
  ContainerForm,
  Graphics,
  Form,
  Menu,
  Span,
  DivInput,
  Personalization,
  DivUnits,
  PersonalizationHeaders,
  FooterForm,
  TabContent,
  Mandatory,
  DivMultiBaseline,
  DivColumn,
  Label,
} from '../styles'
import { Card } from './styles'

// import calculate from '@utils/formulas'

interface TabManager {
  add(id: string, label: string, content: () => JSX.Element)
  update(
    id: string | string[],
    {
      label,
      content,
      visible,
      disable,
    }: {
      label?: string
      content?: () => JSX.Element
      visible?: boolean
      disable?: string
    },
  )
  remove(id: string)
}

export default function Index({
  type,
  widget,
  closeModal,
  idDash,
  onSave,
  tabs,
  onChangeVariavel,
  onChangeDevice,
}: {
  type: WidgetTypeEnum
  widget?: Widget
  closeModal
  idDash: number
  onSave: (data: any, customData: WidgetCustomData) => void
  tabs: (tabs: TabManager) => void
  onChangeVariavel?: any
  onChangeDevice?: any
}) {
  const [active, setActive] = useState('data')
  const [device, setDevice] = useState<number>(
    (widget && widget.device ? widget.device.id || widget.device : 0) as number,
  )
  const prevDevice = usePrevious<number>(device)
  const [variables, setVariables] = useState(
    widget && widget.variables ? widget.variables : [],
  )

  const [title, setTitle] = useState(widget && widget.title ? widget.title : '')
  const [showUnits, setShowUnits] = useState(
    widget &&
      widget.customData &&
      widget.customData.units &&
      widget.customData.units.showUnits
      ? widget.customData.units.showUnits
      : false,
  )
  const [units, setUnits] = useState(
    widget &&
      widget.customData &&
      widget.customData.units &&
      widget.customData.units.valueUnits
      ? widget.customData.units.valueUnits
      : '',
  )
  const [decimalPlaces, setDecimalPlaces] = useState(
    widget &&
      widget.customData &&
      widget.customData.units &&
      widget.customData.units.decimalPlaces
      ? widget.customData.units.decimalPlaces
      : 1,
  )
  const [showIcon, setShowIcon] = useState(
    widget &&
      widget.customData &&
      widget.customData.icon &&
      widget.customData.icon.showIcon
      ? widget.customData.icon.showIcon
      : false,
  )
  const [iconGraphic, setIconGraphic] = useState(
    widget &&
      widget.customData &&
      widget.customData.icon &&
      widget.customData.icon.icon
      ? widget.customData.icon.icon
      : undefined,
  )
  const [internalTabs, setInternalTabs] = useState({
    data: {
      label: 'Dados',
    },
    personalization: {
      label: 'Personalização',
    },
    notification: {
      label: 'Notificação',
    },
  })
  const [notification, setNotification] = useState<any>(
    widget && widget.customData && widget.customData.notification
      ? widget.customData.notification
      : undefined,
  )

  const tabManager: TabManager = {
    add(id, label, content) {
      setInternalTabs({ ...internalTabs, [id]: { label, content } })
    },
    update(id, { label, content, visible, disable }) {
      const ids: string[] = typeof id === 'string' ? [id] : id

      ids.map(i => {
        if (typeof content !== 'undefined') {
          internalTabs[i].content = content
        }
        if (typeof label !== 'undefined') {
          internalTabs[i].label = label
        }
        if (typeof visible !== 'undefined') {
          internalTabs[i].visible = visible === true
        }
        if (typeof disable !== 'undefined') {
          internalTabs[i].disable = disable
        }
        return internalTabs
      })

      setInternalTabs({ ...internalTabs })
    },
    remove(id) {
      delete internalTabs[id]
      setInternalTabs({ ...internalTabs })
    },
  }

  useEffect(() => {
    if (device > 0) {
      tabManager.update(['personalization', 'notification'], {
        disable: '',
      })
    } else {
      tabManager.update(['personalization', 'notification'], {
        disable: 'Escolha o Dispositivo em Dados para acessar',
      })
    }
    if (onChangeDevice && prevDevice !== device) {
      onChangeDevice(device)
    }
  }, [device])

  useEffect(() => {
    if (tabs) {
      tabs(tabManager)
    }
  }, [])

  function showUnitsDiv() {
    setShowUnits(!showUnits)
    setUnits('')
    setDecimalPlaces(0)
  }

  function showIconDiv() {
    setShowIcon(!showIcon)
    setIconGraphic('')
  }

  const { variables: allVariables, isError } = useVariables(device)

  useEffect(() => {
    debug('api')(isError)
  }, [isError])

  const handleAddFields = event => {
    event.preventDefault()
    event.stopPropagation()
    notification.push({ value: '', notify: ['email'], message: '' })
    setNotification([...notification])
  }

  const handleRemoveFields = (event, indexParams) => {
    event.preventDefault()
    event.stopPropagation()
    notification.splice(indexParams, 1)
    setNotification([...notification])
  }

  const handleInputChange = (indexParams, event, isExpression = false) => {
    const values: any = [...notification]
    if (isExpression) {
      values[indexParams].expression = event
    }

    setNotification(values)
  }

  const changeNotificationMessage = (indexParams, value) => {
    const values: any = [...notification]
    values[indexParams].message = value
    setNotification(values)
  }

  useEffect(() => {
    if (onChangeVariavel) onChangeVariavel(variables)
  }, [variables])

  useEffect(() => {
    if (!notification) {
      setNotification([{ value: '', notify: ['email'], message: '' }])
    }
  }, [])

  async function onSubmit(e) {
    e.preventDefault()

    const customData: WidgetCustomData = {
      icon: {
        showIcon,
        icon: iconGraphic,
      },
      units: {
        showUnits,
        decimalPlaces,
        valueUnits: units,
      },
      notification,
    }

    const data = {
      title,
      type,
      customData,
      dashboardId: idDash,
      variables,
      deviceId: device,
    }

    onSave(data, customData)
  }

  const [width, setWidth] = useState(250)
  const [widthCard, setWidthCard] = useState(width || 180)

  useEffect(() => {
    setWidth((100 * widthCard) / 840)
  }, [widthCard, title, units, decimalPlaces, iconGraphic])

  return (
    <>
      <ContainerForm>
        <span>Pré-visualização</span>
        <Graphics>
          <Card id="container">
            <Resizable
              className="resizeContainer"
              {...{ width: widthCard }}
              onResizeStop={(e, direction, ref, d) => {
                setWidthCard(widthCard + d.width)
              }}
              minHeight={125}
              maxHeight={125}
              maxWidth={840}
              minWidth={240}
            >
              <Preview
                widget={
                  {
                    title: title || 'Título',
                    type,
                    device: {
                      id: device,
                    } as any,
                    disabled: false,
                    dashboard: {
                      id: idDash,
                    } as any,
                    variables,
                    isAllowed: true,
                    customData: {
                      icon: {
                        showIcon,
                        icon: iconGraphic,
                      },
                      units: {
                        showUnits,
                        decimalPlaces,
                        valueUnits: units,
                      },
                      notification,
                    },
                  } as any
                }
                edit={false}
              />
            </Resizable>
          </Card>
        </Graphics>
      </ContainerForm>
      <Form onSubmit={e => onSubmit(e)}>
        <Menu>
          {Object.keys(internalTabs).map(index => {
            if (internalTabs[index].visible == false) return null

            if (
              internalTabs[index].disable &&
              internalTabs[index].disable.length > 0
            ) {
              return (
                <Tooltip title={internalTabs[index].disable}>
                  <Span key={`${index}`}>{internalTabs[index].label}</Span>
                </Tooltip>
              )
            }

            return (
              <Span
                key={`${index}`}
                active={active == index}
                onClick={() => setActive(index)}
              >
                {internalTabs[index].label}
              </Span>
            )
          })}
        </Menu>
        {Object.keys(internalTabs).map(index => {
          const tab = internalTabs[index]

          if (tab.visible == false) return <></>

          return (
            <>
              {index == 'data' && (
                <TabContent key={`${index}`} displayTab={active === index}>
                  <DivInput>
                    <label htmlFor="fname">
                      Título<Mandatory>*</Mandatory>
                    </label>
                    <input
                      value={title}
                      id="fname"
                      required
                      type="text"
                      placeholder="Digite uma descrição..."
                      onChange={e => setTitle(e.target.value)}
                    />
                  </DivInput>
                  <SelectDevice device={device} setDevice={setDevice} />
                  <SelectVariables
                    required
                    idDevice={device}
                    variable={variables}
                    setVariable={setVariables}
                    type={type}
                  />
                  {tab.content && tab.content()}
                </TabContent>
              )}
              {index === 'personalization' && (
                <TabContent displayTab={active === index}>
                  <Personalization>
                    <DivUnits>
                      <PersonalizationHeaders>
                        <span>Mostrar unidade</span>
                        <CheckBoxWrapper>
                          <CheckBox
                            onClick={() => showUnitsDiv()}
                            id="checkbox-units"
                            type="checkbox"
                            defaultChecked={showUnits}
                          />
                          <CheckBoxLabel htmlFor="checkbox-units" />
                        </CheckBoxWrapper>
                      </PersonalizationHeaders>
                      {showUnits === true ? (
                        <div style={{ display: 'inline-flex' }}>
                          <DivInput style={{ marginRight: '5px' }}>
                            <Label htmlFor="unit">Unidades</Label>
                            <input
                              id="unit"
                              value={units}
                              required
                              type="text"
                              placeholder="Digite a unidade"
                              onChange={e => setUnits(e.target.value)}
                            />
                          </DivInput>
                          <DivInput style={{ flex: 1, marginLeft: '5px' }}>
                            <SelectDecimal
                              decimalPlaces={decimalPlaces}
                              setDecimalPlaces={setDecimalPlaces}
                            />
                          </DivInput>
                        </div>
                      ) : null}
                    </DivUnits>
                    {type && type !== 'METER' && type !== 'CYLINDER' ? (
                      <DivUnits>
                        <PersonalizationHeaders>
                          <span>Mostrar ícone</span>
                          <CheckBoxWrapper>
                            <CheckBox
                              onClick={() => showIconDiv()}
                              id="checkbox-icons"
                              type="checkbox"
                              defaultChecked={showIcon}
                            />
                            <CheckBoxLabel htmlFor="checkbox-icons" />
                          </CheckBoxWrapper>
                        </PersonalizationHeaders>
                        {showIcon === true ? (
                          <SelectIcon
                            iconGraphic={iconGraphic}
                            setIconGraphic={setIconGraphic}
                          />
                        ) : null}
                      </DivUnits>
                    ) : (
                      false
                    )}
                  </Personalization>
                  {tab.content && tab.content()}
                </TabContent>
              )}
              {index === 'notification' && (
                <TabContent displayTab={active === index}>
                  {notification?.map((item, indexInput) => (
                    <div style={{ margin: '20px 0px' }}>
                      <DivMultiBaseline style={{ alignItems: 'flex-start' }}>
                        <DivColumn style={{ width: '-webkit-fill-available ' }}>
                          <label
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '14px',
                              color: '#495057',
                              marginBottom: '1%',
                            }}
                          >
                            Se...
                          </label>

                          <GroupingFormulas
                            data={allVariables.map(variable => variable.tag)}
                            onChange={e =>
                              handleInputChange(indexInput, e, true)
                            }
                            defaultValue={notification[indexInput].expression}
                          />

                          <Input
                            name="notification-message"
                            onChange={e =>
                              changeNotificationMessage(
                                indexInput,
                                e.target.value,
                              )
                            }
                            value={notification[indexInput].message}
                            label="então enviar a mensagem..."
                            placeholder="mensagem da notificação"
                          />
                        </DivColumn>
                        <DivButtons>
                          {notification.length > 1 && (
                            <ButtonRed
                              onClick={event =>
                                handleRemoveFields(event, indexInput)
                              }
                            >
                              <BsDashLg />
                            </ButtonRed>
                          )}
                        </DivButtons>
                      </DivMultiBaseline>
                    </div>
                  ))}
                  {notification?.length <= 5 && (
                    <ButtonGreen
                      style={{ marginTop: '10px', width: '200px' }}
                      onClick={event => handleAddFields(event)}
                    >
                      <BsPlusLg style={{ marginRight: '10px' }} />
                      Adicionar notificação
                    </ButtonGreen>
                  )}
                </TabContent>
              )}
              {!(
                index === 'data' ||
                index === 'personalization' ||
                index === 'notification'
              ) && (
                <TabContent displayTab={active === index}>
                  {tab.content && tab.content()}
                </TabContent>
              )}
            </>
          )
        })}
        <FooterForm>
          <ButtonWhite onClick={closeModal}>Cancelar</ButtonWhite>
          <ButtonGreen
            type="submit"
            disabled={variables.filter(d => d > 0).length === 0}
          >
            {widget && widget.id ? 'Atualizar' : 'Adicionar'}
          </ButtonGreen>
        </FooterForm>
      </Form>
    </>
  )
}
