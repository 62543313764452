import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import CreateUserOrganization from '@modals/organization/createuser'
import {
  getOrganizationApi,
  User,
  CreateUserOrganizationBody,
} from '@services/api'
import useOrganization from '@hooks/useOrganization'
import { Nav, Menu, Title } from '../../styles'
import { ButtonGreen } from '../../../../assets/styles/stylesButton'

export default function Index({
  organizationId,
  onAddMember,
}: {
  organizationId?: number
  onAddMember?: (user: User) => void
}) {
  const [open, setOpen] = useState(false)
  const { addToast } = useToasts()

  const { organization } = useOrganization(organizationId)

  function onCreateUser(eventForm) {
    const formData = eventForm

    const userEvent: CreateUserOrganizationBody = {
      name: formData.target.name.value,
      email: formData.target.email.value,
      password: formData.target.password.value,
      organizationId: Number(organizationId),
      type: formData.target.typeUser.value,
      iAgree: true,
    }

    getOrganizationApi()
      .organizationControllerCreateStaff({ ...userEvent })
      .then(response => {
        addToast(`${formData.target.typeUser.value} foi criado`, {
          appearance: 'success',
          autoDismiss: true,
        })
        setOpen(false)
        if (onAddMember) onAddMember(response.data.data as unknown as User)
      })
      .catch(e => {
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <Nav>
      <Title>Membros{organization ? ` de ${organization.name}` : ''}</Title>
      <Menu>
        {onAddMember && (
          <ButtonGreen onClick={() => setOpen(true)}>+ Novo membro</ButtonGreen>
        )}
      </Menu>
      <CreateUserOrganization
        open={open}
        setOpen={setOpen}
        onAddMember={e => onCreateUser(e)}
      />
    </Nav>
  )
}
