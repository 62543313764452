import { useAuth } from '@components/store/context'
import { UserTypeEnum } from '@services/openapi'
import React from 'react'
import AdminMenu from './admin'
import OrganizationMenu from './organization'
import StaffMenu from './staff'

const DefaultMenu: React.FC = () => {
  const [user] = useAuth()
  return (
    <>
      {user.type === UserTypeEnum.Admin && <AdminMenu />}
      {user.type === UserTypeEnum.OrganizationAdmin && <OrganizationMenu />}
      {user.type === UserTypeEnum.Staff && <StaffMenu />}
    </>
  )
}

export default DefaultMenu
