import styled from 'styled-components'

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 500px;
`

interface ITypeUser {
  dropError?: boolean
}
export const TypeUser = styled.div<ITypeUser>`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  height: 30px;
  border: 1px solid
    ${({ dropError, theme }) => (dropError ? 'red' : theme.backgroundSecondary)};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  border-radius: 4px;
  position: relative;
  align-items: left;
  margin-top: 10px;
  padding: 5px 10px;
  justify-content: center;

  section {
    color: ${props => props.theme.title};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const Select = styled.div<{
  active?: boolean
}>`
  background-color: ${props => props.theme.backgroundPrimary};
  z-index: 13;
  width: -webkit-fill-available;
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-size: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  text-align: center;
  position: absolute;
  border-radius: 3px;
  left: 0px;
  top: 44px;

  span {
    text-align: left;
    font-size: 0.9rem;
    font-family: Inter;
    padding: 7px 10px;
    cursor: pointer;
    &:hover {
      border-left: 2px solid #00a65f;
      background: ${props => props.theme.modalHover};
    }
  }
`
export const Title = styled.h1`
  color: ${props => props.theme.title};
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  margin: 14px 0px 0px;
  font-size: 1rem;
`
