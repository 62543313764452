import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { InputDiv } from './styles'

let searchTimeout

interface InputSearchProps {
  placeholder?: string
  value?: string
  onSearch: (search: string) => void
  style?: any
}

const InputSearch: React.FC<InputSearchProps> = ({
  placeholder,
  value,
  onSearch,
  style,
}) => {
  const [search, setSearch] = useState<string | undefined>(value)

  const searchNow = (s: string) => {
    if (searchTimeout) clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      onSearch(s)
      clearTimeout(searchTimeout)
      searchTimeout = null
    }, 500)
  }

  return (
    <InputDiv style={style}>
      <BsSearch />
      <input
        type="search"
        placeholder={placeholder ?? 'Pesquisar…'}
        onChange={e => {
          setSearch(e.target.value)
          searchNow(e.target.value)
        }}
        value={search}
      />
    </InputDiv>
  )
}

export default InputSearch
