import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { useToasts } from 'react-toast-notifications'
import { useTheme } from 'styled-components'
import Logo from '@assets/logo-default.png'
import LogoDark from '@assets/logo-theme-dark.png'
import { getUserApi } from '@services/api'
import {
  Main,
  IntroDiv,
  ImgLogo,
  ImgGraphic,
  FormCard,
  TableDiv,
  Div,
  FirstText,
  SecondText,
  Form,
  InputDiv,
  Label,
  Button,
  LinkLogin,
} from './styles'
import Graphics from '../../../assets/img/graphics.png'

export default function Index() {
  const { userId } = useParams()
  const { addToast } = useToasts()
  const [tokenForm, setTokenForm] = useState('')
  const history = useHistory()
  const theme: any = useTheme()

  function getLogoImg() {
    const src = {
      src: Logo,
    }
    if (theme.themeName == 'darkTheme') src.src = LogoDark
    return src
  }

  const apiUser = getUserApi()

  function onSubmit(e) {
    e.preventDefault()

    apiUser.userControllerActivate(userId, tokenForm).then(response => {
      if (response.data.success) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        history.push('/')
      } else {
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    })
  }

  return (
    <Main>
      <IntroDiv>
        <ImgLogo {...getLogoImg()} alt="Logo interlock" />
        <h1>Lorem ipsum dolor sit</h1>
        <p>
          Lorem ipsum dolor sit amet. Qui tempora enim est sint aperiam sit
          itaque est quas voluptatibus et eveniet necessitatibus At culpa
          repellendus.
        </p>
        <ImgGraphic src={Graphics} alt="pessoas mexendo em um grafico" />
      </IntroDiv>
      <FormCard>
        <TableDiv onSubmit={e => onSubmit(e)}>
          <Div>
            <ImgLogo {...getLogoImg()} alt="Logo interlock" />
            <FirstText>Insira o Token</FirstText>
            <SecondText>
              Verifique sua caixa de e-mail para ativar a sua conta
            </SecondText>
            <Form>
              <Label>Token</Label>
              <InputDiv>
                <AiOutlineMail size="20px" />
                <input
                  required
                  placeholder="Token"
                  value={tokenForm}
                  onChange={e => setTokenForm(e.target.value)}
                />
              </InputDiv>
            </Form>
            <LinkLogin>
              <Link to="/">Fazer Login</Link>
            </LinkLogin>
            <Button>
              <span>
                <AiOutlineMail size="20px" />
              </span>
              Ativar conta
            </Button>
          </Div>
        </TableDiv>
      </FormCard>
    </Main>
  )
}
