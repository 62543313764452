import React, { useState } from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { BsTrash } from 'react-icons/bs'
import { FiRefreshCcw } from 'react-icons/fi'

import { getVariableApi, Variable } from '@services/api'
import { DivVariable, DivFilter } from '@modals/device/update/styles'
import InputSearch from '@components/Input/search'
import { ButtonBoxWhite } from '@assets/styles/stylesButton'
import DataTable from '../dataTable'
import VariableItem from './item'

interface VariableTabProps {
  data: Variable[]
  onRequestRefreshDevice: () => void
}

const VariableTab: React.FC<VariableTabProps> = ({
  data,
  onRequestRefreshDevice,
}) => {
  const apiVariable = getVariableApi()
  const { addToast } = useToasts()

  const [search, setSearch] = useState('')
  const [ids, setIds] = useState<number[]>([])

  const headers = [
    { name: '', field: 'id', sortable: false },
    { name: 'VARIÁVEL', field: 'name', sortable: false },
    { name: 'NÚM. REGISTROS', field: 'variable', sortable: false },
    { name: 'ARMAZENAR DADOS?', field: 'storeData', sortable: false },
  ]

  function setIdsArray(id) {
    const exist = ids.indexOf(id) > -1
    if (exist) {
      setIds(ids.filter(item => item !== id))
    } else {
      setIds([...ids, id])
    }
  }

  async function disabled() {
    await apiVariable
      .variableControllerDisableLog(ids.join(','))
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          onRequestRefreshDevice()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  return (
    <>
      <DivVariable>
        <InputSearch
          style={{ width: '100%' }}
          placeholder="Pesquisar por nome…"
          onSearch={s => setSearch(s)}
        />
        <DivFilter>
          <ButtonBoxWhite onClick={() => onRequestRefreshDevice()}>
            <FiRefreshCcw />
          </ButtonBoxWhite>
          <ButtonBoxWhite
            disabled={ids.length === 0}
            onClick={() => disabled()}
          >
            <BsTrash />
          </ButtonBoxWhite>
        </DivFilter>
      </DivVariable>
      {data.length > 0 && (
        <DataTable
          search={search}
          headers={headers}
          data={data}
          onFilter={item =>
            item.tag.toLowerCase().includes(search.toLowerCase())
          }
          render={item => (
            <VariableItem
              variable={item}
              onToggleCheck={() => setIdsArray(item.id)}
            />
          )}
        />
      )}
    </>
  )
}

export default VariableTab
