import { compareNatural } from 'mathjs'

const calculateText = (formula: string) => {
  const [first, second] = formula.split('==')
  try {
    return compareNatural(first.trim(), second.trim()) === 0
  } catch (e) {
    return false
  }
}

export default calculateText
