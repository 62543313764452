/* eslint-disable react/no-array-index-key */
import React from 'react'
import { HeaderMap } from '../Header'

interface BodyProps<T> {
  data: T[]
  headers: HeaderMap<T>[]
  render?: (item: T, index: number) => React.ReactNode | React.ReactElement
}

function Body<T>({ data, headers, render }: BodyProps<T>) {
  return (
    <tbody>
      {data.map((item, index) =>
        render
          ? render(item, index)
          : headers.map(header => header.render!(item, index, header)),
      )}
    </tbody>
  )
}

export default Body
