import styled from 'styled-components'
import { InputWithIcon } from '../styles'

export const InputDiv = styled(InputWithIcon)`
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  width: 300px;

  svg {
    position: absolute;
    pointer-events: none;
    padding: 10px;
    margin-right: -10px;
  }

  input {
    padding: 10px 10px 10px 35px;
  }
`
