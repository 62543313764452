import React, { useEffect, useState } from 'react'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import { PaginationStyle, Arrow } from './styles'

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}) => {
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage))
  }, [total, itemsPerPage])

  if (totalPages === 0) return null

  return (
    <>
      {totalPages === 1 ? (
        <PaginationStyle>
          <Arrow active>{currentPage}</Arrow>
        </PaginationStyle>
      ) : (
        <PaginationStyle>
          <Arrow
            active={currentPage === 1}
            key="FirstPage"
            onClick={() => onPageChange(1)}
          >
            {1}
          </Arrow>
          <Arrow
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage == 1}
          >
            <BsChevronDoubleLeft />
          </Arrow>
          <Arrow active>{currentPage}</Arrow>
          <Arrow
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage == totalPages}
          >
            <BsChevronDoubleRight />
          </Arrow>
          <Arrow
            active={totalPages === currentPage}
            key="LastPage"
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </Arrow>
        </PaginationStyle>
      )}
    </>
  )
}

export default PaginationComponent
