import styled from 'styled-components'

export const PaginationStyle = styled.div<{
  sortable?: boolean
}>`
  margin-top: 2%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : '')};
`

export const Arrow = styled.button<{
  active?: boolean
}>`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  color: ${({ active, theme }) => (active ? '#00a65f' : theme.title)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  background: ${props => props.theme.backgroundPrimary};
  border-radius: 6px;
  border: none;
  &:hover {
    background: rgba(200, 200, 200, 0.24);
  }
`
