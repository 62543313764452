import React, { useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsKey, BsEye, BsEyeSlash } from 'react-icons/bs'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Logo from '@assets/logo-default.png'
import LogoDark from '@assets/logo-theme-dark.png'
import { useTheme } from 'styled-components'
import { getLoginApi } from '@services/api'
import {
  Main,
  IntroDiv,
  ImgLogo,
  ImgGraphic,
  FormCard,
  TableDiv,
  Div,
  FirstText,
  Form,
  InputDivPass,
  Label,
  Span,
  ButtonLoanding,
  Button,
  LinkLogin,
} from './styles'
import Graphics from '../../../assets/img/graphics.png'

export default function Index() {
  const { addToast } = useToasts()
  const { token, userId } = useParams()
  const history = useHistory()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showPasswordInputOne, setShowPasswordInputOne] = useState(false)
  const [showPasswordInputTwo, setShowPasswordInputTwo] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const theme: any = useTheme()

  function getLogoImg() {
    const src = {
      src: Logo,
    }
    if (theme.themeName == 'darkTheme') src.src = LogoDark
    return src
  }

  const apiLogin = getLoginApi()

  function onSubmit(e) {
    e.preventDefault()
    setButtonDisabled(true)

    const form = { token, userId: Number(userId), newPassword, confirmPassword }
    if (newPassword.length < 8 || confirmPassword.length < 8) {
      addToast('Senha curta', {
        appearance: 'error',
        autoDismiss: true,
      })
      setButtonDisabled(false)
      return null
    }
    if (newPassword !== confirmPassword) {
      addToast('As senhas não são iguais', {
        appearance: 'error',
        autoDismiss: true,
      })
      setButtonDisabled(false)
      return null
    }
    apiLogin
      .loginControllerResetPassword(form)
      .then(response => {
        if (response.data.success) {
          addToast('Senha redefinida com sucesso!', {
            appearance: 'success',
            autoDismiss: true,
          })
          history.push('/')
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        setButtonDisabled(false)
      })
      .catch(error => {
        if (error.response.data.message === 'TOKEN_INVALID') {
          addToast('Token expirado. Recomece o processo', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        if (error.response.data.message === 'TOKEN_USED') {
          addToast('Token já usado para redefinir a senha', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        if (error.response.data.message === 'INCOMPATIBLE_PASSWORDS') {
          addToast('As senhas não coincidem', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
    return null
  }

  return (
    <Main>
      <IntroDiv>
        <ImgLogo {...getLogoImg()} alt="Logo interlock" />
        <h1>Lorem ipsum dolor sit</h1>
        <p>
          Lorem ipsum dolor sit amet. Qui tempora enim est sint aperiam sit
          itaque est quas voluptatibus et eveniet necessitatibus At culpa
          repellendus.
        </p>
        <ImgGraphic src={Graphics} alt="pessoas mexendo em um grafico" />
      </IntroDiv>
      <FormCard>
        <TableDiv onSubmit={e => onSubmit(e)}>
          <Div>
            <ImgLogo {...getLogoImg()} alt="Logo interlock" />
            <FirstText>Redefinição de senha</FirstText>
            <Form>
              <Label>Nova senha</Label>
              <InputDivPass>
                <BsKey size="20px" />
                {showPasswordInputOne ? (
                  <>
                    <input
                      value={newPassword}
                      type="text"
                      placeholder="Senha"
                      required
                      onChange={e => setNewPassword(e.target.value)}
                      min="8"
                    />
                    <BsEye
                      size="24px"
                      onClick={() =>
                        setShowPasswordInputOne(!showPasswordInputOne)
                      }
                    />
                  </>
                ) : (
                  <>
                    <input
                      value={newPassword}
                      type="password"
                      placeholder="Senha"
                      required
                      onChange={e => setNewPassword(e.target.value)}
                      min="8"
                    />
                    <BsEyeSlash
                      size="24px"
                      onClick={() =>
                        setShowPasswordInputOne(!showPasswordInputOne)
                      }
                    />
                  </>
                )}
              </InputDivPass>
              <Span>Deve ter no mínimo 8 caracteres</Span>
              <Label>Confirmação de senha</Label>
              <InputDivPass>
                <BsKey size="20px" />
                {showPasswordInputTwo ? (
                  <>
                    <input
                      value={confirmPassword}
                      type="text"
                      placeholder="Senha"
                      required
                      onChange={e => setConfirmPassword(e.target.value)}
                      min="8"
                    />
                    <BsEye
                      size="24px"
                      onClick={() =>
                        setShowPasswordInputTwo(!showPasswordInputTwo)
                      }
                    />
                  </>
                ) : (
                  <>
                    <input
                      value={confirmPassword}
                      type="password"
                      placeholder="Senha"
                      required
                      onChange={e => setConfirmPassword(e.target.value)}
                      min="8"
                    />
                    <BsEyeSlash
                      size="24px"
                      onClick={() =>
                        setShowPasswordInputTwo(!showPasswordInputTwo)
                      }
                    />
                  </>
                )}
              </InputDivPass>
            </Form>
            <Span>Deve ter no mínimo 8 caracteres</Span>
            <LinkLogin>
              <Link to="/">Fazer Login</Link>
            </LinkLogin>
            {buttonDisabled ? (
              <ButtonLoanding type="submit">
                <span>
                  <AiOutlineMail size="20px" />
                </span>
                Carregando
              </ButtonLoanding>
            ) : (
              <Button type="submit">
                <span>
                  <AiOutlineMail size="20px" />
                </span>
                Entrar
              </Button>
            )}
          </Div>
        </TableDiv>
      </FormCard>
    </Main>
  )
}
