import React from 'react'
import { Div, Label, Select } from './styles'

export default function index({ decimalPlaces, setDecimalPlaces }) {
  async function handleSubmit(unit) {
    setDecimalPlaces(unit)
  }

  return (
    <Div>
      <Label htmlFor="select-decimal">Casas decimais</Label>
      <Select
        id="select-decimal"
        className={`form-control ${decimalPlaces ? 'not-empty' : 'empty'}`}
        value={decimalPlaces}
        onChange={event => handleSubmit(event.target.value)}
      >
        <option value="">Selecione...</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </Select>
    </Div>
  )
}
