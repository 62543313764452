import styled from 'styled-components'

export const Card = styled.div`
  margin-top: 2%;
  background: ${props => props.theme.backgroundPrimary};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.07);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .resizeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      z-index: 22222;
    }
  }
`
