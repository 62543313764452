import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from 'styled-components'
import { ApexOptions } from 'apexcharts'

import { Log, Widget } from '@services/openapi'
import Dropdown from '@components/dropdown'
import useLastLog from '@hooks/useLastLog'
import { convertDateInString } from '@utils/date-graphic'
import { Card, Title, DivTitle } from './styles'

export default function Index({
  title,
  widget,
  edit,
  refresh,
  time,
  onClone,
  setModalVisibleDelete,
  setModalVisibleUpdate,
  setWidget,
}: {
  title: string
  widget: Widget
  edit: boolean
  refresh?: number
  time: number
  onClone?: (widget: Widget) => void
  setModalVisibleDelete?: (enable: boolean) => void
  setModalVisibleUpdate?: (enable: boolean) => void
  setWidget?: (widget: Widget) => void
}) {
  const [chartSeries, setChartSeries] = useState<any>([
    {
      name: '',
      data: [],
    },
  ])
  const [labels, setLabels] = useState<any[]>([])
  const [logs, setLogs] = useState<Log[]>()
  const [lastModified, setLastModified] = useState<string>()
  const theme = useTheme()
  const chartRef = React.useRef(null)

  const { data, lastModifiedTimestamp, error, refreshNow } = useLastLog(
    widget.variables,
    logs,
    lastModified,
    time * 1000,
  )

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      width: 100,
      animations: {
        enabled: false,
        easing: 'linear',
        dynamicAnimation: {
          enabled: false,
          speed: 1000,
        },
      },
    },
    colors: [
      theme.chart1,
      theme.chart2,
      theme.chart3,
      theme.chart4,
      theme.chart5,
    ],
    legend: {
      labels: {
        colors: theme.title,
      },
    },
    noData: {
      text: 'Carregando...',
      style: {
        color: theme.title,
      },
    },
    stroke: {
      curve: 'straight',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: theme.title,
        },
      },
    },
    yaxis: [
      {
        labels: {
          formatter(val) {
            return val.toFixed(2)
          },
          style: {
            colors: theme.title,
          },
        },
      },
    ],
    markers: {
      hover: {
        sizeOffset: 4,
      },
    },
  }

  async function setDatas(array) {
    await array.map((e, index) => {
      if (!chartSeries[index]) {
        chartSeries[index] = e
      } else {
        chartSeries[index] = {
          name: e.name,
          data: e.data,
        }
      }
      return e
    })

    await setChartSeries([...chartSeries])
  }

  async function update() {
    const label: string[] = []
    const datas = logs!.map(log => {
      label.push(convertDateInString(log.date))
      return {
        name: log.tag,
        data: [log.value ?? 0],
      }
    })

    await setDatas(datas)
    await setLabels(label)
  }

  useEffect(() => {
    if (logs) {
      update()
    }
  }, [logs])

  useEffect(() => {
    if (data && chartRef.current !== null) {
      if (data.constructor === Array) {
        setLogs(data as Log[])
      } else {
        setLogs([data as Log])
      }
    }
    setLastModified(lastModifiedTimestamp || lastModified)
  }, [data, error])

  useEffect(() => refreshNow(), [refresh])

  return (
    <>
      <Card id="container">
        <DivTitle>
          <Title>{title}</Title>
          {edit && (
            <Dropdown
              items={[
                [
                  'Editar',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleUpdate) setModalVisibleUpdate(true)
                  },
                ],
                ['Duplicar', () => onClone && onClone(widget)],
                [
                  'Excluir',
                  () => {
                    if (setWidget) setWidget(widget)
                    if (setModalVisibleDelete) setModalVisibleDelete(true)
                  },
                ],
              ]}
            />
          )}
        </DivTitle>
        <Chart
          ref={chartRef}
          key="charts"
          options={{
            ...options,
            chart: {
              ...options.chart,
              id: `chart`,
            },
            xaxis: {
              categories:
                labels.length > 0
                  ? labels
                  : [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
              ...options.xaxis,
            },
          }}
          series={chartSeries}
          type="bar"
          width="100%"
        />
      </Card>
    </>
  )
}
