import React, { useState, useEffect, useMemo } from 'react'
import Base from './base'
import { Personalization, DivUnits, DivInput, Label } from '../styles'
import ConditionalsSection from './conditionals'

const AdditionalPersonalization = ({
  defaultMaximumValue,
  defaultMinimumValue,
  setMinimumValue,
  setMaximumValue,
}) => {
  const [internalMinimumValue, setInternalMinimumValue] =
    useState(defaultMinimumValue)
  const [internalMaximumValue, setInternalMaximumValue] =
    useState(defaultMaximumValue)

  useEffect(() => {
    setMinimumValue(internalMinimumValue)
    setMaximumValue(internalMaximumValue)
  }, [internalMaximumValue, internalMinimumValue])

  return (
    <Personalization>
      <DivUnits>
        <DivInput>
          <Label>Valor mínimo</Label>
          <input
            value={internalMinimumValue}
            type="number"
            placeholder="0"
            onChange={e => setInternalMinimumValue(e.target.value)}
          />
        </DivInput>
      </DivUnits>
      <DivUnits>
        <DivInput>
          <label htmlFor="fname">Valor máximo</label>
          <input
            value={internalMaximumValue}
            id="fname"
            type="number"
            placeholder="100"
            onChange={e => setInternalMaximumValue(e.target.value)}
          />
        </DivInput>
      </DivUnits>
    </Personalization>
  )
}

export default function Index({
  onSave: onSubmit,
  ...props
}: {
  type
  onSave
  closeModal
  idDash
  widget?
}) {
  const [tabManager, setTabManager] = useState<any>()
  const [device, setDevice] = useState<number>()

  const [minimumValue, setMinimumValue] = useState(
    props.widget &&
      props.widget.customData &&
      props.widget.customData.range &&
      props.widget.customData.range.minimumValue
      ? props.widget.customData.range.minimumValue
      : undefined,
  )
  const [maximumValue, setMaximumValue] = useState(
    props.widget &&
      props.widget.customData &&
      props.widget.customData.range &&
      props.widget.customData.range.maximumValue
      ? props.widget.customData.range.maximumValue
      : undefined,
  )
  const [map, setMap] = useState<any>(
    (props.widget && props.widget.customData
      ? props.widget.customData.map
      : [{ value: '', label: '', action: '', expression: '' }]) || [
      { value: '', label: '', action: '', expression: '' },
    ],
  )

  const onChangeConditionals = (m: any) => {
    setMap(m)
  }

  useMemo(() => {
    if (!tabManager) return

    tabManager.update('personalization', {
      content: () => (
        <>
          <AdditionalPersonalization
            defaultMinimumValue={minimumValue}
            defaultMaximumValue={maximumValue}
            setMinimumValue={setMinimumValue}
            setMaximumValue={setMaximumValue}
          />
          <ConditionalsSection
            {...{
              device,
              widget: props.widget,
              onChange: onChangeConditionals,
            }}
          />
        </>
      ),
    })
  }, [tabManager, device, props.widget])

  function onSave(oldData, oldCustomData) {
    const data = oldData
    const customData = oldCustomData
    customData.range = {
      minimumValue,
      maximumValue,
    }
    customData.map = map
    data.customData = customData
    onSubmit(data, customData)
  }

  return (
    <Base
      {...props}
      onChangeDevice={e => setDevice(e)}
      onSave={(data, customData) => onSave(data, customData)}
      tabs={tabs => setTabManager(tabs)}
    />
  )
}
