import React, { useState } from 'react'
import moment from 'moment'
import Dropdown from '@components/dropdown'
import ModalUpdate from '@modals/device/update'
import ModalDelete from '@modals/device/delete'
import { Device } from '@services/openapi'
import { Td } from '../styles'

const DeviceItem = ({ device, onUpdate, onDelete, onRefresh }) => {
  const [modalVisibleUpdate, setModalVisibleUpdate] = useState(false)
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false)
  const [idDevice, setIdDevice] = useState(false)

  function openModalUpdate() {
    setIdDevice(device.id)
    setModalVisibleUpdate(true)
  }

  function openModalDelete() {
    setIdDevice(device.id)
    setModalVisibleDelete(true)
  }

  function isConnected(date) {
    const dateDevice = moment(date)
      .add(15, 'minutes')
      .format('DD-MM-YYYY HH:mm')
    const now = moment().format('DD-MM-YYYY HH:mm')

    return dateDevice >= now
  }

  return (
    <>
      <tr key={device.id}>
        <th scope="row">{device.name}</th>
        <Td active={isConnected(device.updatedAt)}>
          <span>
            {isConnected(device.updatedAt) ? 'Conectado' : 'Desconectado'}
          </span>
        </Td>
        <td>{device.variableCount}</td>
        <td>{`${moment(device.updatedAt).format(
          'DD/MM/YYYY \\à\\s HH:mm',
        )}`}</td>
        <td>{`${moment(device.createdAt).format(
          'DD/MM/YYYY \\à\\s HH:mm',
        )}`}</td>
        <td>
          <Dropdown
            items={[
              ['Editar', () => openModalUpdate()],
              ['Excluir', () => openModalDelete()],
            ]}
          />
        </td>
      </tr>
      {modalVisibleUpdate && (
        <ModalUpdate
          open={modalVisibleUpdate}
          setOpen={setModalVisibleUpdate}
          device={device}
          onUpdate={(updated: Device) => onUpdate(updated)}
          onRefresh={() => onRefresh()}
        />
      )}
      {modalVisibleDelete && (
        <ModalDelete
          open={modalVisibleDelete}
          setOpen={setModalVisibleDelete}
          idDevice={idDevice}
          onDelete={() => onDelete()}
        />
      )}
    </>
  )
}

export default DeviceItem
