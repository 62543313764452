import styled from 'styled-components'

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const Message = styled.div`
  margin-top: 5%;
  color: ${props => props.theme.title};
  font-family: Inter, sans-serif;
`

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: end;
  button {
    margin-left: 5%;
  }
`
