import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

import OrganizationPage from '@pages/organization'
import OrganizationMembers from '@pages/organization/show/members'
import OrganizationDevices from '@pages/organization/show/devices'
import OrganizationConfig from '@pages/organization/show/config'
import RoutesPrivates from './routes/privaterRoutes'
import Login from './pages/login'
import ForgotPassword from './pages/login/forgotPassword'
import ResetPassword from './pages/login/resetPassword'
import ActiveUser from './pages/login/activeAccount'
import {
  TokenProvider,
  RouteStateProvider,
  ThemeProvider,
} from './components/store/context'
import Home from './pages/home'
import Devices from './pages/devices'
import NotFound from './pages/notFound'
import Construction from './pages/construction'
import { GlobalStyle } from './GlobalStyles'

const App = () => {
  return (
    <ThemeProvider>
      <GlobalStyle />

      <ToastProvider>
        <TokenProvider>
          <RouteStateProvider>
            <Router>
              <Switch>
                {/* Routes test */}
                {/* <Route path="/dashboard" component={Home} exact />
              <Route path="/devices" component={Devices} exact /> */}
                {/* End routes test */}
                <Route path="/" component={Login} exact />
                {/* <Route path="/create-account" component={CreateUser} exact /> */}
                <Route
                  path="/active-account/:token/:userId"
                  component={ActiveUser}
                  exact
                />
                <Route
                  path="/forgot-password"
                  component={ForgotPassword}
                  exact
                />
                <Route
                  path="/reset-password/:token/:userId"
                  component={ResetPassword}
                  exact
                />
                <RoutesPrivates
                  path="/dashboard/:index?"
                  component={false}
                  renderCustom={() => {
                    return <Home />
                  }}
                />
                <RoutesPrivates
                  path="/devices"
                  component={false}
                  renderCustom={() => {
                    return <Devices />
                  }}
                />

                <RoutesPrivates
                  path="/organization"
                  component={false}
                  renderCustom={() => {
                    return <OrganizationPage />
                  }}
                  exact
                />
                <RoutesPrivates
                  path="/organization/:organizationId/members"
                  component={false}
                  renderCustom={() => {
                    return <OrganizationMembers />
                  }}
                />
                <RoutesPrivates
                  path="/organization/:organizationId/devices"
                  component={false}
                  renderCustom={() => {
                    return <OrganizationDevices />
                  }}
                />
                <RoutesPrivates
                  path="/organization/:organizationId/config"
                  component={false}
                  renderCustom={() => {
                    return <OrganizationConfig />
                  }}
                />
                <RoutesPrivates
                  path="/user"
                  component={false}
                  renderCustom={() => {
                    return <Construction />
                  }}
                />
                <RoutesPrivates
                  path="/configuration"
                  component={false}
                  renderCustom={() => {
                    return <Construction />
                  }}
                  exact
                />
                <Route component={NotFound} exact />
              </Switch>
            </Router>
          </RouteStateProvider>
        </TokenProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

export default App
