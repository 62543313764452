import React from 'react'
import { Link } from 'react-router-dom'
import logoImage from '../../assets/logo-default.png'
import { Logo, Div } from '../../pages/construction/styles'

const InConstruction: React.FC = () => {
  return (
    <Div>
      <div>
        <h1>Página em construção</h1>
      </div>
      <div>
        <Logo src={logoImage} />
      </div>
      <div>
        <Link to="/dashboard">Vá para a Dashboards</Link>
      </div>
    </Div>
  )
}

export default InConstruction
