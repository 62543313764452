import styled from 'styled-components'

const InputDiv = styled.div`
  svg {
    position: absolute;
    pointer-events: none;
    padding: 10px;
  }

  label {
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.title};
  }

  input {
    border-radius: 6px;
    padding: 12px;
    background-color: ${props => props.theme.backgroundForm};
    color: ${props => props.theme.title};
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
  }

  input::placeholder {
    color: ${props => props.theme.placeholder};
  }

  input:not(:disabled) {
    box-shadow: 0px 0.4px 1px rgba(0, 0, 0, 0.024),
      0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  input:not(:disabled):focus {
    box-shadow: 0px 0.4px 2.5px rgba(0, 0, 0, 0.062),
      0px 3px 20px rgba(0, 0, 0, 0.13);
  }

  input:disabled {
    background-color: ${props => props.theme.backgroundSecondary} !important;
  }

  button {
    align-self: end;
  }
`

export const InputContent = styled(InputDiv)`
  position: relative;
`

export const InputWithEndIcon = styled(InputContent)`
  svg:last-child {
    right: 0;
  }

  input {
    padding: 10px 35px 10px 10px;
  }
`

export const InputWithStartIcon = styled(InputContent)`
  svg:first-child {
    margin-right: -10px;
  }

  input {
    padding: 10px 10px 10px 35px;
  }
`

export const InputWithIcon = styled(InputContent)`
  svg:first-child {
    margin-right: -10px;
  }

  svg:last-child {
    right: 0;
  }

  input {
    padding: 10px 35px 10px 35px;
  }
`

export const DivInput = styled(InputDiv)`
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  label {
    margin-bottom: 1%;
  }

  input {
    margin-top: 1%;
  }

  button {
    align-self: end;
  }
`

export const LabelCheckBox = styled.label`
  width: 100%;
  font-size: 0.8rem !important;
  cursor: pointer;
  font-weight: 400 !important;
  color: ${props => props.theme.title};
`
