import InputSearch from '@components/Input/search'
import styled from 'styled-components'

export const DivInput = styled.div`
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  label {
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.title};
    margin-bottom: 1%;
  }

  input {
    margin-top: 1%;
    box-shadow: 0px 0.4px 1px rgba(0, 0, 0, 0.024),
      0px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 12px;
    background-color: ${props => props.theme.backgroundForm};
    color: ${props => props.theme.title};
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
  }

  input::placeholder {
    color: ${props => props.theme.placeholder};
  }

  input:focus {
    box-shadow: 0px 0.4px 2.5px rgba(0, 0, 0, 0.062),
      0px 3px 20px rgba(0, 0, 0, 0.13);
  }
`
export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputColor = styled.div`
  position: relative;
  width: 50px !important;
  height: 50px !important;
  border-radius: 100% !important;
  overflow: hidden;

  input {
    height: 100%;
    background: ${props => props.theme.backgroundPrimary} !important;
    padding: 0 !important;
    cursor: pointer;
    transform: scale(1.5);
  }

  &:hover::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.2;
  }
`

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: ${props => props.theme.placeholder};
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const Search = styled(InputSearch).attrs({
  style: {
    marginTop: '5%',
    width: '100%',
  },
})``

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 1.5em;
  margin: 0 0 20px -20px;
`

export const Card = styled.div`
  background: ${props => props.theme.backgroundPrimaryContrast};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  display: flex;
  flex-basis: ${props => {
    if (props.theme.isDesktop) {
      return 'calc(33.3% - 20px)'
    }
    if (props.theme.isTablet) {
      return 'calc(50% - 20px)'
    }
    return '100%'
  }};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px 0 0 20px;
  box-sizing: border-box;

  svg {
    color: ${props => props.theme.greenHighlight};
    font-size: 50px;
  }
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${props => props.theme.title};
  }
  span {
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    color: #adb5bd;
  }

  &:hover {
    background: ${props => props.theme.modalHover};
    box-shadow: 0px 0px 5px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  }
`

// FORM

export const HeaderForm = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    font-size: 32px;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const DivForm = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  background: ${props => props.theme.backgroundPrimaryContrast};
  color: ${props => props.theme.title};
  svg {
    margin-left: 2%;
    color: ${props => props.theme.title};
  }
  &:hover {
    color: ${props => props.theme.greenHighlight};
    svg {
      fill: ${props => props.theme.greenHighlight};
    }
  }
`

export const TypeForm = styled.div`
  margin-top: 3%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.greenHighlight};
`

export const Icon = styled.div`
  width: 64px;
  height: 64px;
  color: ${props => props.theme.greenHighlight}
  border-radius: 50%;
  font-size: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export const Title = styled.div`
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
`

export const ContainerForm = styled.div`
  margin-top: 2%;
  /* height: 200px; */
  background: ${props => props.theme.backgroundSecondary};
  border-radius: 6px;
  padding: 2%;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #adb5bd;
  }
`

export const Graphics = styled.div`
  display: flex;
  justify-content: center;
`

export const Form = styled.form``

export const Menu = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.backgroundSecondary};
`

export const Span = styled.span<{
  active?: boolean
}>`
  width: 90px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;

  color: ${({ active, theme }) => (active ? theme.greenHighlight : '#adb5bd')};
  border-bottom: ${({ active, theme }) =>
    active ? `solid 2px ${theme.greenHighlight}` : 'none'};

  &:hover {
    color: ${props => props.theme.greenHighlight}
    border-bottom: solid 2px ${props => props.theme.greenHighlight}
  }
`

export const Personalization = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: space-between;
`

export const DivUnits = styled.div`
  width: 45%;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.title};
  }
`

export const PersonalizationHeaders = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FooterForm = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: end;
  button {
    margin-left: 5%;
  }
`

export const ButtonsPlus = styled.div`
  display: flex;
  select {
    width: 90%;
  }
`

export const ButtonsAddRemove = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const TabContent = styled.div<{
  displayTab?: boolean
}>`
  display: ${({ displayTab }) => (displayTab ? 'block' : 'none')};
`

export const Label = styled.label`
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.title};
  margin-bottom: 2%;
`

export const LabelNoMargin = styled(Label)`
  font-weight: 400;
  margin-bottom: 0;
  /* flex: 1; */
  width: fit-content;
`

export const DivMultiBaseline = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  align-items: center;
  justify-content: normal;
  margin-top: 15px;

  input {
    max-width: 200px;
  }

  button {
    width: 45px;
    margin-right: 15%;
  }
`

export const HeaderTitle = styled.div`
  margin-top: 45px;
`

export const Mandatory = styled.span`
  margin-left: 5px;
  color: red;
`
