import React from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { IoIosArrowBack } from 'react-icons/io'
import { AiOutlineClose } from 'react-icons/ai'
import { getWidgetApi } from '@services/api'
import { NewWidget, WidgetTypeEnum } from '@services/openapi'
import { HeaderForm, DivForm, TypeForm, Icon, Title } from '../styles'
import Bar from '../common/bar'
import Common from '../common/common'
import Line from '../common/line'
import Meter from '../common/speedometer'
import Cylinder from '../common/cylinder'

export default function Index({
  type,
  onBack,
  onCreate,
  setOpen,
  idDash,
}: {
  type
  onBack: () => void
  onCreate: (data: NewWidget) => void
  setOpen
  idDash: number
}) {
  const { addToast } = useToasts()

  const apiWidget = getWidgetApi()

  function closeModal() {
    setOpen(false)
  }

  async function onSave(data) {
    await apiWidget
      .widgetControllerCreate(data)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          closeModal()
          onCreate(response.data.data)
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  function getProps() {
    try {
      return {
        type: type.type,
        onSave: data => onSave(data),
        closeModal: () => closeModal(),
        idDash,
      }
    } catch (e) {
      return {
        type: type.type,
        onSave: data => onSave(data),
        closeModal: () => closeModal(),
        idDash,
      }
    }
  }
  return (
    <>
      <HeaderForm>
        <DivForm onClick={() => onBack()}>
          <IoIosArrowBack />
          Voltar
        </DivForm>
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </HeaderForm>
      <TypeForm>
        <Icon>{type.icon}</Icon>
        <Title>{type.title}</Title>
      </TypeForm>

      {type.type === WidgetTypeEnum.Text && <Common {...getProps()} />}
      {type.type === WidgetTypeEnum.Number && <Common {...getProps()} />}
      {type.type === WidgetTypeEnum.Meter && <Meter {...getProps()} />}
      {type.type === WidgetTypeEnum.LineChart && <Line {...getProps()} />}
      {type.type === WidgetTypeEnum.BarChart && <Bar {...getProps()} />}
      {type.type === WidgetTypeEnum.Cylinder && <Cylinder {...getProps()} />}
    </>
  )
}
