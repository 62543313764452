import styled from 'styled-components'

export const Div = styled.div`
  width: calc(100% - 10px);
  background-color: ${props => props.theme.backgroundTerciary};
`

export const FullScreen = styled.div`
  overflow: auto;
  min-height: 100vh;
  background-color: ${props => props.theme.backgroundTerciary};
`
