import styled from 'styled-components'
import { DropDownContainer as BaseDropDownContainer } from '@components/dropdown/styles'

const speedMetre = (value, max, min) => {
  if (value) {
    let v = value
    if (v > max) {
      v = max
    }
    if (v < min) {
      v = min
    }
    const result = (0.5 * (v - min)) / (max - min)
    return `rotate(${result}turn);`
  }
  return `rotate(0turn);`
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TaskList = styled.div`
  display: flex;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: start;
  overflow: hidden;
  height: calc(100% - 24px);
  border-radius: 4px;

  text,
  text * {
    color: ${props => props.theme.title};
  }
`

export const CardMetter = styled.div`
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: space-between;
  overflow: hidden;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${props => props.theme.title};
  display: flex;
  justify-content: space-between;
  svg {
    transform: rotate(225deg);
  }
`

export const Center = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  height: 100%;
  justify-content: center;
  * {
    margin: 0px;
    padding: 0px;
  }

  svg {
    height: 30px;
    color: ${props => props.theme.greenHighlight};
    font-size: 30px;
  }

  small {
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    color: #adb5bd;
    position: relative;
  }
`
interface ICylinderContent {
  heightCard: number | boolean
}
export const CylinderContent = styled.div<ICylinderContent>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  align-items: center;
  svg {
    height: 100% !important;
    margin-top: -5px;
  }
  > section {
    display: flex;
    justify-content: center;

    * {
      margin: 0px;
      padding: 0px;
    }
    p,
    span {
      text-align: center;
      font-size: 1rem;
    }
    p {
      color: ${props => props.theme.greenHighlight};
      margin-top: -1px;
      font-weight: 600;
      ${props => {
        if (props.heightCard !== false && props.heightCard > 430) {
          return 'font-size: 2rem;margin-top: 3%;'
        }
        if (props.heightCard !== false && props.heightCard > 300) {
          return 'font-size: 1.6rem;margin-top: 2.5%;'
        }
        return 'font-size: 1.3rem'
      }}
    }
    span {
      /* margin-top: 5%; */
      font-family: Inter;
      font-style: normal;
      font-size: 12px;
      color: #adb5bd;
      position: relative;
    }
  }
`
export const CenterGauge = styled(Center)<{
  value: number
  min: number
  max: number
  color?: string
}>`
  .gauge {
    width: 100%;
    max-width: 180px;
    font-size: 32px;
    color: #004033;
  }

  .gauge__body {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: #b4c0be;
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;
  }

  .gauge__fill {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: ${props => props.color ?? props.theme.greenHighlight};
    transform-origin: center top;
    transform: ${({ value, max, min }) => speedMetre(value, max, min)};
    transition: transform 0.2s ease-out;
  }

  .gauge__cover {
    width: 75%;
    height: 150%;
    background: ${props => props.theme.backgroundPrimary};
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const CenterNotValue = styled(Center)`
  flex: 0.1;
  justify-content: end;
  padding-bottom: 6px;
`

export const IconDiv = styled.div`
  height: 30px;

  svg {
    color: ${({ color }) => color ?? 'unset'};
  }
`

export const Units = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 6px;

  span {
    font-size: 16px;
    margin-top: 10%;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    color: #adb5bd;
  }
`
export const Middle = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`

export const UnitsSpeed = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 14px;
  justify-content: center;
  z-index: 2;
  svg {
    height: 20px;
    padding-top: 10px;
  }

  div {
    display: flex;
    align-items: stretch;
    span {
      font-size: 16px;
      margin-top: 10%;
    }
  }

  span {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    color: #adb5bd;
    position: relative;
    z-index: 2;
  }
`

export const Value = styled.div<{
  color?: string
}>`
  margin-top: 3%;
  color: ${props => props.color ?? props.theme.greenHighlight};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
`

export const NotValue = styled.div`
  margin-top: 5%;
  color: ${props => props.theme.greenHighlight};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
`

export const IconFooter = styled.div`
  display: flex;
  justify-content: end;
  svg {
    transform: rotate(315deg);
  }
`

export const DivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 20000000;
`

export const DivCenter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const DivContent = styled.div`
  width: 100%;
  flex: 1;
  .chartsCustom {
    width: inherit;
    /* height: inherit; */
    /* min-height: unset !important; */
    margin-top: 7px;
  }
`

export const ValueAndIcons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const UnitSpeedSpan = styled(Center)`
  flex: 0.1;
  justify-content: end;
  /* padding-bottom: 6px; */
`

export const DropDownContainer = styled(BaseDropDownContainer)<{
  active?: boolean
}>`
  z-index: 122222223;
`
