import ModalComponent from '@components/modal'
import { NewWidget, WidgetTypeEnum } from '@services/openapi'
import React, { useState } from 'react'
import Form from './form'
import List from './list'

interface CreateWidgetProps {
  open: boolean
  idDash: number
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCreate: (data: NewWidget) => void
}

const CreateWidget: React.FC<CreateWidgetProps> = ({
  open,
  idDash,
  setOpen,
  onCreate,
}) => {
  const customStyles = {
    content: {
      width: '65%',
      height: '90vh',
      maxWidth: '882px',
    },
  }

  const [type, setType] = useState<WidgetTypeEnum>()

  return (
    <ModalComponent open={open} setOpen={setOpen} style={customStyles}>
      {type ? (
        <>
          <Form
            type={type}
            onBack={() => setType(undefined)}
            onCreate={(data: NewWidget) => {
              setType(undefined)
              onCreate(data)
            }}
            setOpen={setOpen}
            idDash={idDash}
          />
        </>
      ) : (
        <List setType={setType} setOpen={setOpen} />
      )}
    </ModalComponent>
  )
}

export default CreateWidget
