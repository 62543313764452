import React, { useState } from 'react'
import UpdateDashboardModal from '@components/modal/dashboard/delete'
import Dropdown from '@components/dropdown'
import { Item } from './style'

export default function Index({ dash, idActive, onDelete, onEdit, onClick }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Item
        key={dash.id}
        onClick={() => onClick()}
        active={idActive === dash.id}
      >
        <div>
          {dash.name}
          <Dropdown
            items={[
              ['Editar', () => onEdit(true)],
              ['Excluir', () => setOpen(true)],
            ]}
          />
        </div>
      </Item>
      <UpdateDashboardModal
        open={open}
        setOpen={setOpen}
        dashboard={dash}
        onDelete={() => onDelete()}
      />
    </>
  )
}
