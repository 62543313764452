import styled from 'styled-components'

export const DivInput = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    color: ${props => props.theme.title};
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  input {
    margin-top: 3%;
    background: ${props => props.theme.backgroundForm};
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 6px;
    padding: 12px;
    border: none;
    color: ${props => props.theme.title};
  }
`

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: end;
  button {
    margin-left: 5%;
  }
`

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`
