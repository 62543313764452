import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import debug from 'debug'
import { BsKey, BsFileEarmark } from 'react-icons/bs'
import { useToasts } from 'react-toast-notifications'

import {
  getDeviceApi,
  getOrganizationApi,
  Device,
  DeviceBody,
  Variable,
} from '@services/api'
import useOrganizationStaffList from '@hooks/useOrganizationStaffList'
import TableCommon from '@components/table/permission'
import Input from '@components/Input'
import { useAuth } from '@components/store/context'
import SubmitButton from '@components/submitbutton'
import VariableTab from '@table/variable'
import ModalComponent from '@components/modal'
import { Menu, Span, Div, ContentColaborate } from './styles'
import {
  ButtonGreen,
  ButtonWhite,
} from '../../../../assets/styles/stylesButton'

export default function Index({ open, setOpen, device, onUpdate, onRefresh }) {
  const [user] = useAuth()

  const { addToast } = useToasts()
  const [active, setActive] = useState(1)
  const [tags, setTags] = useState<Variable[]>([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [tokenDevice, setTokenDevice] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)
  const apiDevice = getDeviceApi()
  const apiOrganization = getOrganizationApi()
  const { organizationId } = useParams()

  const { staff } = useOrganizationStaffList(
    Number(organizationId || (user?.organization ? user?.organization.id : 0)),
    true,
  )

  useEffect(() => {
    setName(device.name)
    setDescription(device.description)
    setTokenDevice(device.token)
    if (device.variables) {
      setTags(device.variables)
    }
  }, [device])

  async function copy() {
    addToast('TokenDevice Copiado', {
      appearance: 'success',
      autoDismiss: true,
    })
    navigator.clipboard.writeText(tokenDevice)
  }

  function closeModal() {
    setOpen(false)
  }

  async function onSubmit(e) {
    e.preventDefault()
    setDisabledButton(true)
    const data: Partial<Device> = {
      name,
      description,
    }

    await apiDevice
      .deviceControllerUpdate(device.id, data as DeviceBody)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          closeModal()
          onUpdate({
            ...device,
            ...data,
            updatedAt: new Date().toISOString(),
          })
          onRefresh()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
    setDisabledButton(false)
  }

  const headerPermission = [{ name: 'Nome', field: 'name', sortable: true }]
  const ref = useRef<HTMLDivElement>(null)
  const [dropDownVisible, setDropDownVisible] = useState<any>()
  const [dropdownIndexSelected, setDropdownIndexSelected] = useState<any>(0)
  const [permissions, setPermissions] = useState<any>([])
  const [thereChangesPermission, setThereChangesPermission] =
    useState<boolean>(false)

  useEffect(() => {
    const valuess: any[] = []
    staff.forEach(item => {
      const userItem = item.permission.find(
        permissionItem => permissionItem.device == device.id,
      )
      if (userItem) {
        valuess.push({
          user_id: userItem.user,
          variables: userItem.variables ? userItem.variables : [],
        })
      }
    })
    setPermissions(valuess)
  }, [staff])

  function onSubmitPermission() {
    apiOrganization
      .organizationControllerCreateOrUpdatePermission(
        permissions.map(item => {
          return {
            userId: item.user_id,
            deviceId: device.id,
            variableIds: item.variables,
          }
        }),
      )
      .then(response => {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(error => {
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (dropDownVisible && ref.current && !ref.current.contains(e.target)) {
        setDropDownVisible(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [dropDownVisible])

  return (
    <ModalComponent title="Dados do dispositivo" open={open} setOpen={setOpen}>
      <Menu>
        <Span active={active == 1} onClick={() => setActive(1)}>
          Geral
        </Span>
        <Span active={active == 2} onClick={() => setActive(2)}>
          Variáveis
        </Span>
        <Span active={active == 3} onClick={() => setActive(3)}>
          Permissões
        </Span>
      </Menu>
      {active == 1 && (
        <form onSubmit={e => onSubmit(e)}>
          <Input
            label="Nome"
            value={name}
            required
            type="text"
            placeholder="Digite um nome..."
            onChange={e => setName(e.target.value)}
          />
          <Input
            label="Descrição"
            value={description}
            required
            type="text"
            placeholder="Digite uma descrição..."
            onChange={e => setDescription(e.target.value)}
          />
          <Input
            disabled
            label="Token"
            value={tokenDevice}
            start={<BsKey />}
            end={
              <BsFileEarmark
                style={{ cursor: 'copy', pointerEvents: 'all' }}
                onClick={() => copy()}
              />
            }
            required
          />
          <Div>
            <ButtonWhite onClick={() => closeModal()}>Cancelar</ButtonWhite>
            <ButtonGreen disabled={disabledButton}>Salvar</ButtonGreen>
          </Div>
        </form>
      )}
      {active == 2 && (
        <VariableTab data={tags} onRequestRefreshDevice={() => onRefresh()} />
      )}
      {active == 3 && (
        <ContentColaborate>
          <div className="content">
            <select
              onChange={e =>
                setDropdownIndexSelected(e.target.options.selectedIndex)
              }
              className="dropDownColoborate"
            >
              <option>Device (Todos)</option>
              {tags.map(i => (
                <option key={i.id}>{i.tag}</option>
              ))}
            </select>
            <TableCommon
              permissions={permissions}
              setPermissions={e => {
                setPermissions(e)
                setThereChangesPermission(true)
              }}
              search=""
              headers={headerPermission}
              data={staff}
              dropdownSelected={dropdownIndexSelected}
              tags={tags.map(i => i.id)}
            />
          </div>

          <SubmitButton
            label="Salvar permissões"
            style={{
              alignSelf: 'end',
            }}
            disable={!thereChangesPermission}
            onClick={() => {
              onSubmitPermission()
            }}
          />
        </ContentColaborate>
      )}
    </ModalComponent>
  )
}
