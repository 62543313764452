import React from 'react'
import debug from 'debug'
import Modal from 'react-modal'
import { useTheme } from 'styled-components'
import { AiOutlineClose } from 'react-icons/ai'
import { useToasts } from 'react-toast-notifications'
import { getDeviceApi } from '@services/api'
import { Header, Message, Div } from './styles'
import { ButtonRed, ButtonWhite } from '../../../../assets/styles/stylesButton'

export default function Index({ open, setOpen, idDevice, onDelete }) {
  const theme: any = useTheme()
  const { addToast } = useToasts()
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '35%',
      backgroundColor: theme.backgroundPrimary,
      borderColor: theme.backgroundPrimaryContrast,
      boxShadow: `2px 2px 9px ${
        theme.themeName != 'whiteThem' ? '#000' : theme.title
      }`,
    },
    overlay: {
      backgroundColor: theme.modalOverlay,
    },
  }

  const apiDevice = getDeviceApi()

  function closeModal() {
    setOpen(false)
  }

  async function disabled() {
    await apiDevice
      .deviceControllerRemove(idDevice)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          closeModal()
          onDelete()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <Header>
        Excluir Dispositivo
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </Header>
      <Message>Deseja excluir esse dispositivo?</Message>
      <Div>
        <ButtonWhite onClick={() => closeModal()}>Cancelar</ButtonWhite>
        <ButtonRed onClick={() => disabled()}>Excluir</ButtonRed>
      </Div>
    </Modal>
  )
}
