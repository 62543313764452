import React from 'react'
import SideBar from '@sidebar/index'
import InConstruction from '@errors/inConstruction'
import Main from '@layout/Main'

function ConstructionPage() {
  return (
    <>
      <SideBar />
      <Main>
        <InConstruction />
      </Main>
    </>
  )
}

export default ConstructionPage
