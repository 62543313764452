/* eslint-disable react/destructuring-assignment */
import { Mandatory } from '@components/modal/widget/styles'
import React from 'react'
import {
  DivInput,
  InputWithStartIcon,
  InputWithEndIcon,
  InputWithIcon,
  InputContent,
  LabelCheckBox,
} from './styles'

interface IInputComponent {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: any
  value?: string
  placeholder?: string
  required?: boolean
  name?: string
  type?: string
  checked?: boolean
  disabled?: boolean
  start?: React.ReactNode
  end?: React.ReactNode
}

const Content: React.FC<IInputComponent> = ({
  label,
  onChange,
  value,
  required,
  placeholder,
  disabled,
  name,
  type,
  checked,
  start,
  end,
}) => (
  <>
    {label && type != 'checkbox' && (
      <label htmlFor={name}>
        {label}
        {required && <Mandatory>*</Mandatory>}
      </label>
    )}
    {label && type == 'checkbox' ? (
      <LabelCheckBox>
        <input
          key={checked ? 'with' : 'nothing'}
          name={name}
          value={value}
          required={required || false}
          type={type || 'text'}
          placeholder={placeholder}
          onChange={e => onChange && onChange(e)}
          checked={checked}
          disabled={disabled}
        />
        {label}
      </LabelCheckBox>
    ) : (
      <InputContent>
        {start}
        <input
          id={name}
          name={name}
          value={value}
          required={required || false}
          type={type || 'text'}
          placeholder={placeholder}
          disabled={disabled}
          onChange={e => onChange && onChange(e)}
        />
        {end}
      </InputContent>
    )}
  </>
)

const Input: React.FC<IInputComponent> = props => {
  return (
    <>
      {props.start && !props.end && (
        <InputWithStartIcon>
          <Content {...props} />
        </InputWithStartIcon>
      )}
      {props.end && !props.start && (
        <InputWithEndIcon>
          <Content {...props} />
        </InputWithEndIcon>
      )}
      {props.start && props.end && (
        <InputWithIcon>
          <Content {...props} />
        </InputWithIcon>
      )}
      {!props.start &&
        !props.end &&
        (props.type == 'checkbox' ? (
          <Content {...props} />
        ) : (
          <DivInput>
            <Content {...props} />
          </DivInput>
        ))}
    </>
  )
}

export default Input
