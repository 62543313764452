import { evaluate } from 'mathjs'

const calculateFormula = (formula: string) => {
  try {
    return evaluate(formula)
  } catch (e) {
    return e
  }
}

export default calculateFormula
