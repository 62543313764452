import React from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'
import { getDashboardApi } from '@services/api'
import AreYouSure from '../areyousure'

export default function Index({ open, setOpen, dashboard, onDelete }) {
  const { addToast } = useToasts()

  async function remove() {
    await getDashboardApi()
      .dashboardControllerRemove(dashboard.id)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          setOpen(false)
          onDelete()
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  return (
    <AreYouSure
      open={open}
      setOpen={setOpen}
      title={`Excluir ${dashboard.name}`}
      description="Deseja excluir essa dashboard? Todos os widgets serão perdidos"
      confirmText="Excluir"
      onConfirm={() => remove()}
    />
  )
}
