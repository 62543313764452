import styled from 'styled-components'

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  button {
    width: 100px;
  }
`

export const Button = styled.button`
  color: ${props => props.theme.backgroundPrimary} !important;
  text-transform: uppercase;
  text-decoration: none;
  background: ${props => props.theme.greenHighlight};
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  &:hover {
    background: #434343;
  }
`

export const Title = styled.section`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`
