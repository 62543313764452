import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import ModalComponent from '@components/modal'
import { Widget, WidgetDTO } from '@services/api'
import { Header } from '../styles'
import Form from './form'

interface UpdateWidgetProps {
  widget: Widget
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onUpdate: (data: WidgetDTO) => void
}

const UpdateWidget: React.FC<UpdateWidgetProps> = ({
  open,
  setOpen,
  widget,
  onUpdate,
}) => {
  const customStyles = {
    content: {
      width: '65%',
      height: '90vh',
      maxWidth: '882px',
    },
  }

  function closeModal() {
    setOpen(false)
  }

  return (
    <ModalComponent open={open} setOpen={setOpen} style={customStyles}>
      <Header>
        Editar Widget - {widget.title}
        <span>
          <AiOutlineClose onClick={() => closeModal()} />
        </span>
      </Header>
      <Form widget={widget} onUpdate={onUpdate} setOpen={setOpen} />
    </ModalComponent>
  )
}

export default UpdateWidget
