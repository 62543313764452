import styled from 'styled-components'

export const SideBar = styled.div`
  position: fixed;
  width: 56px;
  height: 100%;
  background-color: ${props => props.theme.backgroundPrimary};
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24);

  @media (min-width: 768px) {
    z-index: 99;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundTerciary};
  }

  @media (max-width: 420px) {
    justify-content: end;
    height: 10%;
  }
`

export const Menu = styled.div<{
  isVisible?: boolean
}>`
  margin-top: 75px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 2%;
  }

  @media (max-width: 420px) {
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: ${({ isVisible }) => (isVisible ? '365px' : '0')};
    transition: max-height 0.3s easy-in;
  }
`

export const MenuLink = styled.div`
  display: flex;
  padding: 0.5rem;
  text-decoration: none;
  justify-content: center;

  @media (max-width: 420px) {
    justify-content: start;
  }
`

export const MenuLinkAvatar = styled(MenuLink)`
  position: fixed;
  bottom: 10px;
  @media (max-width: 768px) {
    top: 5px;
    right: 15px;
    height: 20px;
  }
`

export const Icons = styled.div<{
  active?: boolean
}>`
  padding: 0.4rem;
  border-radius: 5px;
  color: ${({ active, theme }) =>
    active ? theme.backgroundPrimary : '#adb5bd'};
  background-color: ${({ active }) => (active ? '#00A65F' : 'none')};

  display: flex;
  &:hover {
    background-color: #00a65f;
    cursor: pointer;
    color: ${props => props.theme.backgroundPrimary};
  }
`

export const IconsDivisor = styled.div`
  color: #adb5bd;
  padding: 0.4rem;
  border-radius: 5px;
  margin-bottom: 10%;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 420px) {
    display: block;
    margin-bottom: 0%;
  }
`

export const IconsDivisorResponsive = styled(IconsDivisor)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

export const IconsInvert = styled(Icons)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const Avatar = styled.img`
  border-radius: 50%;
  height: 20px;
  margin-left: 2px;
`

export const Hamburger = styled(MenuLink)`
  display: none;
  flex-direction: column;
  cursor: pointer;
  color: #adb5bd;
  font-size: 25px;

  @media (max-width: 420px) {
    display: flex;
  }
`

export const Navbar = styled.div`
  display: none;

  @media (max-width: 420px) {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.backgroundTerciary};
  }
`
