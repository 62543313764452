import styled from 'styled-components'

export const Logo = styled.img`
  padding: 1rem 0;
  max-width: 180px;
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  h1,
  a {
    color: ${props => props.theme.title};
  }
`
