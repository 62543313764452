import debug from 'debug'

export function valueGraphic(value, decimal, type) {
  try {
    if (type == 'TEXT') {
      return value
    }
    if (value === null) {
      return null
    }
    return parseFloat(value).toFixed(decimal)
  } catch (error) {
    debug('api')(error)
  }
  return null
}
