import styled from 'styled-components'

export const ContentEditable = styled.div.attrs({
  contentEditable: true,
})`
  box-shadow: 0px 0.4px 1px rgba(0, 0, 0, 0.024),
    0px 3px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: ${props => props.theme.backgroundForm};
  color: ${props => props.theme.title};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px;
  outline: none;
  box-sizing: border-box;

  &:focus {
    box-shadow: 0px 0.4px 2.5px rgba(0, 0, 0, 0.062),
      0px 3px 20px rgba(0, 0, 0, 0.13);
  }

  .chip-variavel {
    /* border: 2px solid blue; */
    /* width: fit- */
    border-radius: 3px;
    background: #e9ecef;
    width: fit-content;
    padding: 3px;
    border: 1px solid #e2dcea;
    margin: 0px 4px;
    > span {
      padding: 3px 5px;
      background: #ffffff;
      color: #b9b9b9;
      font-weight: bold;
      margin-left: 7px;
      border-radius: 3px;
      height: fit-content;
      font-size: 0.8rem;
      width: fit-content;

      &:hover {
        background: #c3b0a2;
        cursor: pointer;
        color: white;
      }
    }
  }
`
