import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { getDeviceApi } from '@services/api'
import { useAuth } from '@components/store/context'

function useDevices(config?: SWRConfigurationProps) {
  const [token] = useAuth()

  const { data, error, mutate } = (
    config?.immutable === false ? useSWR : useSWRImmutable
  )(
    config?.canFetch || token.userId
      ? ['deviceControllerGetAllByUser', token.userId]
      : null,
    () =>
      getDeviceApi()
        .deviceControllerGetAllByUser()
        .then(response => response.data),
    config as SWRConfiguration,
  )

  return {
    devices: data?.data || [],
    response: data,
    isError: error,
    isLoading: !error && !data,
    mutate,
  }
}

export default useDevices
