import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle<any>`
  @font-face {
  font-family: Inter;
  src: url('./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf');
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: ${props => props.theme.placeholder};
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: ${props => props.theme.greenHighlight};
  border-radius: 10px;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: ${props => props.theme.backgroundTerciary || 'white'};
}

a {
  font-size: 1em;
  cursor: pointer;
  color: ${props => props.theme.greenHighlight};
  text-decoration: underline;
}

.main {
  padding-top: 10px;
  padding-left: 80px;
  padding-right: 24px;
  min-height: 100vh;
}

.float-right {
  float: right
}

@media (max-width: 768px) {
  .main {
    padding-top: 50px;
    padding-left: 20px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 1px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: ${props => props.theme.greenHighlight};
}

input:focus + .slider {
  box-shadow: 0 0 1px ${props => props.theme.greenHighlight};
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ReactModal__Overlay {
  z-index: 3 !important;
}

/* DropDown */

.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: ${props => props.theme.backgroundPrimary};
  z-index: 13;
  width: 10.5em;
  margin: 0 auto;
  font-family: 'Inter';
  font-style: normal;
  font-size: 15px;
  color: ${props => props.theme.title};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  text-align: center;
  position: absolute;
  border: 1px solid ${props => props.theme.backgroundHover};;
  border-radius: 3px;
  right: 1px;
}
.dropdown-content > p {
  margin: 0;
  font-family: 'Inter';
  padding: 7px 7px;
  cursor: pointer;
}
.dropdown-content > p:hover {
  border-left: 2px solid ${props => props.theme.greenHighlight};
  background: ${props => props.theme.backgroundHover};
}

.dropdown:hover .dropdown-content {
  display: block;
}

.react-grid-item.cssTransforms {
  transition-property: none;
}
.animated .react-grid-item.cssTransforms {
  transition-property: transform;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.placeholder};
}

`
