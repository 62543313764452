import React from 'react'
import { ButtonRed, ButtonWhite } from '@assets/styles/stylesButton'
import ModalComponent from '..'
import { Div, Message } from './styles'

interface IAreYouSure {
  onConfirm: React.MouseEventHandler<HTMLButtonElement>
  title: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  description: string
  confirmText?: string
  cancelText?: string
}

const AreYouSure: React.FC<IAreYouSure> = ({
  onConfirm,
  setOpen,
  description,
  title,
  open,
  confirmText,
  cancelText,
}) => {
  return (
    <ModalComponent title={title} open={open} setOpen={setOpen}>
      <Message>{description}</Message>
      <Div>
        <ButtonWhite onClick={() => setOpen(false)}>
          {cancelText || 'Cancelar'}
        </ButtonWhite>
        <ButtonRed onClick={onConfirm}>{confirmText || 'Confirmar'}</ButtonRed>
      </Div>
    </ModalComponent>
  )
}

export default AreYouSure
