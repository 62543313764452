import styled from 'styled-components'

export const DivInput = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    color: ${props => props.theme.title};
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  input {
    margin-top: 3%;
    background: ${props => props.theme.backgroundForm};
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 6px;
    padding: 12px;
    border: none;
    color: #adb5bd;
  }
`

export const DivInputToken = styled.div`
  margin-top: 3%;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: ${props => props.theme.backgroundSecondary};
  border-radius: 6px;
  padding: 10px;
  color: #adb5bd;
  display: flex;
  align-items: center;
  input {
    margin-left: 5px;
    background-color: ${props => props.theme.backgroundSecondary};
    border: none;
    outline: none;
    width: 100%;
  }
`

export const Div = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: end;
  button {
    margin-left: 5%;
  }
`

export const DivFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 95px;
`

export const DivVariable = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const Message = styled.div`
  margin-top: 5%;
  color: ${props => props.theme.title};
  font-family: Inter, sans-serif;
`

export const Menu = styled.div`
  display: flex;
  border-bottom: solid 1px ${props => props.theme.backgroundSecondary};
`

export const Span = styled.span<{
  active?: boolean
}>`
  width: 70px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: 5%;
  margin-left: 5%;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;

  color: ${({ active, theme }) => (active ? theme.greenHighlight : '#adb5bd')};
  border-bottom: ${({ active, theme }) =>
    active ? `solid 2px ${theme.greenHighlight}` : 'none'};

  &:hover {
    color: ${props => props.theme.greenHighlight};
    border-bottom: solid 2px ${props => props.theme.greenHighlight};
  }
`

export const InputDiv = styled.div`
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: ${props => props.theme.backgroundPrimary};
  border-radius: 6px;
  padding: 10px;
  color: #adb5bd;
  display: flex;
  align-items: center;
  width: 300px;
  input {
    margin-left: 5px;
    background-color: ${props => props.theme.backgroundPrimary};
    border: none;
    outline: none;
    width: 100%;
  }
`

// FORM

export const HeaderForm = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    cursor: pointer;
    color: #adb5bd;
    font-size: 32px;
    &:hover {
      color: ${props => props.theme.title};
    }
  }
`

export const DivForm = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  svg {
    margin-left: 2%;
  }
  &:hover {
    color: ${props => props.theme.title};
  }
`

export const TypeForm = styled.div`
  margin-top: 3%;
  display: flex;
  align-items: center;
`

export const Icon = styled.div`
  width: 64px;
  height: 64px;
  background: #ebfff6;
  color: ${props => props.theme.greenHighlight};
  border-radius: 50%;
  font-size: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

export const Title = styled.div`
  margin-left: 5%;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: ${props => props.theme.title};
`

export const ContainerForm = styled.div`
  margin-top: 2%;
  height: 200px;
  background: ${props => props.theme.backgroundSecondary};
  border-radius: 6px;
  padding: 2%;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #adb5bd;
  }
`

export const Graphics = styled.div`
  display: flex;
  justify-content: center;
`

export const Form = styled.form``

export const SpanForm = styled.span<{
  active?: boolean
}>`
  width: 90px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;

  color: ${({ active, theme }) => (active ? theme.greenHighlight : '#adb5bd')};
  border-bottom: ${({ active, theme }) =>
    active ? `solid 2px ${theme.greenHighlight}` : 'none'};

  &:hover {
    color: ${props => props.theme.greenHighlight};
    border-bottom: solid 2px ${props => props.theme.greenHighlight};
  }
`

export const Personalization = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: space-between;
`

export const DivUnits = styled.div`
  width: 45%;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.title};
  }
`

export const PersonalizationHeaders = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FooterForm = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: end;

  button {
    margin-left: 5%;
  }
`

export const ButtonsPlus = styled.div`
  display: flex;
  select {
    width: 90%;
  }
`

export const ButtonsAddRemove = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const DivPersonalization = styled.div<{
  display?: number
}>`
  display: ${({ display }) => (display === 2 ? 'block' : 'none')};
`

export const DivDados = styled.div<{
  display?: number
}>`
  display: ${({ display }) => (display === 1 ? 'block' : 'none')};
`
