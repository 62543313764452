import React from 'react'
import { DivGraphic, SelectGraphic } from './styles'

export default function index({ setValue, value }) {
  async function handleSubmit(variables) {
    setValue(variables)
  }

  return (
    <DivGraphic>
      <SelectGraphic
        className="form-control"
        value={value}
        onChange={event => handleSubmit(event.target.value)}
      >
        <option value="1">Última 1 hora</option>
        <option value="2">Últimas 6 horas</option>
        <option value="3">Últimas 24 horas</option>
        <option value="4">Ontem</option>
        <option value="5">Últimos 3 dias</option>
        <option value="6">Últimos 7 dias</option>
        <option value="7">Últimos 31 dias</option>
        <option value="8">Customizado</option>
      </SelectGraphic>
    </DivGraphic>
  )
}
