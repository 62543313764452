export enum PeriodInterval {
  HOUR = 'hour',
  QUARTER = 'quarter',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THREE_DAYS = 'threeDays',
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom',
}
