import React from 'react'
import { BsPeople, BsBroadcast, BsPen } from 'react-icons/bs'
import SidebarItem from '@sidebar/item'
import Divider from '@sidebar/divider'
import ConfigMenu from '@sidebar/menu/config'
import Back from '@components/sidebar/back'
import { UserTypeEnum } from '@services/openapi'
import { useAuth } from '@components/store/context'

interface SingleOrganizationMenuProps {
  id: number
}

const SingleOrganizationMenu: React.FC<SingleOrganizationMenuProps> = ({
  id,
}) => {
  const [user] = useAuth()
  return (
    <>
      <Back />
      <SidebarItem
        to={`/organization/${id}/members`}
        id="organization/single/members"
        title="Membros da Organização"
        icon={BsPeople}
      />
      <SidebarItem
        to={`/organization/${id}/devices`}
        id="organization/single/devices"
        title="Dispositivos da Organização"
        icon={BsBroadcast}
      />
      {user.type === UserTypeEnum.Admin && (
        <SidebarItem
          to={`/organization/${id}/config`}
          id="organization/single/config"
          title="Configurações da Organização"
          icon={BsPen}
        />
      )}
      <Divider />
      <ConfigMenu />
    </>
  )
}

export default SingleOrganizationMenu
