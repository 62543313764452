import React, { useState } from 'react'
import debug from 'debug'
import { useToasts } from 'react-toast-notifications'

import { getUserApi } from '@services/api'
import CreateUserOrganization from '@components/modal/organization/createuser'
import { UserTypeEnum } from '@services/openapi'
import AreYouSure from '@components/modal/areyousure'
import Dropdown from '@components/dropdown'
import moment from 'moment'
import { Td } from './styles'

const MemberItem = ({ user, updateTable, onUpdateUser, logingThisUser }) => {
  const [modalVisibleUpdate, setModalVisibleUpdate] = useState(false)
  const [modalVisibleBanir, setModalVisibleBanir] = useState(false)
  const [modalVisibleDesbanir, setModalVisibleDesbanir] = useState(false)
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false)

  const apiUser = getUserApi()
  const { addToast } = useToasts()

  const language = {
    [UserTypeEnum.Admin]: 'Administrador',
    [UserTypeEnum.OrganizationAdmin]: 'Admin. da Organização',
    [UserTypeEnum.Staff]: 'Membro',
  }

  function openModalUpdate() {
    setModalVisibleUpdate(true)
  }

  function openModalBanir() {
    setModalVisibleBanir(true)
  }

  function openModalDesbanir() {
    setModalVisibleDesbanir(true)
  }

  function banirUnBanir() {
    setModalVisibleBanir(false)
    setModalVisibleDesbanir(false)
    ;(user.banned
      ? apiUser.userControllerUnban(user.id)
      : apiUser.userControllerBan(user.id)
    )
      .then(() => {
        updateTable()
      })
      .catch(error => {
        debug('api')(error)
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  function deleteUser() {
    setModalVisibleDelete(false)
    apiUser
      .userControllerDelete(user.id)
      .then(() => updateTable())
      .catch(error => {
        debug('api')(error)
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  async function updateUser(eventForm, userId) {
    const formData = eventForm

    const userEvent: any = {
      name: formData.target.name.value,
      email: formData.target.email.value,
      type: formData.target.typeUser.value,
      iAgree: true,
      avatar: '',
    }

    await apiUser
      .userControllerUpdate(userId, userEvent)
      .then(response => {
        onUpdateUser(response.data)
        updateTable()
      })
      .catch(error => {
        debug('api')(error)
        addToast(error.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <>
      <tr key={user && user.id}>
        {user && (
          <>
            <Td>
              <div>{user.name}</div>
            </Td>
            <Td active={!user.banned}>
              <span>{user.banned ? 'Banido' : 'Ativo'}</span>
            </Td>
            <Td>
              <div>{user.email}</div>
            </Td>
            <Td type={user.type == UserTypeEnum.OrganizationAdmin}>
              <div>{language[user.type]}</div>
            </Td>
            <Td>
              <div>{`${moment(user.updatedAt).format(
                'DD/MM/YYYY \\à\\s HH:mm',
              )}`}</div>
            </Td>
            <Td>
              <div>{`${moment(user.createdAt).format(
                'DD/MM/YYYY \\à\\s HH:mm',
              )}`}</div>
            </Td>
          </>
        )}

        <td>
          <Dropdown
            items={[
              ['Editar', () => openModalUpdate()],
              [
                user.banned ? 'Desbanir' : 'Banir',
                () => (user.banned ? openModalDesbanir() : openModalBanir()),
              ],
              ['Excluir', () => setModalVisibleDelete(true)],
              ['Logar', () => logingThisUser(user.email)],
            ]}
          />
        </td>
      </tr>
      <AreYouSure
        title="Banir membro da organização"
        open={modalVisibleBanir}
        description="Deseja banir esse membro?"
        onConfirm={() => banirUnBanir()}
        setOpen={setModalVisibleBanir}
      />
      <AreYouSure
        title="Desbanir membro da organização"
        open={modalVisibleDesbanir}
        description="Deseja desbanir esse membro?"
        onConfirm={() => banirUnBanir()}
        setOpen={setModalVisibleDesbanir}
      />
      <AreYouSure
        title="Excluir usuário da organização"
        open={modalVisibleDelete}
        description="Deseja deletar esse usuário ?"
        onConfirm={() => deleteUser()}
        setOpen={setModalVisibleDelete}
      />
      <CreateUserOrganization
        onAddMember={e => {
          setModalVisibleUpdate(false)
          updateUser(e, user.id)
        }}
        open={modalVisibleUpdate}
        setOpen={setModalVisibleUpdate}
        user={user}
      />
    </>
  )
}

export default MemberItem
