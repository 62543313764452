import styled from 'styled-components'
import BaseMain from '@layout/Main'

export const Main = styled(BaseMain)<{ active?: boolean }>`
  padding-right: ${({ active }) => (active ? '8px' : '12px')};
  overflow: hidden;

  ${({ theme, active }) => {
    if (theme.isDesktop) {
      return `padding-left: ${active ? '19rem' : '70px'} !important;`
    }
    if (theme.isTablet) {
      return `padding-left: ${active ? '19rem' : '25px'} !important;`
    }
    return 'padding-left: 8px !important;'
  }}

  ${({ theme }) => {
    if (theme.isMobile) {
      return 'padding-top: 8px !important;'
    }
  }}
`

export const MainFullScreen = styled.div`
  padding-top: 10px;
  padding-left: '4rem';
`
