import React, { useEffect, useState, useRef } from 'react'
import { debug } from 'debug'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useToasts } from 'react-toast-notifications'
import ModalDelete from '@modals/widget/delete'
import ModalUpdate from '@modals/widget/update'
import {
  getWidgetApi,
  WidgetDTO,
  Dashboard,
  Widget,
  WidgetPosition,
  WidgetTypeEnum,
} from '@services/api'
import Preview from '../../../widgets/preview'
import { Card, Container } from './styles'
import '@dependencies/react-grid-layout/css/styles.css'
import '@dependencies/react-resizable/css/styles.css'

const ReactGridLayout = WidthProvider(Responsive)

export default function Index({
  reDrawing = false,
  edit = false,
  refresh,
  widgets,
  dashboard,
  onUpdateDashboard,
  onLayoutChange,
  setWidgets,
  setReDrawing,
}: {
  edit: boolean
  refresh: number
  widgets: Widget[]
  dashboard: Dashboard
  reDrawing: boolean
  onUpdateDashboard: (dashboard: Dashboard) => void
  onLayoutChange: (newLayout: WidgetPosition[]) => void
  setWidgets: React.Dispatch<React.SetStateAction<Widget[]>>
  setReDrawing: (element: any) => any
}) {
  const [positions, setPositions] = useState<WidgetPosition[]>()
  const [modalVisibleDelete, setModalVisibleDelete] = useState<boolean>(false)
  const [modalVisibleUpdate, setModalVisibleUpdate] = useState<boolean>(false)
  const [widget, setWidget] = useState<Widget>({ id: 0 } as unknown as Widget)
  const [widgetIndex, setWidgetIndex] = useState<number>(-1)
  const [realtimeDelay, setRealtimeDelay] = useState<number>(0)
  // const [layoutsReact, setLayoutsReact] = useState<any>({})
  const [isDrag, setIsDrag] = useState<boolean>(false)
  const [animate, setAnimate] = useState<boolean>(false)
  const refGridTemplateLayout = useRef<any>(null)
  const refContainerGrid = useRef<any>(null)
  const { addToast } = useToasts()
  const [cols, setCols] = useState<any>()
  const [maxWidth, setMaxWidth] = useState<any>()

  const apiWidget = getWidgetApi()

  async function cloneWidget(data: Widget) {
    await apiWidget
      .widgetControllerClone(data.id!)
      .then(response => {
        if (response.data.success) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          const newWidget = {
            ...data,
            id: response.data.data.newId,
          }
          setWidgets([...widgets, newWidget])
          setWidgetIndex(-1)
          onUpdateDashboard({
            ...dashboard,
            position: response.data.data.newPosition,
          })
        } else {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
      .catch(error => {
        debug('api')(error)
      })
  }

  useEffect(() => {
    if (false) {
      setAnimate(true)
      setReDrawing(true)
    }

    setPositions(dashboard.position)
  }, [dashboard])

  useEffect(() => {
    setRealtimeDelay(10 / widgets.length)
  }, [widgets])

  useEffect(() => {
    if (positions !== undefined) {
      onLayoutChange(positions && positions.length > 0 ? positions : [])
    }
  }, [positions])

  function position(id: any, index: any, widgetData?: Widget) {
    const value = positions!.findIndex(obj => obj.i == id)
    if (value >= 0) {
      positions![value].minW = 2
      positions![value].minH = 3

      if (widgetData && widgetData.type === WidgetTypeEnum.LineChart) {
        positions![value].minH =
          1 + positions![value].minH * widgetData.variableList.length
      }
      if (positions![value].h < positions![value].minH) {
        positions![value].h = positions![value].minH
      }
      if (positions![value].w < positions![value].minW) {
        positions![value].w = positions![value].minW
      }
      return positions![value]
    }
    const valueTemp: WidgetPosition = {
      h: 1,
      i: `${id}`,
      w: 3,
      x: 0,
      y: index,
      minW: 2,
      minH: 3,
      moved: false,
    }
    return valueTemp
  }

  function deleteWidget(index: number) {
    widgets.splice(index, 1)
    setWidgets([...widgets])
    setWidgetIndex(-1)
  }

  function updateWidget(index: number, data: WidgetDTO) {
    const newWidgets = [...widgets]
    newWidgets[index].title = data.title
    newWidgets[index].device = data.deviceId as any
    newWidgets[index].variables = data.variables
    newWidgets[index].customData = data.customData
    setWidgets(newWidgets)
    setWidgetIndex(-1)
  }

  function getProportionScreen() {
    const gridWidth =
      refContainerGrid && refContainerGrid.current
        ? refContainerGrid.current.offsetWidth
        : 975
    const defaultSize = 82
    let countColumns = 1
    let widthCalculate = defaultSize
    while (gridWidth - widthCalculate > defaultSize) {
      widthCalculate += defaultSize
      countColumns += 1
    }
    setMaxWidth(widthCalculate)
    setCols({
      lg: countColumns,
      md: countColumns,
      sm: countColumns,
      xs: countColumns,
      xxs: countColumns,
    })
    setPositions(dashboard.position)
  }

  let doit: any
  function resizedw() {
    getProportionScreen()
  }

  window.onresize = () => {
    clearTimeout(doit)
    doit = setTimeout(() => {
      resizedw()
    }, 500)
  }

  useEffect(() => {
    getProportionScreen()
  }, [])

  return (
    <div>
      {reDrawing == true ? null : (
        <Container ref={refContainerGrid} isDrag={isDrag}>
          {(!widgets || widgets.length === 0) && (
            <div>Nenhum widget na dashboard</div>
          )}
          <ReactGridLayout
            useCSSTransforms
            measureBeforeMount
            width={maxWidth}
            cols={cols}
            rowHeight={47}
            isDraggable={edit}
            isResizable={edit}
            style={{ width: `${maxWidth}px !important` }}
            onDragStart={(layout, oldItem, newItem, placeholder, event) => {
              const heightReactGridLayout =
                refGridTemplateLayout.current.elementRef.current.offsetHeight
              const windowHeightCalulated = heightReactGridLayout - 100

              const eventWithoutType: any = event
              const positionCurrentEvent = eventWithoutType.nativeEvent.layerY

              if (positionCurrentEvent > windowHeightCalulated) {
                setIsDrag(true)
                setTimeout(() => {
                  window.scrollTo({
                    top: windowHeightCalulated,
                    behavior: 'smooth',
                  })
                }, 400)
              }
            }}
            onResizeStop={layout => {
              setPositions(layout as WidgetPosition[])
            }}
            onDragStop={layout => {
              setPositions(layout as WidgetPosition[])
              setIsDrag(false)
            }}
            className={`reactgridlayout${animate ? ' animated' : ''}`}
            compactType="vertical"
            ref={refGridTemplateLayout}
          >
            {positions &&
              widgets.map((element, index) => (
                <Card
                  className="item"
                  key={element.id}
                  data-grid={position(element.id, index, element)}
                >
                  <Preview
                    style={!element.isAllowed && { opacity: '.5' }}
                    key={element.id}
                    widget={element}
                    realtimeDelay={realtimeDelay * index}
                    dashboard={dashboard}
                    refresh={refresh}
                    edit={edit}
                    onClone={(w: Widget) => cloneWidget(w)}
                    setModalVisibleDelete={setModalVisibleDelete}
                    setModalVisibleUpdate={setModalVisibleUpdate}
                    setWidget={(data: Widget) => {
                      setWidgetIndex(index)
                      setWidget(data)
                    }}
                  />
                </Card>
              ))}
          </ReactGridLayout>
          {modalVisibleDelete && (
            <ModalDelete
              open={modalVisibleDelete}
              setOpen={setModalVisibleDelete}
              widget={widget}
              onDelete={() => deleteWidget(widgetIndex)}
            />
          )}
          {modalVisibleUpdate && (
            <ModalUpdate
              open={modalVisibleUpdate}
              setOpen={setModalVisibleUpdate}
              widget={widget}
              onUpdate={(w: WidgetDTO) => updateWidget(widgetIndex, w)}
            />
          )}
        </Container>
      )}
    </div>
  )
}
