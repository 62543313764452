import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SideBar from '@sidebar/index'
import Navbar from '@components/navbar/device'
import DeviceList from '@components/pages/devices/index'
import { Device, UserTypeEnum } from '@services/openapi'
import { useAuth, useRouteState } from '@components/store/context'
import SingleOrganizationMenu from '@components/sidebar/menu/organization/Single'
import NotFound from '@components/errors/notFound'
import Main from '@layout/Main'

export default function OrganizationDevices() {
  const [user] = useAuth()
  const { organizationId } = useParams()
  const [, setRouteState] = useRouteState()

  const [newDevice, addDevice] = useState<Device>()

  useEffect(() => {
    setRouteState({
      key: 'organization/single/devices',
      menu: (
        <SingleOrganizationMenu
          id={
            organizationId ??
            (typeof user.organization === 'object'
              ? user.organization.id
              : user.organization)
          }
        />
      ),
    })
  }, [])

  return (
    <>
      <SideBar />
      {user.type === UserTypeEnum.Admin || user.organization ? (
        <>
          <Navbar onAddDevice={addDevice} />
          <Main>
            <DeviceList ofOrganization={organizationId} addDevice={newDevice} />
          </Main>
        </>
      ) : (
        <Main>
          <NotFound />
        </Main>
      )}
    </>
  )
}
