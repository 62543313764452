import { Widget, WidgetCustomData, WidgetTypeEnum } from '@services/openapi'
import React from 'react'
import Base from './base'

export default function Index(props: {
  onSave: (data: any, customData: WidgetCustomData) => void
  closeModal
  idDash: number
  widget?: Widget
}) {
  return (
    <Base
      {...props}
      type={WidgetTypeEnum.LineChart}
      tabs={tabs => {
        tabs.update('personalization', {
          visible: false,
        })
      }}
    />
  )
}
